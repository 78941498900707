<template>
  <div>
        <label
            v-if="(label || $slots.label) && type != 'file'"
            :for="id ? id : randId"
            class="font-weight-bold small mb-0"
        >
        <slot v-if="!label" name="label"/>
        <span v-else>{{ label }}</span>
        </label><template v-if="required">*</template>
        <slot name="label-info" />
        <Quill
            v-if="type == 'quill'"
            ref="quillEditor"
            :value="value"
            @change="onEditorChange"
            :options="editorOptions"
        >
        </Quill>
        <input
            v-else-if="type != 'textarea' && type != 'file'"
            :id="id ? id : randId"
            :type="type"
            class="form-control"
            :class="[
                (error && (errorMessage || errorBorder)) ? 'is-invalid' : '',
            ]"
            :step="step"
            :max="max"
            :min="min"
            :name="name"
            :value="value"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :readonly="readonly"
            :disabled="disabled"
            @input.prevent="ev => { if(!readonly && !disabled) inputEvent(ev) }"
            @keydown.prevent.enter="enterPress"
            @keydown="keyDownEvent"
        >
        <textarea
            v-else-if="type != 'file'"
            :id="id ? id : randId"
            class="form-control"
            :class="[
                error && (errorMessage || errorBorder) ? 'is-invalid' : ''
            ]"
            :name="name"
            :value="value"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :readonly="readonly"
            :disabled="disabled"
            @input.prevent="ev => { if(!readonly && !disabled) inputEvent(ev) }"
        ></textarea>
        <label
            v-else
            :for="files.length == 0 ? (id ? id : randId) : ''"
            class="file-upload-container rounded p-3 position-relative"
            :class="[
                files.length == 0 ? 'd-flex justify-content-center align-items-center' : ''
            ]"
        >
            <div v-if="files.length == 0" class="button rounded-circle border shadow d-flex justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
                </svg>
            </div>
            <div v-else class="row">
            <div class="col-md-4" v-for="(file, key) in files" :key="key">
                <div class="rounded position-relative border h-100 p-2 justify-content-center align-items-center d-flex bg-white">

                    <img :src="file.url ? file.url : file.value" class="mw-100 mh-100 m-auto" />
                    <button
                        type="button"
                        class="close rounded-circle"
                        aria-label="Close"
                        @click="removeImage(file, key)"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div class="col-md-4" v-if="files.length < 3">
                <label :for="id ? id : randId" class="rounded cursor-pointer border h-100 justify-content-center align-items-center d-flex bg-white">
                    <div class="button rounded-circle border shadow d-flex justify-content-center align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
                        </svg>
                    </div>
                </label>
            </div>
        </div>
            <input
                class="input-file d-none"
                :type="type"
                :id="id ? id : randId"
                :multiple="multiple"
                :accept="accept"
                @click="checkAction($event)"
                @change="uploadFile($event)"
            />
        </label>
        <slot></slot>
        <span
            v-if="error && errorMessage"
            class="invalid-feedback"
            role="alert"
        >
            <template v-if="Array.isArray(error)">
                <div
                    v-for="(message, key) in error"
                    :key="key"
                ><strong>{{ message }}</strong></div>
            </template>
            <strong v-else>{{ error }}</strong>
        </span>

  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';

export default {
    props: {
        step: [String, Number],
        max: [String, Number],
        min: [String, Number],
        label: String,
        name: String,
        id: String,
        type: {
            type: String,
            default: 'text',
        },
        value: {},
        errorBorder: {
            type: Boolean,
            default: false,
        },
        placeholder: String,
        required: Boolean,
        autocomplete: String,
        readonly: Boolean,
        disabled: Boolean,
        multiple: Boolean,
        accept: String,
        errors: [Array, Object, String],
        errorMessage: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        Quill: quillEditor,
    },
    data() {
        return {
            error: this.errors,
            randId: this.id ? null : 'id-' + (this.name ? this.name : '') + Math.floor(Math.random() * 10001),

            files: [],
            editorOptions: {
                placeholder: this.placeholder,
                modules: {
                    toolbar: [
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', { 'color': [] }],

                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }, { 'align': [] }],

                        ['link', 'clean']
                    ]
                },
            },

            remove: false,
        }
    },
    mounted() {
        if(this.type == 'file' && this.value) {
            this.value.forEach((el) => {
                this.files.push({
                    url: el.url,
                    id: el.id,
                    market_id: el.pivot.market_id
                });
            })
        }

        this.checkError(this.$root.errors.values);
    },

    methods: {
        inputEvent(ev){
            this.$emit('input', this.type != 'file' ? ev.target.value : ev.target.files);
            this.removeError();
        },
        enterPress(ev){
            this.$emit('enter', ev);
        },
        keyDownEvent(ev){
            if(this.$props.type == 'number' && ev.which == 69) {
                ev.preventDefault();
                return false;
            }
        },
        removeError(){
            if(this.error && this.$root.errors.values){
                let errorKey = null;
                if(this.$root.errors.values[this.id]) errorKey = this.id;
                else errorKey = this.name;

                this.error = null;
                this.$root.errors.values[errorKey] = null;
            }
        },
        onEditorChange({ quill, html, text }) {
            this.$emit('input', html);
        },
        checkAction(ev){
            if(this.remove && this.files.length == 0) {
                ev.preventDefault();
                this.remove = false;
            }
        },
        uploadFile(ev){
            let formData = new FormData();
            let a = [];
            let filesLength = ev.target.files.length;
            let length = this.files.length + filesLength;
            Array.from(ev.target.files).forEach((file, key) => {
                if(this.files.length + (key + 1) <= 3) {
                    var reader = new FileReader();
                    reader.onload = (rFile) => {
                        this.files.push({
                            value: reader.result,
                            file: file,
                        });

                        this.$emit('input', this.files);
                    };
                    reader.readAsDataURL(file);
                    console.log(key, length, filesLength, length - filesLength)
                }
            });

            if(length > 3) {
                alert("Możesz dodać maksymalnie 3 zdjęcia na jedno ogłoszenie!");
            }

            ev.target.value = '';
        },
        removeImage(file, index){
            this.remove = true;
            if(file.url) {
                axios.post('/market/image/remove', {
                    image: file
                })
                .then((response) => {
                    this.$root.flash = response.data;
                    this.files.splice(index, 1);

                    // let a = [];
                    // this.files.forEach(el => {
                    //     if(el.file)
                    //         a.push(el.file);
                    // });
                    this.$emit('input', this.files);
                })
            }
            else {
                this.files.splice(index, 1);

                // let a = [];
                // this.files.forEach(el => {
                //     if(el.file)
                //         a.push(el.file);
                // });
                this.$emit('input', this.files);

            }
        },
        checkError(val){
            if(val){
                let value = null;

                if(val[this.id]) value = val[this.id];
                else value = val[this.name];

                this.error = value;
            }
            else this.error = null;
        }
    },
    watch: {
        '$root.errors.values': {
            deep: true,
            handler(val){
                this.checkError(val);
            }
        },
        value() {
            this.removeError();
        },
        errors(value){
            this.error = value;
        },
        disabled(val){
            if(val) this.removeError();
        }
    }
}
</script>

<style scoped>
.file-upload-container {
    width: 100%;
    border-style: dashed;
    border-color: rgb(219, 219, 219);
    background-color: rgb(241, 241, 241);
    font-weight: bold;
    cursor: pointer;

    transition: all .5s ease;
}
.cursor-pointer {
    cursor: pointer;
}
.button {
    background-color: white;
    padding: 10px;
    width: 50px;
    height: 50px;
    cursor: pointer;

    transition: all .5s ease;
}
.file-upload-container:hover {
    background-color: white;
}
.file-upload-container:hover .button {
    background-color: rgb(241, 241, 241);
}
.input-file {
    height: 0;
    width: 0;
    pointer-events: none;
}
.ql-snow .ql-picker-options {
    z-index: 10 !important;
}
.close {
    background-color: rgba(255, 0, 0);
    color: white;
    top: 5px !important;
    left: auto;
    right: 5px !important;
    position: absolute;
}
::-webkit-input-placeholder{
    font-size: 13px;
}
</style>
