<template>
<div>
    <label
        v-if="(label || $slots.label) && type != 'file'"
        :for="id ? id : randId"
        class="font-weight-bold small mb-0"
    >
    <slot v-if="!label" name="label"/>
    <span v-else>{{ label }}</span>
    </label><template v-if="required">*</template>
    <slot name="label-info" />
    <div class="input-group"
        :class="[
            error && errorMessage ? 'is-invalid' : '',
        ]"
    >
        <div class="input-group-prepend" v-if="$slots.prepend">
            <span class="input-group-text" id="basic-addon2">
                <slot name="prepend" />
            </span>
        </div>
        <input
            v-if="type != 'textarea'"
            :id="id ? id : randId"
            :type="type"
            class="form-control"
            :class="[
                error && errorMessage ? 'is-invalid' : '',
            ]"
            :step="step"
            :max="max"
            :min="min"
            :name="name"
            :value="value"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :readonly="readonly"
            :disabled="disabled"
            @input.prevent="ev => { if(!readonly && !disabled) inputEvent(ev) }"
            @keydown="keyDownEvent"
        >
        <textarea
            v-else
            :id="id ? id : randId"
            class="form-control"
            :class="[
                error && errorMessage ? 'is-invalid' : '',
            ]"
            :name="name"
            :value="value"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :readonly="readonly"
            :disabled="disabled"
            @input.prevent="ev => { if(!readonly && !disabled) inputEvent(ev) }"
        ></textarea>
        <div class="input-group-append" v-if="$slots.append">
            <span class="input-group-text" id="basic-addon2">
                <slot name="append" />
            </span>
        </div>
    </div>
    <span
        v-if="error && errorMessage"
        class="invalid-feedback"
        role="alert"
    >
        <template v-if="Array.isArray(error)">
            <div
                v-for="(message, key) in error"
                :key="key"
            ><strong>{{ message }}</strong></div>
        </template>
        <strong v-else>{{ error }}</strong>
    </span>
</div>
</template>

<script>
export default {
    props: {
        step: [String, Number],
        max: [String, Number],
        min: [String, Number],
        label: String,
        name: String,
        id: String,
        type: {
            type: String,
            default: 'text',
        },
        value: {},
        placeholder: String,
        required: Boolean,
        autocomplete: String,
        readonly: Boolean,
        disabled: Boolean,
        multiple: Boolean,
        accept: String,
        errors: [Array, Object, String],
        errorMessage: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            error: this.errors,
            randId: this.id ? null : 'id-' + (this.name ? this.name : '') + Math.floor(Math.random() * 10001),
            remove: false,
        }
    },
    mounted() {
        this.checkError(this.$root.errors.values);
    },

    methods: {
        inputEvent(ev){
            this.$emit('input', ev.target.value);
            this.removeError();
        },
        removeError(){
            if(this.error){
                let errorKey = null;
                if(this.$root.errors.values[this.id]) errorKey = this.id;
                else errorKey = this.name;

                this.error = null;
                this.$root.errors.values[errorKey] = null;
            }
        },
        keyDownEvent(ev){
            if(this.$props.type == 'number' && ev.which == 69) {
                ev.preventDefault();
                return false;
            }
        },
        checkError(val){
            if(val){
                let value = null;

                if(val[this.id]) value = val[this.id];
                else value = val[this.name];

                this.error = value;
            }
            else this.error = null;
        }
    },
    watch: {
        '$root.errors.values': {
            deep: true,
            handler(val){
                this.checkError(val);
            }
        },
        value() {
            this.removeError();
        },
        disabled(val){
            if(val) this.removeError();
        }
    }
}
</script>

<style>

</style>
