<template>
<div class="custom-control"
    :class="[
        type == 'checkbox' ? 'custom-checkbox' : 'custom-radio'
    ]"
>
    <input
        :type="type"
        class="custom-control-input"
        :class="[
            error ? 'is-invalid' : ''
        ]"
        :name="name"
        :value="value"
        :id="id ? id : randId"
        :checked="checked || isChecked ? true : isTrue(value)"
        @input.prevent="inputEvent"
    >
    <label
        class="custom-control-label text-left d-flex"
        :for="id ? id : randId"
    >
        <template v-if="!$slots.default">
            <span :class="[
                bold ? 'font-weight-bold' : ''
            ]">{{label}}</span>
        </template>
        <slot v-else :setChecked="setChecked" />
        <template v-if="required">*</template>
    </label>
    <span
        v-if="error && errorMessage"
        class="invalid-feedback"
        role="alert"
    >
        <template v-if="Array.isArray(error)">
            <div
                v-for="(message, key) in error"
                :key="key"
            ><strong>{{ message }}</strong></div>
        </template>
        <strong v-else>{{ error }}</strong>
    </span>
</div>
</template>

<script>
export default {
    props: {
        id: String,
        name: String,
        value: {},
        label: String,
        type: {
            type: String,
            default: 'checkbox'
        },
        bool: {
            type: Boolean,
            default: false,
        },
        checked: Boolean,
        data: {},
        bold: {
            type: Boolean,
            default: true,
        },
        required: Boolean,
        errorMessage: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isChecked: this.isTrue(this.value),
            error: null,
            randId: this.id ? null : 'id-' + (this.name ? this.name : '') + Math.floor(Math.random() * 10001),
        }
    },
    mounted() {
        if(this.$props.bool)
            this.isChecked = this.value ? true : false;
        else if(Array.isArray(this.value) && this.value.includes(this.data)){
            this.isChecked = true;
        }
        else if(Number.isInteger(this.value) && !this.$props.bool){
            this.isChecked = (this.value == Number(this.data) ? true : false)
        }
        else if(this.value == this.data && this.value != undefined)
            this.isChecked = true;

        this.checkError(this.$root.errors.values);
    },
    methods: {
        inputEvent(ev){
            if(this.data) {
                if(Array.isArray(this.value)){

                    if(this.value.length > 0){
                        let isInArray = false;
                        this.value.forEach((el, key) => {
                            if(el == this.data) {
                                isInArray = true;
                                this.value.splice(key, 1);
                            }
                        });

                        if(!isInArray) this.value.push(this.data);
                    }
                    else this.value.push(this.data);
                }
                else this.$emit('input', this.data);
            }
            else {
                if(this.isBoolean(this.value) || this.$props.bool){

                    this.isChecked = !this.isChecked;

                    // console.log(this.value)
                    this.$emit('input', !this.value);
                }
                else this.$emit('input', ev.target.value);
            }

            if(this.error){
                let errorKey = null;
                if(this.$root.errors.values[this.id]) errorKey = this.id;
                else errorKey = this.name;

                this.error = null;
                this.$root.errors.values[errorKey] = null;
            }
        },
        setChecked(val){
            this.isChecked = !this.isChecked;
        },
        isBoolean(val) {
            return val === false || val === true;
        },
        isTrue(val) {
            if(this.isBoolean(val)) return val == true;
            else return false;
        },
        checkError(val){
            if(val){
                let value = null;

                if(val[this.id]) value = val[this.id];
                else value = val[this.name];

                this.error = value;
            }
            else this.error = null;
        }
    },

    watch: {
        '$root.errors.values': {
            deep: true,
            handler(val){
                this.checkError(val);
            }
        },
        value(val){
            if(this.$props.bool)
                this.isChecked = this.value ? true : false;
            else if(Array.isArray(this.value) && this.value.includes(this.data)){
                this.isChecked = true;
            }
            else if(Number.isInteger(this.value) && !this.$props.bool){
                this.isChecked = (this.value == Number(this.data) ? true : false)
            }
            else if(this.value == this.data && this.value != undefined)
                this.isChecked = true;

            else if(val != this.$props.data) this.isChecked = false;
            else if(!val)
                this.isChecked = false;

        }
    }
}
</script>

<style>

</style>
