<template>
<div>
    <transition
        mode="out-in"
        name="fade"
    >
        <div
            v-if="($root.errors.message || $root.flash) && !flashAnswer"
            class="alert alert-dismissible font-weight-bold shadow-sm m-3"
            :class="[
                $root.errors.message || $root.flash.error ? 'alert-danger' : '',
                $root.flash.success ? 'alert-success' : ''
            ]"
        >
            <div
                class="mt-0"
            >
                {{ $root.flash.success ? $root.flash.success : $root.flash.error }}
                <button
                    type="button"
                    class="close table"
                    style="left: auto;"
                    @click="ev => {
                        $root.errors.message = null;
                        $root.flash = null;
                    }"

                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </transition>
    <h4 class="font-weight-bold text-waste-yellow mb-3">{{ 'Podaj parametry wyszukiwania:' }}</h4>
    <form @submit.prevent="searchResult" id="form-home-market" class="m-auto">
        <div class="form-row">
            <div class="form-group col-xl-3 col-sm-6">
            <Select
                class="trash-type-section"
                label="Wybierz rodzaj odpadów jaki chcesz przyjąć"
                name="code"
                :live-search="true"
                :size="10"
                :data="trashes"
                v-model="form.code"
            >
            <template #default="{ data, selected }">
                <optgroup
                        v-for="(trash) in data"
                        :key="trash.id"
                        :label="trash.code + (trash.is_dangerous ? '*' : '') + ' - ' + trash.description"
                    >
                    <template v-for="child in trash.children">
                        <option
                            v-for="type in child.children"
                            :key="type.id"
                            :value="type.id"
                            :selected="Number(selected) == type.id"
                            :data-tokens="type.code.replaceAll(' ', '')"
                            :data-content="'<b>' + type.code + (type.is_dangerous ? '*' : '') + '</b> - ' + type.description"
                        ></option>
                    </template>
                </optgroup>
            </template>
            </Select>
            <small class="font-weight-bold" v-if="adminInfo.trash_file_url">Nie wiesz jaki kod odpadu wybrać? <a :href="adminInfo.trash_file_url" download class="font-weight-bold text-waste">Kliknij Tutaj!</a></small>
            </div>
            <Geocoder
                class="form-group  col-xl-3 col-sm-6"
                label="Twoja lokalizacja"
                id="location"
                placeholder="Lokalizacja"
                @update="ev => updateLocation(ev, form)"
                :value="form.location"
            />
            <InputLabel
                class="form-group col-xl-2 col-md-3"
                id="market-arae"
                type="number"
                label="Obszar działania [km]"
                step="0.01"
                placeholder="Obszar działania [km]"
                v-model="form.area"
            />

            <div class="form-group col-xl-2 col-md-6">
                <div class="form-group mb-0 px-0 col">
                    <small class="font-weight-bold">Ilość odpadów</small>
                    <div class="form-row"
                        :class="[
                            $root.errors.values && $root.errors.values['from'] ? 'is-invalid' : ''
                        ]"
                    >
                        <InputLabel
                            class="form-group col-6 mb-0"
                            id="from"
                            type="number"
                            :max="form.to ? form.to : null"
                            min="0"
                            step="0.01"
                            placeholder="Od"
                            :errorMessage="false"
                            :errorBorder="true"
                            v-model.number="form.from"
                        />
                        <InputLabel
                            class="form-group col-6 mb-0"
                            id="to"
                            type="number"
                            :min="form.from ? form.from : 0"
                            step="0.01"
                            placeholder="Do"
                            :errorMessage="false"
                            :errorBorder="true"
                            v-model.number="form.to"
                        />
                    </div>
                    <strong class="invalid-feedback" role="alert">
                        <ul class="p-0">
                            <li v-if="$root.errors.values && $root.errors.values['from']">{{ $root.errors.values['from'][0] }}</li>
                            <li v-if="$root.errors.values && $root.errors.values['to']">{{ $root.errors.values['to'][0] }}</li>
                        </ul>
                    </strong>
                </div>
            </div>
            <Select
                class="form-group col-xl-2 col-md-3"
                label="Wybierz jednostkę"
                name="unit"
                :size="10"
                :data="units"
                :required="true"
                :clearOption="false"
                v-model="form.unit"
            >
                <template #default="{ data, selected }">
                    <option
                        value="all"
                        :selected="selected = 'all'"
                    >
                        Wszystkie
                    </option>
                    <option
                        v-for="unit in data"
                        :key="unit.id"
                        :value="unit.id"
                        :selected="unit.id == selected"
                    >
                        {{ unit.symbol + ' (' + unit.name + ')'}}
                    </option>
                </template>
            </Select>
        </div>
        <div class="form-row">
            <div class="form-group col-sm">
                <small class="font-weight-bold">Transport odpadów</small>
                <div class="d-flex flex-wrap">
                    <template
                        v-for="(transport, key) in transports"
                    >
                        <Checkbox
                            :key="key"
                            class="custom-control-inline"
                            v-if="transport.slug != 'nie_uwzgledniaj'"
                            type="checkbox"
                            name="transport"
                            :bold="false"
                            :label="transport.value"
                            :id="'transport' + key"
                            :data="transport.slug"
                            v-model="form.transport"
                        />
                    </template>
                </div>
            </div>
            <div class="form-group col d-flex  flex-wrap justify-content-end align-items-end" v-if="$props.user">
                <!-- <button type="submit" class="btn waste-container btn-search d-block px-5 my-3 mx-2">Szukaj</button> -->
                <div class="position-relative">
                    <div class="px-2 py-3 btn-map d-block border shadow rounded bg-white"
                    >
                        <button
                            type="button"
                            data-toggle="modal"
                            @click.prevent="showMap"
                            data-target="#mapSection"
                            class="btn btn-primary mx-auto d-block px-4"
                        >Pokaż na mapie</button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <!-- Google maps modal -->
    <div
        class="modal fade"
        id="mapSection"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="mapSectionLabel"
        aria-hidden="true"
        v-if="currentSearch && $props.user"

        :style="answer.data ? 'z-index: 1000 !important;' : ''"
    >
    <button
        type="button"
        class="close modal-close map-close shadow border rounded-circle bg-white m-0 p-2"
        data-dismiss="modal"
        aria-label="Close"
        @click.prevent="openMap = false"
    >
    <span aria-hidden="true">&times;</span>
    </button>
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content vh-100">
                <div class="modal-body p-0">
                    <GoogleMap
                        v-if="openMap"
                        :data="offers"
                        :area="form.area"
                        :coords="{
                            lat: form.lat,
                            lng: form.lng
                        }"
                        :location="showMarker"
                        @blocked="ev => openMap = ev"
                    >
                    <template v-slot:list-result="{ location }">
                        <div class="bg-waste-offer rounded-top px-3 py-2 text-white font-weight-bold position-relative">
                            <h6 class="font-weight-bold my-auto">{{ `${ location.trash.code + (location.trash.is_dangerous ? '*': '') } - ${ location.trash.description }` }}</h6>
                            <h6 class="font-weight-bold mt-2 mb-0">
                                Dodano: <small style="font-size: 15px;">{{ getDate(location.created_at, 'DD/MM/YYYY') }}</small>
                            </h6>
                        </div>
                        <div class="rounded-bottom p-3 bg-white position-relative">
                            <div class="row">
                                <div class="col">
                                    <div class="d-flex justify-content-center align-items-centertext-white w-100 pb-3" style="height: 180px !important;">
                                        <img
                                            :src="location.images.length > 0 ? location.images[0].url : '/img/site/rynek-no-image.png'"
                                            class="mw-100 mh-100 m-auto d-block border p-2"
                                        />
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item px-0 pb-3">
                                            <svg
                                                width="1.5em"
                                                height="1.5em"
                                                viewBox="0 0 16 16"
                                                class="bi bi-geo-alt"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                            </svg>
                                            <span class="ml-1">{{location.location.full_address}}</span>
                                        </li>
                                        <li class="list-group-item px-0" v-if="location.details">
                                            <b>Opis odpadów:</b>
                                            <ul class="mt-1 pl-0 pl-md-4 list-disc">
                                                <li style="word-break: break-all;" v-html="location.details.replace(/(?:\r\n|\r|\n)/g, '<br>')"></li>
                                            </ul>
                                        </li>
                                        <li class="list-group-item px-0">
                                            <b>Przewidywana ilość odpadów:</b> <span>{{ location.size }} [{{ location.unit ? location.unit.symbol : 'Mg' }}]</span>
                                        </li>
                                        <li class="list-group-item px-0">
                                            <b>Możliwy odbiór części odpadów:</b>
                                            <span class="ml-1">{{ location.some_part == true ? 'Tak' : 'Nie' }}</span>
                                        </li>
                                        <li class="list-group-item px-0">
                                            <b>Terminarz odbioru odpadów:</b>
                                            <span>Od {{ getDate(location.date_from, 'DD/MM/YYYY') + " do " + getDate(location.date_to, 'DD/MM/YYYY')}}</span>
                                        </li>
                                        <li class="process list-group-item px-0">
                                            <b>Sposób przetwarzania odpadów:</b>
                                            <span
                                                v-for="(process, key) in location.process" :key="process.id"
                                                :class="[
                                                    location.process.length - 1 != key ? 'mr-2' : ''
                                                ]"
                                            >
                                                <InfoIcon
                                                    v-if="Number(process) === 1"
                                                    :title="processes[process]"
                                                    viewBox="0 0 24 24"
                                                    size="26"
                                                    class="btn text-success process-r p-0"
                                                >
                                                    <path d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"/>
                                                </InfoIcon>
                                                <InfoIcon
                                                    v-else-if="Number(process) === 2"
                                                    :title="processes[process]"
                                                    viewBox="0 0 16 16"
                                                    size="26"
                                                    class="btn p-0"
                                                >
                                                    <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
                                                </InfoIcon>
                                            </span>
                                        </li>
                                    </ul>
                                    <button @click="showMoreDetails(location, 'modal')" class="btn mt-2 waste-container text-white w-100">
                                        <span>Szczegóły ogłoszenia</span>
                                        <svg
                                            :style="showMore.value == location.id && showMore.type == 'modal' ? '' : 'transform:rotate(180deg)'"
                                            style="transition: all .5s ease;"
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                        </svg>
                                    </button>
                                    <transition
                                        mode="out-in"
                                        @before-enter="beforeEnter"
                                        @enter="slideEnter"
                                        @after-enter="afterEnter"
                                        @leave="slideLeave"
                                    >
                                    <ul
                                        class="list-group list-group-flush"
                                        v-if="showMore.value == location.id && showMore.type == 'modal'"
                                    >
                                        <li class="list-group-item px-0" v-if="objectCount(location.params.filter(el => el.required == true)) > 0">
                                            <b>Parametry odpadów:</b><br>
                                            <ul class="mt-2 pl-0 pl-md-4">
                                                <template
                                                    v-for="(p, key) in location.params"
                                                >
                                                    <li v-if="p.required" :key="key" class="mb-2 list-disc">
                                                        {{ p.name }}<br>
                                                        <div v-if="p.value" class="ml-2" v-html="p.value.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                                                    </li>
                                                </template>
                                            </ul>
                                        </li>
                                        <li class="list-group-item px-0" v-if="objectCount(location.pollution.filter(el => el.required == true)) > 0">
                                            <b>Rodzaj i udział zanieczyszczeń:</b>
                                            <ul class="mt-1 pl-0 pl-md-4">
                                                <template
                                                    v-for="(p, key) in location.pollution"
                                                >
                                                <li
                                                    :key="key"
                                                    :class="p ? 'mb-2' : ''"
                                                    class="list-disc"
                                                    v-if="p && p.required"
                                                >
                                                    {{ p.name }}<br>
                                                    <div class="ml-2" v-if="p && p.required && p.value" v-html="p.value.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                                                </li>
                                                </template>
                                            </ul>
                                        </li>
                                        <li class="list-group-item px-0">
                                            <b>Transport:</b>
                                            <ul class="list-disc pl-0 pl-md-4">
                                                <li v-for="(t) in location.transport" :key="t">{{ __('messages.' + t) }}</li>
                                            </ul>
                                        </li>
                                        <li class="list-group-item px-0">
                                            <b>Załadunek:</b>
                                            <ul class="list-disc mt-2 pl-0 pl-md-4">
                                                <template
                                                    v-if="Array.isArray(location.additional.loading)"
                                                >
                                                    <li v-for="(l) in location.additional.loading" :key="l">{{ __('messages.' + l) }}</li>
                                                </template>
                                                <li v-else>{{ __('messages.' + location.additional.loading) }}</li>
                                            </ul>
                                        </li>
                                        <li class="list-group-item px-0">
                                            <b>Sposób magazynowania:</b>
                                            <ul class="list-disc mt-2 pl-0 pl-md-4" :key="location.id">
                                                <li>
                                                    <span
                                                        :class="[
                                                            location.additional.storage == 'other' ? 'font-weight-bold' : ''
                                                        ]"
                                                        v-if="location.additional.storage != 'other'"
                                                    >
                                                        {{ __('messages.storage.' + location.additional.storage) + (location.additional.storage == 'other' ? ':' : '')}}
                                                    </span>
                                                    <div
                                                        v-if="location.additional.storage == 'other' && location.additional.storage_other"
                                                        v-html="location.additional.storage_other.replace(/(?:\r\n|\r|\n)/g, '<br>')"
                                                    ></div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="list-group-item px-0" v-if="location.additional.other && location.additional.other">
                                            <b>Inne:</b>
                                            <ul class="list-disc mt-2 pl-0 pl-md-4">
                                                <li>
                                                    <div v-html="location.additional.other.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="list-group-item px-0">
                                            <b>Mogą składać ofertę:</b>
                                            <ul class="list-disc mt-2 pl-0 pl-md-4">
                                                <li v-for="(can) in location.can_order" :key="can">{{ __('messages.can_order.' + can) }}</li>
                                            </ul>
                                        </li>
                                        <li class="list-group-item px-0">
                                            <b>Wymagane dostarczenie decyzji potwierdzającej uprawnienia do zagospodarowania odpadów:</b>
                                            <span class="ml-1">{{ location.environmental_decision == '0' || !location.environmental_decision ? 'Nie' : 'Tak' }}</span>
                                        </li>
                                    </ul>
                                    </transition>
                                </div>
                                <div class="col-12">
                                    <ul class="pl-0 my-3 list-unstyled d-md-flex justify-content-center">
                                        <li class="mr-md-2 mb-md-0 mb-2">
                                            <button
                                                @click="ev => {
                                                    if(contact.data && contact.data.id == location.id){
                                                        contact.data = null;
                                                        contact.type = null;
                                                    }
                                                    else {
                                                        contact.data = location;
                                                        contact.type = 'modal';
                                                    }
                                                }"
                                                class="btn waste-first text-white mx-auto d-block px-5">Kontakt</button>
                                        </li>
                                        <li v-if="$props.user && $props.user.id != location.user_id && $props.isVerified">
                                            <button
                                                data-toggle="modal"
                                                :data-target="($props.user && !$props.user.firstname) ? '#needCompleteUserData' : '#contact-modal'"
                                                @click="checkUserDataAndOpenContactModal(1, location. $event)"
                                            class="btn waste-second to-left text-white mx-auto d-block px-5">Wyślij ofertę</button>
                                        </li>
                                    </ul>
                                </div>
                                <transition
                                    mode="out-in"
                                    @before-enter="beforeEnter"
                                    @enter="slideEnter"
                                    @after-enter="afterEnter"
                                    @leave="slideLeave"
                                >
                                <div
                                    class="col-12 d-flex flex-column justify-content-center"
                                    v-if="contact.data && contact.type == 'modal' && contact.data.id == location.id"
                                >
                                    <div v-if="user && $props.isVerified">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item py-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                </svg>
                                                <b>Nazwa firmy/Os.fizyczna:</b>
                                                {{ contact.data.who }}
                                            </li>
                                            <li class="list-group-item py-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                                                </svg>
                                                <b>E-mail:</b> {{contact.data.email}}
                                            </li>
                                            <li class="list-group-item py-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                                </svg>
                                                <b>Telefon:</b> {{ contact.data.phone ? contact.data.phone : '-'}}
                                            </li>
                                            <li class="list-group-item py-2">
                                                <span class="font-weight-bold">
                                                    <svg
                                                        width="1.5em"
                                                        height="1.5em"
                                                        viewBox="0 0 16 16"
                                                        class="bi bi-geo-alt"
                                                        fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                    </svg>
                                                    Lokalizacja:
                                                </span> {{contact.data.location.full_address}}
                                            </li>
                                        </ul>
                                        <ul class="list-group list-group-flush mt-3">
                                            <li class="list-group-item py-2">
                                                <b>Dodane przez:</b>
                                                <span class="ml-1">{{ contact.data.user.firstname }}</span>
                                                <!-- + ' ' + contact.data.user.lastname -->
                                            </li>
                                            <li class="list-group-item py-2" v-if="contact.data.user.is_bdo">
                                                <b>Dodano jako:</b>
                                                <span class="ml-1">{{__('messages.as_who.bdo')}}</span>
                                            </li>
                                            <li class="list-group-item py-2" v-else>
                                                <b>Dodano jako:</b>
                                                <span class="ml-1">{{__('messages.as_who.other')}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-else class="text-left">
                                        <p class="h5 pb-3 mb-3 border-bottom">Informacja dostepna dla {{ $props.user && !$props.isVerified ? 'zweryfikowanych' : 'zalogowanych' }} uzytkowników</p>

                                        <div v-if="!$props.user">
                                            <LoginForm />
                                            <a href="/register#rejestracja" class="btn mt-3 btn-scale waste-container text-white">Zarejestruj się</a>
                                        </div>
                                    </div>

                                    <a
                                        v-if="contact.data && $props.user && $props.user.id != contact.data.user_id && $props.isVerified"
                                        class="mt-4 btn btn-waste text-center"
                                        :href="'message/create/' + contact.data.user.id + '/8'"
                                    >Wyślij wiadomość</a>
                                </div>
                                </transition>
                            </div>
                        </div>
                    </template>
                    </GoogleMap>
                </div>
            </div>
        </div>
    </div>

    <div class="search-results position-relative">
        <transition
            mode="out-in"
            name="fade"
        >
        <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center position-absolute spinner"
        >
            <div class="sticky-top">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        </transition>
        <h4 v-if="offers.length == 0" class="text-center">
            Żadne ogłoszenie nie spełnia określonych wymagań
            <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-emoji-frown" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
            </svg>
        </h4>
        <div v-for="(item, key) in offers" :key="item.id"
            class="rounded single-offer"
            :data-id="item.id"
            :class="[
                key == offers.length - 1 ? '' : 'mb-3',
                loading ? 'opacity-50' : ''
            ]"
        >
            <div class="bg-waste-offer rounded py-2 px-4 text-white d-sm-flex justify-content-between font-weight-bold position-relative shadow-sm">
                <h4 class="title-offer font-weight-bold my-auto">{{ `${ item.trash.code + (item.trash.is_dangerous ? '*': '') } - ${ item.trash.description }` }}</h4>
                <h5 class="title-offer font-weight-bold my-0 ml-4 text-right">
                    Dodano:<br>
                    <small style="font-size: 15px; white-space: nowrap;">{{ getDate(item.created_at) }}</small>
                </h5>
            </div>
            <div class="rounded px-md-4 px-2 py-4 bg-white position-relative content shadow-sm" ref="content">
                <div class="row">
                    <div class="col-md-6 position-relative details-wrapper pb-5 mb-md-0 mb-2">
                        <ul ref="details" class="list-group list-group-flush details-offer position-absolute overflow-hidden pb-5"
                                :class="[
                                    (showMore.value == item.id && showMore.type == 'search') ? 'active' : ''
                                ]"
                            >
                            <!-- style="height: 0;" -->
                                <li class="list-group-item" v-if="item.details">
                                    <b>Opis odpadów:</b>
                                    <ul class="mt-1 pl-0 pl-md-4 list-disc">
                                        <li style="word-break: break-all;" v-html="item.details.replace(/(?:\r\n|\r|\n)/g, '<br>')"></li>
                                    </ul>
                                </li>
                                <li class="list-group-item">
                                    <b>Przewidywana ilość odpadów:</b> <span>{{ item.size }} [{{ item.unit ? item.unit.symbol : 'Mg' }}]</span>
                                </li>
                                <li class="list-group-item">
                                    <b>Możliwy odbiór części odpadów:</b>
                                    <span class="ml-1">{{ item.some_part == true ? 'Tak' : 'Nie' }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>Terminarz odbioru odpadów:</b>
                                    <span>Od {{ getDate(item.date_from, 'DD/MM/YYYY') + " do " + getDate(item.date_to, 'DD/MM/YYYY')}}</span>
                                </li>
                                <li
                                    class="list-group-item"
                                    v-if="objectCount(item.params.filter(el => el.required == true)) > 0 ||
                                            objectCount(item.pollution.filter(el => el.required == true)) > 0"
                                >
                                    <div class="row mx-n2">
                                        <div class="col-md px-2" v-if="objectCount(item.params.filter(el => el.required == true)) > 0">
                                            <b>Parametry odpadów:</b><br>
                                            <ul class="mt-1 pl-0 pl-md-4 ">
                                                <template
                                                    v-for="(p, key) in item.params"
                                                >
                                                    <li v-if="p.required" :key="key" class="mb-2 list-disc">
                                                        {{ p.name }}<br>
                                                        <div class="ml-2" v-if="p.value" v-html="p.value.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                        <div class="col-md px-2" v-if="objectCount(item.pollution.filter(el => el.required == true)) > 0">
                                            <b>Rodzaj i udział zanieczyszczeń:</b>
                                            <ul class="mt-1 pl-0 pl-md-4">
                                                <template
                                                    v-for="(p, key) in item.pollution"
                                                >
                                                <li
                                                    :key="key"
                                                    :class="p ? 'mb-2' : ''"
                                                    class="list-disc"
                                                    v-if="p && p.required"
                                                >
                                                    {{ p.name }}<br>
                                                    <div class="ml-2" v-if="p && p.required && p.value" v-html="p.value.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                                                </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item process">
                                    <b>Sposób przetwarzania odpadów:</b>
                                    <span
                                        v-for="(process, key) in item.process" :key="process.id"
                                        :class="[
                                            item.process.length - 1 != key ? 'mr-2' : ''
                                        ]"
                                    >
                                        <InfoIcon
                                            v-if="Number(process) === 1"
                                            :title="processes[process]"
                                            viewBox="0 0 24 24"
                                            size="26"
                                            class="btn text-success process-r p-0"
                                        >
                                            <path d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"/>
                                        </InfoIcon>
                                        <InfoIcon
                                            v-else-if="Number(process) === 2"
                                            :title="processes[process]"
                                            viewBox="0 0 16 16"
                                            size="26"
                                            class="btn p-0"
                                        >
                                            <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
                                        </InfoIcon>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <div class="row mx-n2">
                                        <div class="col-md px-2">
                                            <b>Transport:</b>
                                            <ul class="mt-1 pl-0 pl-md-4 list-disc">
                                                <li v-for="(tr) in item.transport" :key="tr">{{ __('messages.' + tr) }}</li>
                                            </ul>
                                        </div>
                                        <div class="col-md px-2">
                                            <b>Załadunek:</b>
                                            <ul class="mt-1 pl-0 pl-md-4 list-disc">
                                                <template
                                                    v-if="Array.isArray(item.additional.loading)"
                                                >
                                                    <li v-for="(lo) in item.additional.loading" :key="lo">{{ __('messages.' + lo) }}</li>
                                                </template>
                                                <li v-else>{{ __('messages.' + item.additional.loading) }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <b>Sposób magazynowania:</b>
                                    <ul class="mt-1 pl-0 pl-md-4 list-disc" :key="item.id">
                                        <li>
                                            <span
                                                :class="[
                                                    item.additional.storage == 'other' ? 'font-weight-bold' : ''
                                                ]"
                                                v-if="item.additional.storage != 'other'"
                                            >
                                                {{ __('messages.storage.' + item.additional.storage) + (item.additional.storage == 'other' ? ':' : '')}}
                                            </span>
                                            <div
                                                v-if="item.additional.storage == 'other' && item.additional.storage_other"
                                                v-html="item.additional.storage_other.replace(/(?:\r\n|\r|\n)/g, '<br>')"
                                            ></div>
                                        </li>
                                    </ul>
                                </li>
                                <li class="list-group-item" v-if="item.additional.other && item.additional.other">
                                    <b>Inne:</b>
                                    <ul class="mt-1 pl-0 pl-md-4 list-disc">
                                        <li>
                                            <div v-html="item.additional.other.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                                        </li>
                                    </ul>
                                </li>
                                <li class="list-group-item">
                                    <b>Mogą składać ofertę:</b>
                                    <ul class="mt-1 pl-0 pl-md-4 list-disc">
                                        <li v-for="(can) in item.can_order" :key="can">{{ __('messages.can_order.' + can) }}</li>
                                    </ul>
                                </li>
                                <li class="list-group-item">
                                    <b>Wymagane dostarczenie decyzji potwierdzającej uprawnienia do zagospodarowania odpadów:</b>
                                    <span class="ml-1">{{ item.environmental_decision == '0' || !item.environmental_decision ? 'Nie' : 'Tak' }}</span>
                                </li>
                        </ul>
                        <button
                            @click="showMoreDetails(item, 'search', key)"
                            class="btn btn-details-offer waste-container text-white position-absolute"
                            ref="btn"
                        >
                            <span>Szczegóły ogłoszenia</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                :style="showMore.value == item.id && showMore.type == 'search' ? '' : 'transform:rotate(180deg)'"
                                style="transition: all .5s ease;"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-caret-up-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="col-md-6">
                        <div class="image-sticky">
                            <!-- <agile
                                ref="carousel"
                                v-if="item.images.length > 1"
                                :key="item.id"
                            >
                                <div
                                    class="slide"
                                    v-for="(image) in item.images"
                                    :key="image.id"
                                >
                                    <img :src="image.url" style="max-height: 280px;" class="mw-100 m-auto d-block border p-2" />
                                </div>

                                <template slot="prevButton">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                                    <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                                    </svg>
                                </template>
                                <template slot="nextButton">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                                    </svg>
                                </template>
                            </agile>
                            <div v-else class="slide">
                                <img
                                    :src="item.images.length == 1 ? item.images[0].url : '/img/site/rynek-no-image.png'"
                                    style="max-height: 280px;"
                                    class="mw-100 m-auto d-block border p-2"
                                />
                            </div> -->

                            <div :id="`carouseleControls-${item.id}`" class="carousel slide position-relative" data-ride="carousel">
                                <div class="carousel-inner">
                                <template v-if="item.images.length > 1">
                                    <div
                                        v-for="(image, key) in item.images"
                                        :key="image.id"
                                        class="carousel-item"
                                        :class="{
                                            active: key == 0
                                        }"
                                        style="height: 280px;"
                                        @click="setActiveModalImage(item.images, key)"
                                    >
                                        <a href="#" data-toggle="modal" data-target="#modal-images">
                                            <img
                                                class="mw-100 d-block h-100 w-auto mw-100 m-auto d-block border p-2 modal-hover-opacity"
                                                :src="image.url"
                                                style="max-width:100%; cursor:pointer; max-height: 280px;"
                                            >
                                        </a>
                                    </div>
                                </template>
                                <div
                                    v-else
                                    class="carousel-item active"
                                    style="height: 280px;"
                                    @click="setActiveModalImage(item.images, key)"
                                >
                                    <img
                                        class="mw-100 d-block h-100 w-auto mw-100 m-auto d-block border p-2 modal-hover-opacity"
                                        :src="item.images.length == 1 ? item.images[0].url : '/img/site/rynek-no-image.png'"
                                        style="max-width:100%; cursor:pointer; max-height: 280px;"
                                    >
                                </div>
                                </div>
                                <a
                                    v-if="item.images.length > 1"
                                    class="carousel-control-prev"
                                    :href="`#carouseleControls-${item.id}`" role="button" data-slide="prev" style="top:40% !important;"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="#909090" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
                                </svg>
                                </a>
                                <a
                                    v-if="item.images.length > 1"
                                    class="carousel-control-next"
                                    :href="`#carouseleControls-${item.id}`" role="button" data-slide="next" style="top:40% !important;"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="#909090" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                                </a>
                            </div>

                            <ul class="pl-0 my-2 text-center list-unstyled">
                                <li>
                                    <div
                                        style="width: fit-content;"
                                        class="mx-auto location"
                                        @click="ev => {showMarker = item; showMap()}"
                                        data-toggle="modal"
                                        data-target="#mapSection"
                                    >
                                    <span class="font-weight-bold">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 16 16"
                                            class="bi bi-geo-alt"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                        Lokalizacja:
                                    </span> {{item.location.full_address}}
                                    </div>
                                </li>
                            </ul>
                            <ul class="pl-0 mt-2 mb-0 text-center list-unstyled d-md-flex justify-content-center">
                                <li>
                                    <button
                                        data-toggle="modal"
                                        :data-target="($props.user && !$props.user.firstname) ? '#needCompleteUserData' : '#contact-modal'"
                                        @click="checkUserDataAndOpenContactModal(2, item)"
                                        class="btn waste-first text-white mx-auto d-block px-5"
                                    >Kontakt</button>
                                    </li>
                                <li
                                    v-if="$props.user && $props.user.id != item.user_id && $props.isVerified"
                                >
                                    <button
                                        data-toggle="modal"
                                        :data-target="($props.user && !$props.user.firstname) ? '#needCompleteUserData' : '#contact-modal'"
                                        @click="checkUserDataAndOpenContactModal(3, item)"
                                        class="btn waste-second to-left text-white mx-auto d-block px-5 ml-md-2 mt-md-0 mt-2"
                                    >Wyślij ofertę</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <transition
                        @before-enter="beforeEnter"
                        @enter="slideEnter"
                        @after-enter="afterEnter"
                        @leave="slideLeave"
                    >
                        <div class="col-12 more" v-if="showMore.value == item.id && showMore.type == 'search'">
                            <div class="mt-3 list-group-item border-0" v-if="item.description">
                                <div class="border-bottom pb-1 mb-1"><b>Dodatkowe informacje:</b></div>
                                <div class="ql-container ql-snow border-0">
                                    <div style="word-break: break-all;"  class="ql-editor px-0" v-html="item.description"></div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="contact-modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            :class="[
                user ? 'modal-lg' : ''
            ]"
        >
            <form
                @submit.prevent="sendAnswer"
                class="modal-content answer-form"
            >
            <transition
                mode="out-in"
                name="fade"
            >
            <div
                v-if="answer.loading"
                class="d-flex justify-content-center align-items-center position-absolute spinner spinner-answer"
            >
                <div class="sticky-top">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            </transition>
            <div class="modal-header">
                <h5 class="modal-title font-weight-bold" id="modalLabel">
                    {{ answer.data ? 'Wyslij swoją ofertę' : 'Informacje kontaktowe' }}
                </h5>
                <button type="button" class="close modal-close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div
                class="jumbotron mb-0 p-0"
            >
                <transition
                    mode="out-in"
                    name="fade"
                >
                    <div
                        v-if="($root.errors.message || $root.flash) && flashAnswer"
                        class="alert-custom alert-dismissible font-weight-bold shadow-sm m-3"
                        :class="[
                            $root.errors.message || $root.flash.error ? 'alert-danger' : '',
                            $root.flash.success ? 'alert-success' : ''
                        ]"
                    >
                        <div
                        >
                            {{ $root.flash.success ? $root.flash.success : $root.flash.error }}
                            <button
                                type="button"
                                class="close table"
                                style="left: auto;"
                                @click="ev => {
                                    $root.errors.message = null;
                                    $root.flash = null;
                                }"

                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </transition>
            </div>
            <div
                class="modal-body mb-0"
                :class="answer.data ? 'jumbotron' : ''"
            >
                <div class="row" v-if="contact.data && contact.type == 'search' && $props.user && $props.isVerified">
                    <div class="col-lg-6 d-flex flex-column justify-content-center">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item py-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                </svg>
                                <b>Nazwa firmy/Os.fizyczna:</b>
                                <!-- {{ contact.data.user.company_name ? contact.data.user.company_name : (contact.data.user.firstname + ' ' + contact.data.user.lastname) }} -->
                                {{ contact.data.who }}
                            </li>
                            <li class="list-group-item py-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                                </svg>
                                <b>E-mail:</b> {{contact.data.email}}
                            </li>
                            <li class="list-group-item py-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                </svg>
                                <b>Telefon:</b> {{ contact.data.phone ? contact.data.phone : '-'}}
                            </li>
                            <li class="list-group-item py-2">
                                <span class="font-weight-bold">
                                    <svg
                                        width="1.5em"
                                        height="1.5em"
                                        viewBox="0 0 16 16"
                                        class="bi bi-geo-alt"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    </svg>
                                    Lokalizacja:
                                </span> {{contact.data.location.full_address}}
                            </li>
                        </ul>
                        <ul class="list-group list-group-flush mt-3">
                            <li class="list-group-item py-2">
                                <b>Dodane przez:</b>
                                <span class="ml-1">{{contact.data.user.firstname}}</span>
                                <!-- + ' ' + contact.data.user.lastname -->
                            </li>
                            <li class="list-group-item py-2" v-if="contact.data.user.is_bdo">
                                <b>Dodano jako:</b>
                                <span class="ml-1">{{__('messages.as_who.bdo')}}</span>
                            </li>
                            <li class="list-group-item py-2" v-else>
                                <b>Dodano jako:</b>
                                <span class="ml-1">{{__('messages.as_who.other')}}</span>
                            </li>
                        </ul>

                        <a
                            v-if="contact.data && $props.user && $props.user.id != contact.data.user_id && $props.isVerified"
                            class="mt-4 btn btn-waste text-center"
                            :href="'message/create/' + contact.data.user.id + '/8'"
                        >Wyślij wiadomość</a>
                    </div>
                    <div class="col-lg-6 mt-lg-0 mt-3">
                        <GoogleMap
                            style="min-height: 300px;"
                            :list="false"
                            v-if="contact.data"
                            :coords="{
                                lat: contact.data.location.lat,
                                lng: contact.data.location.lng
                            }"
                        >
                        </GoogleMap>
                    </div>
                </div>
                <div class="row" v-if="answer.data && $props.user && $props.isVerified">
                    <div class="col-12 border-bottom">
                        <h6 class="font-weight-bold">Informacje o odbiorcy</h6>
                        <InputLabel
                            class="form-group"
                            label="Firma/os. fizyczna"
                            :required="true"
                            id="recipient"
                            v-model="answer.form.recipient"
                        />
                        <div class="form-row">
                            <InputLabel
                                class="form-group col-md-6"
                                label="E-mail"
                                :required="true"
                                id="email"
                                v-model="answer.form.email"
                            />
                            <InputLabel
                                class="form-group col-md-6"
                                label="Telefon"
                                id="phone"
                                v-model="answer.form.phone"
                            />
                        </div>
                        <Geocoder
                            class="form-group"
                            label="Lokalizacja przyjęcia odpadów"
                            placeholder=""
                            id="location_answer"
                            name="location"
                            :value="user.address"
                            @update="ev => updateLocation(ev, answer.form)"
                        />
                        <InputGroup
                            class="form-group"
                            label="Ilość odpadów"
                            step="0.01"
                            type="number"
                            id="amount"
                            :placeholder="'Ilość odpadów [' + answer.data.unit.symbol + ']'"
                            :max="answer.data.size"
                            :required="true"
                            :disabled="answer.data.some_part == false || answer.data.some_part == 0"
                            v-model="answer.form.amount"
                        >
                            <template #append>{{ answer.data.unit.symbol }}</template>
                        </InputGroup>
                    </div>
                    <div class="col-12 border-bottom">
                        <h6 class="font-weight-bold my-3">Rodzaj oferty*:</h6>
                        <div class="form-group">
                            <Checkbox
                                label="Bez transportu odpadów"
                                style="width: fit-content;"
                                name="transport_answer"
                                data="without"
                                :bold="false"
                                v-model="answer.form.transport_answer"
                            />
                            <transition
                                mode="out-in"
                                @before-enter="beforeEnter"
                                @enter="slideEnter"
                                @after-enter="afterEnter"
                                @leave="slideLeave"
                            >
                                <ul
                                    v-if="answer.form.transport_answer.includes('without')"
                                >
                                    <li class="d-flex align-items-center mb-1">
                                        <Checkbox
                                            label="Płatność ponosi przekazujący odpady"
                                            name="payment.without"
                                            type="radio"
                                            :bold="false"
                                            data="owner"
                                            :errorMessage="false"
                                            v-model="answer.form.payment.without"
                                        />
                                        <InputGroup
                                            class="ml-2 w-auto"
                                            placeholder="Cena"
                                            step="0.01"
                                            type="number"
                                            min="0"
                                            id="payment_details.without.owner"
                                            :disabled="answer.form.payment.without != 'owner'"
                                            v-model="answer.form.payment_details.without.owner"
                                        >
                                            <template #append>zł</template>
                                        </InputGroup>
                                    </li>
                                    <li class="d-flex align-items-center">
                                        <Checkbox
                                            label="Płatność ponosi przejmujący odpady"
                                            name="payment.without"
                                            type="radio"
                                            :bold="false"
                                            data="recipient"
                                            v-model="answer.form.payment.without"
                                        />
                                        <InputGroup
                                            class="ml-2 w-auto"
                                            placeholder="Cena"
                                            step="0.01"
                                            type="number"
                                            min="0"
                                            id="payment_details.without.recipient"
                                            :disabled="answer.form.payment.without != 'recipient'"
                                            v-model="answer.form.payment_details.without.recipient"
                                        >
                                            <template #append>zł</template>
                                        </InputGroup>
                                    </li>
                                </ul>
                            </transition>
                        </div>
                        <div class="form-group">
                            <Checkbox
                                label="Z transportem odpadów"
                                style="width: fit-content;"
                                name="transport_answer"
                                data="with"
                                :bold="false"
                                v-model="answer.form.transport_answer"
                            />
                            <transition
                                mode="out-in"
                                @before-enter="beforeEnter"
                                @enter="slideEnter"
                                @after-enter="afterEnter"
                                @leave="slideLeave"
                            >
                                <ul
                                    v-if="answer.form.transport_answer.includes('with')"
                                >
                                    <li class="d-flex align-items-center mb-1">
                                        <Checkbox
                                            label="Płatność ponosi przekazujący odpady"
                                            name="payment.with"
                                            type="radio"
                                            data="owner"
                                            :bold="false"
                                            :errorMessage="false"
                                            v-model="answer.form.payment.with"
                                        />
                                        <InputGroup
                                            class="ml-2 w-auto"
                                            placeholder="Cena"
                                            step="0.01"
                                            type="number"
                                            id="payment_details.with.owner"
                                            :disabled="answer.form.payment.with != 'owner'"
                                            v-model="answer.form.payment_details.with.owner"
                                        >
                                            <template #append>zł</template>
                                        </InputGroup>
                                    </li>
                                    <li class="d-flex align-items-center">
                                        <Checkbox
                                            label="Płatność ponosi przejmujący odpady"
                                            name="payment.with"
                                            type="radio"
                                            data="recipient"
                                            :bold="false"
                                            v-model="answer.form.payment.with"
                                        />
                                        <InputGroup
                                            class="ml-2 w-auto"
                                            placeholder="Cena"
                                            step="0.01"
                                            type="number"
                                            id="payment_details.with.recipient"
                                            :disabled="answer.form.payment.with != 'recipient'"
                                            v-model="answer.form.payment_details.with.recipient"
                                        >
                                            <template #append>zł</template>
                                        </InputGroup>
                                    </li>
                                </ul>
                            </transition>
                        </div>
                    </div>
                    <div class="col-12 border-bottom">
                        <h6 class="font-weight-bold my-3">Sposób przetwarzania odpadów*</h6>
                        <ul>
                            <li class="d-flex flex-wrap">
                                <Checkbox
                                    v-for="(process, key) in processes"
                                    :key="key"
                                    type="radio"
                                    class="custom-control-inline"
                                    :bold="false"
                                    name="process"
                                    :errorMessage="false"
                                    :label="process"
                                    :data="key"
                                    v-model="answer.form.process"
                                />
                            </li>
                            <template v-if="$root.errors.values && $root.errors.values['process']">
                                <li>
                                    <div
                                    :class="[
                                            $root.errors.values && $root.errors.values['process'] ? 'is-invalid' : ''
                                        ]"
                                    ></div>
                                    <strong class="invalid-feedback">
                                        {{$root.errors.values['process'][0]}}
                                    </strong>
                                </li>
                            </template>
                        </ul>
                    </div>
                    <div class="col-12">
                        <h6 class="font-weight-bold my-3">Dodatkowe informacje</h6>
                        <InputLabel
                            type="textarea"
                            v-model="answer.form.additional"
                        />
                    </div>
                </div>
                <div v-else-if="!user" class="text-left">
                    <p class="text-left h5 pb-3 mb-3 border-bottom">{{ answer.data ? 'Funkcjonalność' : 'Informacja'}} dostepna dla zalogowanych uzytkowników</p>

                    <div>
                        <LoginForm />
                        <a href="/register#rejestracja" class="btn mt-3 btn-scale waste-container text-white">Zarejestruj się</a>
                    </div>
                </div>
                <div v-else-if="$props.user && !$props.isVerified">
                    <p class="text-left h5 pb-3 mb-3 border-bottom">Informacja dostepna dla zweryfikowanych uzytkowników</p>
                </div>
            </div>
            <div class="modal-footer">
                <button v-if="answer.data" type="submit" class="btn waste-container text-white">Wyslij ofertę</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal">{{answer.data ? 'Anuluj' : 'Zamknij'}}</button>
            </div>
            </form>
        </div>
    </div>
    <div class="modal fade" id="modal-images" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
        <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body position-relative">
            <!-- carousel -->
            <div
                v-if="activeModalImages.loading"
                class="d-flex justify-content-center align-items-center position-absolute spinner"
            >
                <div class="sticky-top">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <div
                v-else-if="activeModalImages && activeModalImages.images && activeModalImages.images.length > 1"
                id='carouselExampleIndicators3'
                class='carousel slide'
                data-ride='carousel'
            >
                <div class='carousel-inner'>
                    <div
                        class='carousel-item'
                        v-for="(item, key) in activeModalImages.images"
                        :key="item.id"
                        :class="{
                            active: activeModalImages.active == key
                        }"
                    >
                        <img class='img-size img-fluid' :src="item.url" />
                    </div>
                </div>
                <a
                    class='carousel-control-prev'
                    href='#carouselExampleIndicators3'
                    role='button'
                    data-slide='prev'
                    style="top:40% !important;"
                >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="#909090" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
                    </svg>
                </a>
                <a
                    class='carousel-control-next'
                    href='#carouselExampleIndicators3'
                    role='button'
                    data-slide='next'
                    style="top:40% !important;"
                >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="#909090" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                    </svg>
                </a>
            </div>
            </div>
        </div>
        </div>
    </div>
</div>
</template>

<script>
import InputLabel from 'UI/InputLabel.vue';
import Geocoder from 'UI/GeocoderInput.vue';
import Checkbox from 'UI/Checkbox.vue';
import Select from 'UI/Select.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import LoginForm from 'UI/LoginForm';
import InfoIcon from 'UI/InfoIcon';
import Slider from 'UI/Slider';

import InputGroup from 'UI/InputGroup';

import {
    getDate,
    getFullTime,
} from 'helper/moment';

export default {
    props: ['user', 'trashes', 'transports', 'processes', 'units', 'adminInfo', 'isVerified'],
    components: {
        InputLabel,
        Geocoder,
        Checkbox,
        Select,
        GoogleMap,
        LoginForm,
        InfoIcon,
        Slider,
        InputGroup,
    },
    data() {
        return {
            activeModalImages: {
                active: null,
                images: [],
                loading: false,
            },
            flashAnswer: false,
            contact: { data: null, lat: null, lng: null, type: null, },
            answer: {
                data: null,
                form: this.initialFormAnswer(),
                loading: false,
            },
            offers: [],
            form_current: null,
            form: {
                code: this.getParams('code'),
                location: this.getParams('location'),
                lat: this.getParams('lat'),
                lng: this.getParams('lng'),
                area: this.getParams('area'),
                from: this.getParams('from'),
                to: this.getParams('to'),
                unit: this.getParams('unit', 'all'),
                transport: this.getParams('transport', ['with_transport', 'without_transport']),
                list: 1,
            },
            loading: false,
            openMap: false,
            currentSearch: true,
            showMore: {
                value: false,
                type: null,
                last: null,
                last_key: null,
                height: null,
                active: null,
            },
            reloadSearch: null,

            showMarker: null,
            trash: {},
            activeElement: [],
            lastActiveElement: [],
        }
    },
    beforeMount() {
        this.refreshView();
    },
    mounted() {
        this.fetchData();
        $('#list-tab a').on('click', (ev) => {
            if($(ev.target).attr('href') == '#list-waste-market'){
                $('.mg-info').hide();
                $('section.articles').hide();
                this.refreshView();
            }
            else {
                $('.mg-info').show();
                $('section.articles').show();
            }
        })

        $('#contact-modal').on('hidden.bs.modal', (event) => {
            this.contact.data = null;
            this.contact.type = null;

            this.answer.data = null;
        })
    },
    methods: {
        async fetchData(){
            try {
                await axios.post('/api/search/market', {...this.$route.query, user: this.$props.user ? this.$props.user.id : null})
                .then((response) => {
                    this.offers = response.data;

                    this.$nextTick(() => {
                        if(this.$route.query.id){
                            this.$nextTick(() => {
                                $('.single-offer[data-id="'+this.$route.query.id+'"]')[0].scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                })
                                $('.single-offer[data-id="'+this.$route.query.id+'"]').css({
                                    'transform':'scale(1.05)',
                                    'position': 'relative',
                                    'z-index': 2
                                })

                                setTimeout(() => {
                                    $('.single-offer[data-id="'+this.$route.query.id+'"]').css({
                                        'transform':'',
                                        'position': '',
                                        'z-index': ''
                                    })
                                }, 2000)
                            })
                        }
                    })
                })
            } catch(err) {
                console.log(err)
            }
        },
        update(key){
            console.log(key)
        },
        refreshView(){
            this.$nextTick(() => {
                let interval = setInterval(() => {
                    if($('.agile').length){
                        if($('.agile').width() || $('.agile').width() > 0) {
                            this.$refs.carousel.forEach(element => {
                                element.reload();
                            });
                            clearInterval(interval);
                        }
                    }

                    $(this.$refs.details).each((key, el, a) => {
                        // this.getHeightOfElement(el, key);

                        if(key == this.$refs.details.length - 1) {
                            clearInterval(interval);
                        }
                    })


                }, 200)
            })
        },
        getHeightOfElement(el, key)
        {
            let height = 0;
            let a = this.$nextTick(() => {
                height = $(el).parent().height();
                height -= $(this.$refs.btn[key]).outerHeight(true);

                $(el).css('height', window.screen.width < 768 ? 280 : height);
                $(el).data('height', window.screen.width < 768 ? 280 : height);
            })
        },
        getTrashData(val) {
            this.form.code = Number(val.id);
            this.trash = val;
        },
        showMoreDetails(el, type, key) {
            this.showMore.value = el.id == this.showMore.value ? false : el.id;
            this.showMore.type = type;

            if(type == 'search'){
                if(this.showMore.active && this.showMore.active != this.$refs.details[key]){
                    this.activeElement[key] = null;

                }

                this.showMore.active = this.$refs.details[key];

                if(this.activeElement[key] === null || this.activeElement[key] === undefined){
                    this.activeElement[key] = $(this.showMore.active).parent().outerHeight();
                }

                let scrollHeight = $(this.showMore.active)[0].scrollHeight;
                let isTheSame = this.showMore.active == this.showMore.last;

                $($(this.showMore.active).parent()).animate({
                    height: ( isTheSame && this.showMore.active.classList.contains('active') ) ? this.activeElement[key] : scrollHeight,
                }, 500, () => {
                    if(!this.showMore.active.classList.contains('active')){
                        $(this.showMore.active).parent().css('height', '');
                        this.activeElement[key] = null;
                    }
                })

                if(!isTheSame && this.showMore.last) {
                    // console.log(this.showMore.last, this.lastActiveElement[key])

                    $($(this.showMore.last).parent()).animate({
                        height: this.lastActiveElement[this.showMore.last_key],
                    }, 500, () => {
                        if(!this.showMore.last.classList.contains('active')){
                            $(this.showMore.last).parent().css('height', '');
                            this.lastActiveElement[key] = null;
                        }
                    })
                }

                this.showMore.last = this.$refs.details[key];
                this.showMore.last_key = key;
                this.lastActiveElement[key] = this.activeElement[key];
            }
        },
        beforeEnter(el){
            $(el).css('display', 'none');
        },
        afterEnter(el){
            // if(this.showMore.type == 'search' && $('ul.more').first().length){
            //     $('ul.more').first()[0].scrollIntoView({
            //         behavior: "smooth",
            //         block: "center",
            //     });
            // }
            // else {
            //     el.scrollIntoView({
            //         behavior: "smooth",
            //         block: "center",
            //     });
            // }

        },
        slideEnter(el, done){
            $(el).slideDown(500, () => done());
        },
        slideLeave(el, done){
            $(el).slideUp(500, () => done());
        },
        getParams(val, dafault = null){
            if(val == 'transport' && !Array.isArray(this.$route.query[val])){
                let arr = [];
                if(this.$route.query[val]) {
                    arr.push( this.$route.query[val] );

                    return arr;
                }
                else return dafault;
            }
            else return this.$route.query[val] ? this.$route.query[val] : dafault;

        },
        searchResult(ev){

            axios.post('/api/search/market', {...this.form, user: this.$props.user ? this.$props.user.id : null})
            .then((response) => {
                this.offers = response.data;
                this.clearErrors();

                this.currentSearch = true;

                this.$router.replace({ query: null });
                this.$router.replace({ query: this.form })

                this.loading = false;

                this.refreshView();
            })
            .catch((error) => this.showErrors(error))
            .finally(() => {
                this.loading = false;
                $('.reload-search').remove();
            });
        },
        sendAnswer(ev){
            if(this.answer.data && this.$props.user && this.$props.user.id != this.answer.data.user_id && this.$props.isVerified) {
                this.answer.loading = true;

                axios.post('/market/answer/' + this.answer.data.id, this.answer.form)
                .then((response) => {
                    this.clearErrors();
                    $('#contact-modal').modal('hide');
                    this.flashAnswer = false;
                    this.$root.flash = response.data;
                    // console.log(response)
                    this.answer.form = this.initialFormAnswer();

                    this.loading = false;
                    this.answer.loading = false;
                })
                .catch((error) => {
                    this.flashAnswer = true;
                    this.showErrors(error);

                    this.loading = false;
                    this.answer.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                    this.answer.loading = false;
                    $('.reload-search').remove();
                });
            }
        },
        updateLocation(val, form){
            for (const [key, v] of Object.entries(val)) {
                form[key] = v;
            }
        },
        objectCount(item){
            return Object.size(item);
        },

        showMap(){
            if(this.currentSearch)
                this.openMap = !this.openMap;
        },
        initialFormAnswer(){
            return {
                transport_answer: [],
                amount: '',
                process: '',
                payment: {
                    with: '',
                    without: '',
                },
                payment_details: {
                    with: {
                        recipient: null,
                        owner: null,
                    },
                    without: {
                        recipient: null,
                        owner: null,
                    }
                },
                recipient: this.$props.user ? (this.$props.user.company_name ? this.$props.user.company_name : this.$props.user.full_name) : null,
                email: this.$props.user ? this.$props.user.email : null,
                phone: this.$props.user ? this.$props.user.phone : null,
                location: this.$props.user ? this.$props.user.address : null,
                lat: this.$props.user ? this.$props.user.lat : null,
                lng: this.$props.user ? this.$props.user.lng : null,
                additional: null,
            }
        },
        setActiveModalImage (images, key) {
            this.activeModalImages.loading = true;
            this.activeModalImages.active = key;
            this.activeModalImages.images = images;


            setTimeout(() => {
                this.activeModalImages.loading = false;
            }, 500);
        },

        checkUserDataAndOpenContactModal (type, item, event) {
            if(this.$props.user && !this.$props.user.firstname && $('#needCompleteUserData').length) {
                $('#needCompleteUserData').modal('show')

                if (event) event.preventDefault();

                return;
            }

            if (type === 1) {
                this.contact.data = null;
                this.answer.data = item;
                this.answer.form.amount = item.size;
            }
            else if (type === 2) {
                this.answer.data = null;
                this.contact.data = item;
                this.contact.type = 'search';
            } else if (type === 3) {
                this.contact.data = null;
                this.answer.data = item;
                this.answer.form.amount = item.size;
            }
        },
        getDate,
        getFullTime
    },

    watch: {
        form: {
            deep: true,
            handler(val){
                this.loading = true;

                clearInterval(this.reloadSearch);

                this.reloadSearch = setTimeout(() => {
                    this.searchResult();
                }, 1500);

            }
        },
        openMap(val){
            if(val == false) {
                this.showMarker = null;
            }
            else {
                this.showMore.value = false;
                this.showMore.type = null;
            }
        }
    }
}
</script>

<style scoped>
.btn-details-offer{
    bottom: 0;
    left: 15px;
    right: 15px;
}
.bg-waste-offer {
    z-index: 1;
}
.list-group .list-group-item .h5{
    margin-bottom: 0 !important;
}
.list-group .list-group-item {
    padding: 0.25rem 1.25rem;
}
.btn-map {
    background-image: url('/img/site/map.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.fade-enter-active, .fade-leave-active, .fade-move {
  transition: all .2s ease;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.btn-map .btn:hover{
    transform: scale(0.9);
}
.opacity-50 {
    opacity: 0.4 !important;
}
.image-sticky {
    top: 100px;
}
.spinner:not(.spinner-answer) {
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;
}
.spinner-answer {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background: rgb(248, 248, 248, 0.4);
}
.spinner .spinner-border{
    width: 4rem;
    height: 4rem;
    border-width: 0.5em;
}
.text-map {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.list-disc {
    list-style-type: disc !important;
}
.fade-enter-active, .fade-leave-active {
    transition: all .3s ease;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
    transform: scale(0.9);
}
.single-offer{
    transition: all .3s ease-in-out;
}
#modal-images .img-size.img-fluid {
    max-height: calc(100vh - 100px);
    margin: auto;
    display: block;
    width: 100% !important;
    object-fit: contain;
}
#modal-images .carousel-control-next svg, #modal-images .carousel-control-prev svg {
    stroke: #fff;
}
#modal-images .modal-content {
    background-color: transparent;
    border: none;
}
#modal-images .modal-body {
    padding: 0 !important;
}
.modal-dialog.modal-xl {
    max-width: 80%;
}
.modal-close.map-close {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 1;
    transition: all .5s ease;
    width: 40px;
    height: 40px;
    z-index: 10;
}
.modal-close.map-close:hover {
    background-color: rgb(223, 223, 223) !important;
}
.alert {
    z-index: 9999;
}
@media (min-width: 768px) {
    .details-offer{
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
}
}
@media only screen and (max-width: 1250px){
    .modal-dialog.modal-xl {
        max-width: 100%;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
@media only screen and (max-width: 767.98px){
    .title-offer {
        font-size: 1rem;
    }
    .details-wrapper {
       height: 300px;
       overflow: hidden;
    }
    .details-offer{
        position: relative !important;
    }
}
@media only screen and (max-width: 600px){
    .modal-close.map-close {
        top: 5px;
        right: 5px;
    }
}
@media only screen and (max-width: 576px) {
    .modal-dialog.modal-xl {
        margin-left: .5rem;
        margin-right: .5rem;
    }
}
</style>
