<template>
<agile ref="carousel"
    :dots="false"
>
    <div
        class="slide"
        v-for="(image) in data" :key="image.id"
    >
        <img :src="image.url" class="mw-100 mh-100 m-auto d-block border p-2" />
    </div>

    <template slot="prevButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
        <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
        </svg>
    </template>
    <template slot="nextButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
        </svg>
    </template>
</agile>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
    props: ['data', 'refresh'],
    components: {
        agile: VueAgile
    },

    mounted() {
        this.$nextTick(() => {
            this.$refs.carousel.reload();
        })
    },

    watch: {
        refresh(val){
            if(val) {
                this.$nextTick(() => {
                    this.$refs.carousel.reload();
                })
            }
        }
    }
}
</script>

<style>

</style>
