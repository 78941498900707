/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('../../node_modules/bootstrap-select/dist/js/bootstrap-select');
require('../../node_modules/@googlemaps/markerclustererplus');

$.fn.selectpicker.Constructor.BootstrapVersion = '4';

// Require Vue
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueAgile from 'vue-agile'
import __ from "helper/trans";

Vue.use(VueAgile)
Vue.use(VueRouter)
Vue.prototype.__ = __;

Vue.mixin({
    methods: {
        showErrors: function (error) {
            if (error.response) {
                this.$root.flash = {};
                this.$root.flash.error = (error.response.data.message == 'The given data was invalid.' ?
                    'Podane dane są nieprawidłowe bądź niekompletne!' : error.response.data.message);

                this.$root.errors.values = error.response.data.errors;
            }
            else if (error.request) {
                let errorMessage = JSON.parse(error.request.response);

                this.$root.flash = {};
                this.$root.flash.error = (errorMessage.error == 'The given data was invalid.' ?
                    'Podane dane są nieprawidłowe bądź niekompletne!' : errorMessage.error);

                this.$root.errors.values = errorMessage.messages;
            }
            else console.log('Error', error.message);
        },
        clearErrors: function (error) {
            this.$root.errors.values = null;
            this.$root.flash = null;
        },
    },
  })

Vue.component('market-create', require('./components/Page/Market/Create.vue').default);
Vue.component('market-index', require('./components/Page/Market/Index.vue').default);
Vue.component('trash-params', require('./components/Params.vue').default);
Vue.component('recipient-offer-create', require('./components/Page/WastePoints/Create.vue').default);
Vue.component('recipient-offer-index', require('./components/Page/WastePoints/Index.vue').default);
Vue.component('panel-newsletter', require('./components/Page/Newsletter.vue').default);
Vue.component('waste-point-trashes', require('./components/WastePointTrashes.vue').default);

Object.filter = (obj, predicate) =>
    Object.keys(obj)
          .filter( key => predicate(obj[key]) )
          .reduce( (res, key) => (res[key] = obj[key], res), {} );

Object.size = function(obj) {
var size = 0,
    key;
for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
}
return size;
};

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}


const routes = [
    {
        path: '/',
        component: Vue.component('search-component', require('./components/Search.vue').default),
        name: 'search'
    },
  ]

const router = new VueRouter({
    mode: 'history',
    routes
})

// Initialize Vue
if(document.getElementById('app')){

    // axios.get('/api/lang')
    // .then((response) => {
    //     window.i18n = response.data;
    // })
    // .finally(() => {

        const app = new Vue({
            data() {
                return {
                    errors: {
                        values: null,
                        message: null,
                    },
                    flash: null,
                    interval: 0,
                }
            },
            watch: {
                flash(val){
                    if(val) {
                        let i = 10000;
                        let timer = setInterval(() => {
                            i = i - 100
                            this.interval = (i / 1000).toFixed(1);
                        }, 100)

                        setTimeout(() => {
                            this.flash = null;
                            clearInterval(timer);
                        }, 10000)
                    }
                }
            },
            router
        }).$mount('#app');
    // })
}
