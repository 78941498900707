<template>
<div>
    <Select
        class="form-group"
        label="Duplikuj zapisany wariant"
        name="template"
        :live-search="true"
        :size="10"
        :data="paramsTemplate"
        :clearOption="false"
        v-model="duplicate"
    >
    <template #default="{ data, selected }">
        <option
            v-for="item in data"
            :key="item.id"
            :value="JSON.stringify(item)"
            :selected="selected && selected.id == item.id"
            :data-content="'<h6 class=\'mb-0\'><b>Parametry:</b> ' + item.params + '<br><b>Zanieczyszczenia:</b> ' + item.pollutions + '</h6>'"
        ></option>
    </template>
    </Select>

    <div class="rounded">
        <div class="px-3 pb-3 rounded border">
            <div class="row">
                <div class="col-md-6 border-right d-flex flex-column">
                    <div class="row mb-2 border-bottom text-center font-weight-bold bg-light p-3">
                        <div class="col-12">
                            <h5 class="mb-0">Parametr</h5>
                        </div>
                    </div>
                    <InputLabel
                        placeholder="Wpisz parametr"
                        :id="'params' + key"
                        class="mb-2 position-relative"
                        name="params[]"
                        v-for="(value, key) in params"
                        :key="key"
                        v-model="params[key]"
                        @enter="ev => addNew(ev, 'params', key)"
                    >
                        <button class="btn-close rounded-circle" type="button" @click="params.splice(key, 1)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </InputLabel>
                    <button
                        class="btn waste-container text-white btn-scale d-block px-5 mt-2 mx-auto d-block"
                        type="button"
                        @click="ev => {
                            if( params == null || (params && params[params.length - 1]) || (params && params.length == 0) ) params.push('')
                        }"
                    >Dodaj +</button>
                    <p class="small text-center mb-0 mt-2">Klawiszem <b>"Enter"</b> możesz dodać koleją wartość</p>

                    <template v-if="user.params && user.params.length">
                        <div class="mt-auto">
                            <h5 class="mt-3">Parametry użytkowników</h5>
                            <template
                                v-for="(value, key) in user.params"
                            >
                            <div
                                v-if="!params.includes(value)"
                                :key="'params-' + key"
                                class="mb-1 p-1 position-relative border rounded bg-light justify-content-between d-flex align-items-center"
                            >
                                <span>{{ value }}</span>
                                <button
                                    class="btn px-2 mr-3 py-0 btn-secondary ml-auto rounded" type="button"
                                    @click="ev => addNew(value, 'params', 'user')"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                </button>

                                <button class="btn-close rounded-circle" type="button" @click="removeUserParams('params', value, key)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            </template>
                        </div>
                    </template>
                </div>
                <div class="col-md-6 d-flex flex-column">
                    <div class="row mb-2 border-bottom text-center font-weight-bold bg-light p-3">
                        <div class="col-12">
                            <h5 class="mb-0">Rodzaje zanieczyszczeń</h5>
                        </div>
                    </div>
                    <InputLabel
                        placeholder="Wpisz rodzaj zanieczyszczeń"
                        :id="'pollutions' + key"
                        name="pollutions[]"
                        class="mb-2 position-relative"
                        v-for="(value, key) in pollutions"
                        :key="key"
                        v-model="pollutions[key]"
                        @enter="ev => addNew(ev, 'pollutions', key)"
                    >
                        <button class="btn-close rounded-circle" type="button" @click="pollutions.splice(key, 1)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </InputLabel>
                    <button
                        class="btn waste-container text-white btn-scale d-block px-5 mt-2 mx-auto d-block"
                        type="button"
                        @click="ev => {
                            if( pollutions == null || ( pollutions && pollutions[pollutions.length - 1]) || (pollutions && pollutions.length == 0 ) ) pollutions.push('')
                        }"
                    >Dodaj +</button>
                    <p class="small text-center mb-0 mt-2">Klawiszem <b>"Enter"</b> możesz dodać koleją wartość</p>

                    <template v-if="user.pollutions && user.pollutions.length">
                        <div class="mt-auto">
                            <h5 class="mt-3">Parametry użytkowników</h5>
                            <template
                                v-for="(value, key) in user.pollutions"
                            >
                                <div
                                    v-if="!pollutions.includes(value)"
                                    :key="'pollutions-' + key"
                                    class="mb-1 p-1 position-relative border rounded bg-light justify-content-between d-flex align-items-center"
                                >
                                    <span>{{ value }}</span>
                                    <button
                                        class="btn px-2 py-0 mr-3 btn-secondary ml-auto rounded" type="button"
                                        @click="ev => addNew(value, 'pollutions', 'user')"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                        </svg>
                                    </button>
                                    <button class="btn-close rounded-circle" type="button" @click="removeUserParams('pollutions', value, key)">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import InputLabel from 'UI/InputLabel.vue';
import Select from 'UI/Select.vue';
export default {
    components: {
        InputLabel,
        Select
    },
    props: ['trashParams', 'paramsTemplate', 'userParams'],
    data() {
        return {
            params: this.$props.trashParams ? this.$props.trashParams.params ?? [] : [],
            pollutions: this.$props.trashParams ? this.$props.trashParams.pollutions ?? [] : [],

            duplicate: null,

            user: {
                params: this.$props.userParams ? this.$props.userParams.params ?? [] : [],
                pollutions: this.$props.userParams ? this.$props.userParams.pollutions ?? [] : [],
            }
        }
    },
    methods: {
        addNew(ev, type, key){
            if(key != 'user'){
                if(key == this[type].length - 1 && ev.target.value) {
                    this[type].push('');
                    this.$nextTick(() => {
                        $('#' + type + (key + 1)).focus();
                    })
                }
            }
            else {
                this[type].push(ev);
            }
        },

        removeUserParams(type, value, key){

            axios.post('/trash/params/remove', {
                type: type,
                value: value,
                trash_id: this.trashParams.trash_id
            })
            .then(() => this.user[type].splice(key, 1))
            .catch(errors => {
                console.log(errors)
            })
        }
    },
    watch: {
        duplicate(val){
            if(val){
                val.params.forEach(element => {
                    this.params.push(element);
                });

                val.pollutions.forEach(element => {
                    this.pollutions.push(element);
                });
            }
        }
    }
}
</script>

<style scoped>
.btn-close {
    background-color: red;
    opacity: 0.5;
    outline: none;
    border: 0;
    width: 25px;
    height: 25px;
    color: white;
    font-size: 1.35rem;
    line-height: 1;
    position: absolute;

    top: 50%;
    right: -12.5px;
    transform: translateY(-50%);
}
.btn-close:hover {
    opacity: 0.8;
}
</style>
