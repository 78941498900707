import moment from 'moment-timezone';
moment.locale('pl');
// time
export function getFullTime(date = null, format = null) {
    // , 'YYYY-MM-DD HH:mm:ss'
    return moment( date ? date : new Date() ).tz('Europe/Warsaw').format(format ? format : 'HH:mm:ss');
}
export function getDate(date = null, format = null) {
    return moment( date ? date : new Date(), 'YYYY-MM-DD' ).format(format ? format : 'DD-MM-YYYY');
}
export function getYear(date = null, format = null) {
    return moment( date ? date : new Date(), 'YYYY-MM-DD' ).format(format ? format : 'YYYY');
}
export function getDateWithTime(date = null, format = null) {
    // , 'YYYY-MM-DD HH:mm:ss'
    return moment( date ? date : new Date()).tz('Europe/Warsaw').format(format ? format : 'DD-MM-YYYY HH:mm:ss');
}
export function getDateEndOf(date = null) {
    return moment(date ? date : new Date(), 'YYYY-MM-DD').endOf('day').fromNow();
}
export function isAfterDate(date = null) {
    return moment( new Date() ).isAfter(date, 'day');
}
export function getDiffDate(date1, date2 = new Date()){
    var from = moment(date1);
    var to = moment(date2);
    return from.diff(to, 'days');
}
export function addToDate(date = new Date(), add, type = 'days'){
    return moment(date, 'YYYY-MM-DD').add(add, type);
}
