<template>
<div class="d-flex flex-md-row flex-column h-100">
    <div
        v-if="list"
        ref="searchList"
        class="search-list-container rounded p-2 position-relative order-md-0 order-1 border-right"
    >
    <div
        v-if="loadingMapOffer"
        class="d-flex justify-content-center align-items-center position-absolute spinner"
    >
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
            <transition-group
                class="h-100 overflow-y-auto search-list p-0 position-relative"
                tag="ul"
                mode="in-out"
            >
            <li
                v-for="(location, key) in visibleMarkers"
                :key="location.id"
                class="shadow-sm rounded border search-result mr-2"
                :class="[
                    key != (visibleMarkers.length - 1) ? 'mb-2' : '',
                    activeMarker.location == location.id ? 'active' : '',
                ]"
                @click="changeActiveMarker(location.id, location)"
            >
                <slot name="list-result" :location="location" :refresh="refreshSlider" />
            </li>
            <li key="none" class="font-weight-bold h5 text-center mt-3 bg-white" v-if="visibleMarkers.length == 0 && !loadingMapOffer">
                <span>W danym obszarze mapy nie ma dostępnych ofert</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-emoji-frown" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                </svg>
            </li>
        </transition-group>
    </div>
    <div ref="searchMap" class="search-map position-relative h-100 order-md-1 order-0"
        :class="list ? 'list' : 'w-100'"
    >
        <div ref="gmap" id="map-v" class="h-100"></div>
        <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center position-absolute spinner"
        >
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Button from './ui/Button.vue';
export default {
  components: { Button },
    props: {
        data: {},
        area: {},
        coords: {},
        location: {},
        list: {
            type: Boolean,
            default: true,
        },
        areaOpacity: Number,
    },
    data() {
        return {
            map: null,
            interval: null,
            circle: null,
            markerMe: null,
            markerCluster: null,
            overlay: null,
            option: {
                enableHighAccuracy: true,
                timeout: 5 * 1000,
                maximumAge: 0
            },
            first: true,
            currentLatLng: null,
            markers: [],
            markers1: [],

            image: null,
            activeMarker: {
                marker: null,
                location: null,
            },
            hoverMarker: {
                marker: null,
                location: null,
            },
            showMarker: this.$props.location,
            loading: true,

            refreshSlider: false,
            visibleMarkers: [],
            loadingMapOffer: true,
            timer: null,
        }
    },
    mounted() {
        if(!this.coords.lat && !this.coords.lng)
            this.geoSearch();
        else
            this.currentLatLng = new google.maps.LatLng(this.coords.lat, this.coords.lng)

        this.interval = setInterval(() => {
            if(window.google && this.currentLatLng) {
                this.stopInterval();
                this.map = new google.maps.Map(this.$refs.gmap, {
                    zoom: 13,
                    maxZoom: 20,
                    clickableIcons: false,
                    center: this.currentLatLng,
                    streetViewControl: false,
                    fullscreenControl: false
                });

                this.markerMe = new google.maps.Marker({
                    position: this.currentLatLng,
                    map: this.map,
                    clickable: false,
                });

                this.customMarker(null, 'default');

                if(this.$props.data)
                    this.setMarkers(this.$props.data);
                else {
                    this.loading = false;

                    if(
                        this.$props.area && !this.coords ||
                        (
                            this.coords &&
                            this.$props.area &&
                            this.coords.lat &&
                            this.coords.lng
                        )
                    ) {
                        this.setCustomArea(this.$props.area, this.$props.areaOpacity ? this.$props.areaOpacity : 0);
                    }
                }

                this.map.addListener('zoom_changed', (ev) => this.zoomChangeEvent(ev));
                this.map.addListener('bounds_changed', (ev) => this.zoomChangeEvent(ev));

                google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
                    this.zoomChangeEvent()
                });
            }
            a++;
        }, 100)
    },

    methods: {
        stopInterval(){
            clearInterval(this.interval);
        },
        geoSearch() {

            navigator.geolocation.getCurrentPosition((position) => {
                this.currentLatLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            },
            (msg) => {
                alert('Udostępnij lokalizację w urządzeniu.');
                clearInterval(this.interval);
                this.$emit('blocked', false);
            },
            this.option);

        },
        zoomChangeEvent(ev){
            this.loadingMapOffer = true;

            this.stopTimeout();

            this.timer = setTimeout(() => {
                this.visibleMarkers = [];
                let count = 0;

                let marker = this.activeMarker.marker;
                if(marker && marker.getDiv() && marker.active) {
                    if(!$( marker.getDiv() ).hasClass('active'))
                        $( marker.getDiv() ).addClass('active')
                }

                this.markers.forEach((el) => {
                    if(this.checkIfMarkerIsInBounds(el)) {
                        count++;
                        let location = this.$props.data.filter(item => item.id == el.id)[0];
                        this.visibleMarkers.push(location);
                    }
                })

                this.loadingMapOffer = false;

                this.scrollToElement();

            }, 500)

        },
        stopTimeout() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            };
        },
        scrollToElement(el) {
            this.$nextTick(() => {
                if($('.search-result.active').length){
                    $('.search-result.active').first()[0].scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            });
        },
        customMarker(marker = null, color = '#2DAB6A', el){
            var svgTemplate = [
                `<svg xmlns="http://www.w3.org/2000/svg"
                    class="btn process-r p-0"
                    width="2rem" height="2rem"
                    viewBox="0 0 24 24"
                    fill="{{ color }}"
                    style="transition: all .5s ease"
                >
                {{ path }}
                </svg>`
                ].join('\n');
                let process1 = `<path d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"/>`

                let process2 = `<path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>`

            var svg = svgTemplate.replace('{{ color }}', color == 'default' ? '#2DAB6A' : color).replace('{{ path }}', process1);

            if(marker && marker.icon){
                marker.icon.url = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);
            }
            else {
                this.image = {
                    // url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
                    url:  '../img/site/marker_new.png',
                    scaledSize: new google.maps.Size(70, 70)
                };
            }

        },

        setMarkers(data){
            let boundsAll = new google.maps.LatLngBounds();
            data.forEach((el, key) => {
                let LatLng = new google.maps.LatLng(el.location.lat, el.location.lng);
                if(this.markers.length != 0) {
                    for (let i = 0; i < this.markers.length; i++) {
                        var existingMarker = this.markers[i];
                        var pos = existingMarker.position;

                        if (LatLng.equals(pos)) {
                            var a = 360.0 / this.markers.length;
                            var newLat = pos.lat() + -.00005 * Math.cos((+a*i) / 180 * Math.PI);  //x
                            var newLng = pos.lng() + -.00005 * Math.sin((+a*i) / 180 * Math.PI);  //Y
                            LatLng = new google.maps.LatLng(newLat, newLng);
                        }
                    }
                }

                let htmlMarker = `<div class='content position-relative text-white text-center rounded p-1 d-flex justify-content-center align-items-center' style='width: 70px; height: 30px; background: #2DAB6A;'>
                        <div class='bg-light border rounded position-absolute text-dark font-weight-bold px-1' style="bottom: calc(100% - .25rem);">
                            ${ el.trash.code + (el.trash.is_dangerous ? '*' : '') }
                        </div>
                        <span class='font-weight-bold'>
                            ${ el.size } [Mg]
                        </span>
                    </div>`;

                let bounds = new google.maps.LatLngBounds(
                    LatLng,
                );
                this.markers.push( this.generateSitePanel(bounds, htmlMarker, this.map, LatLng, el) );
            });

            this.markerCluster = new MarkerClusterer(this.map, this.markers,
            {
                imagePath: '../img/site/m',
                maxZoom: 19,
            });

            setTimeout(() => {
                if(this.$props.area) {
                    this.setCustomArea(this.$props.area);
                }
                else {
                    if(this.markerMe){
                        boundsAll.extend(
                            new google.maps.LatLng(
                                this.markerMe.position.lat(),
                                this.markerMe.position.lng()
                            )
                        );
                    }

                    for (var i = 0; i < this.markers.length; i++) {
                        let LatLngCurrent = new google.maps.LatLng(
                            this.markers[i].position.lat(),
                            this.markers[i].position.lng()
                        );

                        boundsAll.extend(LatLngCurrent);
                    }

                    this.map.fitBounds(boundsAll);
                }

                if(this.showMarker){
                    this.changeActiveMarker(this.showMarker.id, this.showMarker, true);
                }

                this.loading = false;
            }, 600)
        },
        generateSitePanel(bounds, html, map, position, item) {
            const _this = this;

            HTMLMarker.prototype = new google.maps.OverlayView();

            function HTMLMarker (bounds, html, map, position) {
                this.bounds_ = bounds;
                this.set('position', position);
                this.html_ = html;
                this.map_ = map;
                this.id = item.id;
                this.div_ = null;
                this.active = false;
                this.setMap(map);
            }

            HTMLMarker.prototype.getBounds = function() {
                return new google.maps.LatLngBounds(this.position, this.position);
            };

            HTMLMarker.prototype.getPoint = function() {
                var bounds = this.getBounds();
                var projection = this.getProjection();
                var sw = projection.fromLatLngToDivPixel(bounds.getSouthWest());
                    var ne = projection.fromLatLngToDivPixel(bounds.getNorthEast());

                return new google.maps.Point(sw.x, ne.y);
            };

            HTMLMarker.prototype.getSuperContainer = function(){
                var panes = this.getPanes();
                return $(panes ? panes.overlayImage : '');
            };

            HTMLMarker.prototype.getPosition = function() {
                return this.position;
            };
            HTMLMarker.prototype.setVisible = function(visible)
            {
                return visible ? 'block' : 'hidden';
            };
            HTMLMarker.prototype.getDraggable = function(){ return false; };
            HTMLMarker.prototype.getDiv = function(){ return this.div_; };

            HTMLMarker.prototype.onAdd = function() {
                var div = document.createElement('div');
                div.className = 'anim-marker';
                div.innerHTML = this.html_;
                div.style.position = 'absolute';
                this.div_ = div;
                var panes = this.getPanes();
                panes.overlayImage.appendChild(div);

                const marker = this;

                google.maps.event.clearListeners(div, 'click');
                div.addEventListener('click', function(event) {
                    _this.markerClickEvent(marker, item);
                });
            };

            HTMLMarker.prototype.draw = function() {
                var overlayProjection = this.getProjection();
                var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
                var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

                var div = this.div_;
                let width = $(div).width();
                let height = $(div).height();

                div.style.left = (sw.x - (width / 2) ) + 'px';
                div.style.top = (ne.y - (height + 5) ) + 'px';
            };

            HTMLMarker.prototype.onRemove = function() {
                this.div_.parentNode.removeChild(this.div_);
                this.div_ = null;
            };

            return new HTMLMarker(bounds, html, map, position);
        },
        markerClickEvent(marker, el, location = false){
            if(marker){
                if ($(marker.getDiv()).hasClass('active') && location == false) {
                    $(this.activeMarker.marker.getDiv()).removeClass('active');

                    this.activeMarker.marker.active = false;
                    this.activeMarker.location = null;
                    this.activeMarker.marker = null;
                }
                else {

                    if(this.activeMarker.marker) {
                        this.activeMarker.marker.active = false;
                        $(this.activeMarker.marker.getDiv()).removeClass('active');
                    }
                    if(!$(marker.getDiv()).hasClass('active')){
                        marker.active = true;
                        $(marker.getDiv()).addClass('active');
                    }

                    let last = this.activeMarker.location;

                    this.activeMarker.marker = marker;
                    this.activeMarker.location = el.id;

                    // this.$nextTick(() => {
                        // let searchList = $('.search-list');
                        // let active = searchList.find('.search-result.active');

                        // $(searchList).animate({
                        //     scrollTop:  searchList.scrollTop() - searchList.offset().top + active.offset().top - 30
                        // }, 'slow');
                    // })

                    let cluster = false;

                    if(location && this.markerCluster){
                        this.markerCluster.clusters_.forEach(el => {
                            let clusterMarkers = el.getMarkers();
                                clusterMarkers.forEach((item) => {
                                    if(item.id == this.activeMarker.location) {
                                        cluster = el;
                                    }
                                })
                        });

                        if(cluster){
                            var bounds = new google.maps.LatLngBounds();
                            cluster.getMarkers().forEach((element) => {
                                let LatLng = new google.maps.LatLng(element.position.lat(), element.position.lng());
                                bounds.extend(LatLng);
                            })
                            this.map.fitBounds(bounds);
                        }
                    }
                    this.map.panTo(marker.getPosition());
                }
            }
            else {
                $(this.activeMarker.marker.getDiv()).removeClass('active');

                this.activeMarker.marker.active = false;
                this.activeMarker.location = null;
                this.activeMarker.marker = null;

            }
        },
        setCustomArea(area, opacity = 0){
            if(this.circle != undefined)
                this.circle.setMap(null);

            this.circle = new google.maps.Circle({
                map: this.map,
                radius: ( Number(area) * 1000 ),
                fillColor: '#EC9C17',
                strokeColor: '#F07E12',
                fillOpacity: opacity,
            });
            this.circle.bindTo('center', this.markerMe, 'position');
            this.map.fitBounds(this.circle.getBounds());
        },
        changeActiveMarker(id, data, location = false) {
            if(id){
                let marker = this.markers.filter(el => el.id == id)[0];

                this.$nextTick(() => {
                    if(this.activeMarker.location != id){

                        marker.active = true;
                        this.markerClickEvent(marker, data, location);
                    }
                })
            }
            else {
                this.markerClickEvent(false);
            }
        },
        checkIfMarkerIsInBounds(marker){
            return this.map.getBounds().contains(marker.getPosition());
        },

        // Animate Map
        beforeEnter: function (el) {
            if(window.screen.width > 768)
                $(el).css({ width: 0 })
            else
                $(el).css({ width: 100 + '%', height: 0 })

        },
        enter: function (el, done) {
            let anim = window.screen.width > 768 ? { width: 50 + '%'} : { height: 65 + '%' }
            // search nav
            $(el).animate(anim,
            'slow', () => {
                done();
                this.refreshSlider = true;
                setTimeout(() => {
                    this.refreshSlider = false;
                }, 2000)
            })

            // map
            let animMap = window.screen.width > 768 ? { width: 50 + '%' } : { height: 35 + '%' }
            if(window.screen.width < 768){
                $(this.$refs.searchMap).removeClass('h-100');
                $(this.$refs.searchMap).css({ height: 100 + '%' })
            }

            $(this.$refs.searchMap).animate(animMap,
            'slow', () => done());
        },
        leave: function (el, done) {
            // search nav
            let anim = window.screen.width > 768 ? { width: 0 } : { height: 0 }
            $(el).animate(anim, 'slow', () => done())

            // map
            let animMap = window.screen.width > 768 ? { width: 100 + '%' } : { height: 100 + '%' }
            $(this.$refs.searchMap).animate(animMap,
            'slow', () => {
                if(window.screen.width < 768){
                    $(this.$refs.searchMap).addClass('h-100');
                }
                done()
            })
        }
    },
    watch: {
        area(val){
            if(!this.coords || (this.coords && this.coords.lat && this.coords.lng))
                this.setCustomArea(val, this.$props.areaOpacity);
        },
        coords: {
            deep: true,
            handler(val){
                if(val.lat && val.lng){
                this.currentLatLng = new google.maps.LatLng(val.lat, val.lng);

                this.markerMe.setMap(null);

                this.markerMe = new google.maps.Marker({
                    position: this.currentLatLng,
                    map: this.map
                });
                this.map.setCenter(this.currentLatLng);
                this.setCustomArea(this.$props.area, this.$props.areaOpacity);
                }
            }
        }
    }
}
</script>

<style scoped>
.search-list {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    /* min-width: 330px; */
}
.search-list-container {
    width: 40%;
}
.search-list .search-result:hover, .search-list .search-result.active  {
    /* background-color: rgb(230, 230, 230) !important; */
    cursor: pointer;
}
.search-map.list {
    width: 60%;
}
@media only screen and (max-width: 768px){
    .search-map.list {
        height: 30%;
        width: 100%;
    }
    .search-list-container {
        height: 70%;
        width: 100%;
    }
}
/* .slide-enter-active, .slide-leave-active, .slide-move {
  transition: all .5s ease;
}
.slide-enter, .slide-leave-to {
  transform: translateX(-100%);
}
.slide-leave-active {
  position: absolute;
}
.slide-enter-to {
    transform: translateX(0);
} */
/* .search-result {
    transition: all .5s ease;
} */
.search-result:hover {
    opacity: 0.7;
}
.search-result.active {
    opacity: 1;
    border-width: 3px !important;
    border-color: #EF8313 !important;
}
.overflow-y-auto{
    overflow-y: auto;
    overflow-x: hidden;
}
.close {
    top: 0px;
    right: 0px;
    left: auto;
    z-index: 5;
}
.spinner {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.4);
}
.spinner .spinner-border {
    width: 4rem;
    height: 4rem;
    border-width: 0.5em;
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: all .7s ease;
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0);
}

.list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}

</style>
