<template>
    <svg
        ref="infoIcon"
        xmlns="http://www.w3.org/2000/svg"
        :width="size"
        :height="size"
        fill="currentColor"
        class="bi bi-info-circle-fill"
        :viewBox="viewBox ? viewBox : '0 0 16 16'"
        :data-toggle="type"
        :data-placement="placement"
        :data-html="html"
        :title="title"
    >
        <slot v-if="$slots.default" />
        <path v-else d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </svg>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        type: {
            type: String,
            default: 'tooltip'
        },
        size: {
            type: String,
            default: '16'
        },
        placement: String,
        html: Boolean,
        viewBox: String,
    },

    mounted() {
        if(this.$props.type == 'tooltip') $(this.$refs.infoIcon).tooltip();
        else $(this.$refs.infoIcon).popover();
    },
}
</script>

<style>

</style>
