<template>
<div>
    <p :style="opened.length ? 'display:none;' : ''" class="text-center m-0 text-empty">Aktualnie nie wybrano żadnej z grupy odpadów</p>
    <template
        v-for="(group, trash_code) in $props.trashes"
    >
    <ul
        :key="group.id"
        class="mb-0 main-group-ul text-justify px-3" :data-maingroup="group.code"
        :style="getOld(`trash-code.${trash_code}`) ? '' : 'display:none'"

    >
        <li class="py-2">
            <span
                @click="ev => {
                    if(openedGroup.includes(group.id)) openedGroup.splice(openedGroup.indexOf(group.id) , 1)
                    else openedGroup.push(group.id)
                }"
                class="managing-table-group-select"
            >
                <span class="main-group">
                <b>{{group.code}}{{(group.is_dangerous) ? '*' : ''}}</b> - <b>{{group.description}}</b>
                </span>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="ml-3 bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
            </span>
            <ul
                v-if="opened.includes(group.code)"
                class="nested-group w-100 pl-4 tree-column active-group-selector subgroup-ul"
                :data-subgroup="group.code"
                style="display: none;"
            >
                <li
                    v-for="(childTrash, key) in group.children"
                    :key="childTrash.id"
                    class="subgroup-tree-li" :data-subgroup="childTrash.code"
                >
                    <div
                        class="custom-control custom-checkbox d-flex align-items-center managing-table-group-select tree-path position-relative py-2 subgroup-place-tree"
                        @click="ev => {
                            if(openedTrash.includes(childTrash.id)) openedTrash.splice(openedTrash.indexOf(childTrash.id) , 1)
                            else openedTrash.push(childTrash.id)
                        }"
                    >
                    <input type="checkbox" name="subgroup-code[]" class="custom-control-input subgroup"
                    :data-group="group.code" :value="childTrash.code" :id="'subgroup' + childTrash.id">
                    <b>{{childTrash.code}}{{ (childTrash.is_dangerous) ? '*' : ''}}</b> - {{childTrash.description}}
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="ml-3 arrow bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                    </div>
                    <ul
                        class="nested-group w-100 pl-4 tree-column active-group-selector subgroup-type-ul"
                        :data-code="childTrash.code"
                        style="display:none;"
                    >
                        <li>
                        <div class="row p-2 pb-4 m-0 shadow border rounded row-all-value" :data-id="key">
                            <div class="col-12 px-0 text-center order-0">
                            <b><u>Zdefiniuj wartości dla wszystkich odpadów w podgrupie</u></b>
                            </div>
                            <div class="col-12 col-md-4 col-lg-2 px-sm-2 px-0 mt-lg-0 mt-2 order-1">
                                <div class="form-group m-auto w-100">
                                    <small class="font-weight-bold">Cena [zł/Mg]</small>
                                    <input :name="`all-price[${key}]`" type="number" step="0.01" :data-subgroup="childTrash.code"
                                        :value="getOld('all-price.' + key)"
                                        :data-group="group"
                                        @change="ev => setAll(ev, 'price', key)"
                                        class="form-control price-all-type w-100" placeholder="Cena">
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 px-sm-2 px-0 mt-lg-0 mt-2 d-flex align-items-center order-lg-2 order-3">
                                <div class="form-group m-0">
                                    <small class="font-weight-bold">Proces przetwarzania</small>
                                    <div class="mt-1">
                                        <div v-for="(name, id) in process" :key="name" class="custom-control custom-radio custom-control-inline flex-wrap">
                                        <input class="custom-control-input process-all-type" type="radio"
                                        :data-subgroup="childTrash.code"
                                        :data-group="group.code" :id="`process-all-${key}${id}`" :name="`all-process[${key}]`"
                                        :value="id"
                                        :checked="getOld('all-process.' + key) == id">
                                        <label class="custom-control-label" :for="`process-all-${key}${id}`">{{ name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-2 px-sm-2 px-0 mt-lg-0 mt-2 d-flex order-lg-3 order-4">
                                <div class="form-group m-auto w-100">
                                <small class="font-weight-bold">Ograniczenia [Mg]</small>
                                <input :name="`all-max[${key}]`" step="0.01" :data-subgroup="childTrash.code" :data-group="group"
                                :value="getOld('all-max.' + key)" type="number" class="form-control max-all-type" placeholder="Ograniczenia">
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 px-sm-2 px-0 mt-lg-0 mt-2 d-flex order-lg-4 order-5">
                                <div class="form-group m-auto w-100">
                                <small class="font-weight-bold">Uwagi</small>
                                <textarea :name="`all-comment[${key}]`" oninput="auto_grow(this)" :data-subgroup="childTrash.code" :data-group="group" class="form-control comment-all-type" placeholder="Uwagi"
                                :value="getOld('all-comment.' + key)"></textarea>
                                </div>
                            </div>
                            <div class="col-12 px-sm-2 px-0 mt-2 order-lg-5 order-2">
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input individual-price" type="checkbox" :data-subgroup="childTrash.code" :data-group="group.code" :id="`individual-${key}`"
                                    :name="`individual_price[${key}]`"
                                        :checked="getOld('individual_price.' + key)"
                                    >
                                    <label class="custom-control-label" :for="`individual-${key}`">Cena ustalana indywidualnie</label>
                                </div>
                            </div>
                        </div>
                        </li>
                    <li
                        v-for="(typeTrash, id_price) in childTrash.children"
                        :key="typeTrash.id"
                        class="type-tree-li" :data-typecode="typeTrash.code">
                        <div class="tree-path position-relative position-relative custom-control custom-checkbox py-2 type-place-tree">
                        <input
                        type="checkbox"
                        :name="`type-code[${id_price}]`"
                        class="custom-control-input type"
                        :data-group="group.code" :data-subgroup="childTrash.code" :value="typeTrash.code"
                        :id="`type${typeTrash.id}`"
                        :checked="getOld('type-code.' + id_price) ? true : false"
                        >
                        <label class="custom-control-label text-justify pointer" :for="`type${typeTrash.id}`"><b>{{typeTrash.code}}{{ (typeTrash.is_dangerous) ? '*' : ''}}</b> - {{typeTrash.description}}</label>
                        </div>
                        <div class="row type-row-input p-sm-2 p-0 pb-4 m-0" :data-id="typeTrash.id">
                            <div class="col-12 col-md-4 col-lg-2 px-sm-2 px-0 mt-lg-0 mt-2 d-flex order-1">
                                <div class="form-group m-auto w-100 input_tree last-input-edit price">

                                <small class="font-weight-bold">Cena [zł/Mg]</small>
                                <input :data-code="typeTrash.code" step="0.01" :data-subgroup="childTrash.code" :data-group="group.code" type="number"
                                :name="`priceInfo[${id_price}][value]`" class="form-control price w-100"

                                    :value="getOld('priceInfo.' + id_price + '.value')"
                                    placeholder="Cena"
                                    :disabled="getOld('type-code.' + id_price) ? false : true"

                                >
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 px-sm-2 px-0 mt-lg-0 mt-2 d-flex align-items-center tree-last-input order-lg-2 order-3">
                                <div class="form-group m-0">
                                    <small class="font-weight-bold">Proces przetwarzania</small>
                                    <div class="mt-1 last-input-edit checkbox">
                                        <div
                                            v-for="(name, id) in $props.process"
                                            :key="id"
                                            class="custom-control custom-radio custom-control-inline last-input-edit flex-wrap">
                                        <input class="custom-control-input process" type="radio"
                                            :data-subgroup="childTrash.code"
                                            :data-code="typeTrash.code"
                                            :data-group="group.code"
                                            :id="`process-${typeTrash.id}${id}`" :name="`priceInfo[${id_price}][process]`"
                                            :value="id"
                                            :disabled="getOld('type-code.' + id_price) ? false : true"
                                            :checked="Number(getOld('priceInfo.' + id_price + '.process')) == Number(id) ? true : false"
                                        >
                                        <label class="custom-control-label" :for="`process-${typeTrash.id}${id}`">{{ name }}
                                        </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-2 px-sm-2 px-0 mt-lg-0 mt-2 d-flex order-lg-3 order-4">
                                <div class="form-group m-auto w-100 last-input-edit max">
                                <small class="font-weight-bold">Ograniczenia [Mg]</small>
                                <input :data-code="typeTrash.code" min="1" :data-subgroup="childTrash.code" :data-group="group.code" type="number" :name="`priceInfo[${id_price}][max]`" :value="getOld('priceInfo.' + id_price + '.max')" class="form-control max" placeholder="Ograniczenia"
                                :disabled="getOld('type-code.' + id_price) ? false : true"
                                >
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 px-sm-2 px-0 mt-lg-0 mt-2 d-flex order-lg-4 order-5">
                                <div class="form-group m-auto w-100 last-input-edit comment">
                                    <small class="font-weight-bold">Uwagi</small>
                                <textarea oninput="auto_grow(this)" :data-code="typeTrash.code" :data-subgroup="childTrash.code" :data-group="group.code" :name="`priceInfo[${id_price}][comment]`" class="form-control comment"
                                :disabled="getOld('type-code.' + id_price) ? false : true"
                                 placeholder="Uwagi"
                                :value="getOld('priceInfo.' + id_price + '.comment')"></textarea>
                                <input :data-code="typeTrash.code" :data-subgroup="childTrash.code" :data-group="group.code"
                                :disabled="getOld('priceInfo.' + id_price + '.comment') ? false : true"
                                 type="hidden" :name="`priceInfo[${id_price}][trash_id]`" :value="typeTrash.id">
                                </div>
                            </div>
                            <div class="col-12 px-sm-2 px-0 mt-2 order-lg-5 order-2">
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input individual-price-single" type="checkbox" :data-subgroup="childTrash.code"
                                    :data-code="typeTrash.code" :data-group="group.code" :id="`individual-price-single-${id_price}`"
                                    :name="`individual_price_single[${id_price}]`"
                                    :checked="getOld('individual_price_single.' + id_price) ? true : false"

                                    >
                                    <label class="custom-control-label" :for="`individual-price-single-${id_price}`">Cena ustalana indywidualnie</label>
                                </div>
                            </div>

                        </div>
                    </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
    </template>
</div>
</template>

<script>
export default {
    props: {
        trashes: [Array, Object],
        process: [Array, Object],
        old: [Array, Object],
        errors: [Array, Object],
        priceInfos: [Array, Object],
    },
    data() {
        return {
            opened: [],
            openedGroup: [],
            openedTrash: [],

            values: {},
        }
    },
    mounted() {
        $('input.managing-checkbox').on('change', (ev) => {

            if($(ev.target).prop("checked") == true) this.showPrice( ev.target.value, false)
            else this.showPrice( ev.target.value, true)
        })

        this.$props.trashes.forEach((el, key) => {
            if(this.$props.old[`trash-code.${key}`] || el.choosen){
                this.opened.push(el.code);
            }

            // el.children.forEach((e, k) => {
            //     if(this.$props.old[`trash-code.${key}`]){
            //     this.opened.push(el.id);
            // }
            // });
        });

        // this.$nextTick(() => {
        //     this.FillAllInput();
        // })
    },
    updated() {
        this.$nextTick(() => {
            this.FillAllInput();
        })
    },
    methods: {
        getValue(value, old){
            return value ? value : getOld(old);
        },
        getOld(value){
            if(this.$props.old[value]){
                return this.$props.old[value];
            }
            else return '';
        },
        showPrice(action, remove)
        {
            if(!remove) this.opened.push(action);
            else this.opened.splice(this.opened.indexOf(action), 1);
        },

        beforeEnter(el){
            $(el).css('display', 'none');
        },
        afterEnter(el){

        },
        slideEnter(el, done){
            $(el).slideDown(500, () => done());
        },
        slideLeave(el, done){
            $(el).slideUp(500, () => done());
        },
        getPriceInfo(value){
            return false;
        },
        getParams(val, dafault = null){
            if(val == 'transport' && !Array.isArray(this.$route.query[val])){
                let arr = [];
                if(this.$route.query[val]) {
                    arr.push( this.$route.query[val] );

                    return arr;
                }
                else return dafault;
            }
            else return this.$route.query[val] ? this.$route.query[val] : dafault;

        },

        setAll(ev, type, key){
            this.values[`${type}.${key}`] = ev.target.value;
        },

        FillAllInput()
        {

            $('.price-all-type, .individual-price, .individual-price-single').unbind();
            $('.individual-price-single').on('input', function(){

                let price_sub = $(this).data('subgroup');
                let code = $(this).data('code');

                let _this = $(this);

                $('.form-control.price').each(function(){
                    if($(this).data('subgroup') == price_sub && $(this).data('code') == code) {

                        if(_this.is(':checked')){
                            $('.custom-control-input.type[value="'+code+'"]').prop('checked', true);

                            $('.custom-control-input.type[value="'+code+'"]')
                            .parents().eq(1).find('.type-row-input').find('textarea, input')
                            .attr('disabled', false)
                            .prop('disabled', false);

                            $(this).val(null);
                        }

                        $(this)
                        .attr('disabled', _this.is(':checked') ? true : false)
                        .prop('disabled', _this.is(':checked') ? true : false);
                    }
                })
            });

                $('.price-all-type, .individual-price').on('input', function(){
                    let price_sub = $(this).data('subgroup');
                    let val = !$(this).hasClass('individual-price') ? $(this).val() : 'all';
                    let indyvidual = false;

                    if($(this).hasClass('individual-price'))
                        indyvidual = $(this).is(':checked');

                    $('.form-control.price').each(function(){
                        if($(this).data('subgroup') == price_sub) $(this).val(val == 'all' ? null : val)
                    })

                    $('.custom-control-input.type').each(function(){
                        if($(this).data('subgroup') == price_sub)
                        {
                            if(val != '' || val != null || (val == 'all' && indyvidual == true))
                            {
                                $(this).prop('checked', true);
                                $(this).parents().eq(1).find('.type-row-input').find('textarea, input').attr('disabled', false)

                                if(indyvidual == true){
                                    $('.form-control.price').each(function(){
                                        if($(this).data('subgroup') == price_sub) {
                                            $(this).attr('disabled', true);
                                        }
                                    })

                                    $(this).parents().eq(1).find('.type-row-input').find('.individual-price-single')
                                    .attr('checked', true).prop('checked', true)
                                }
                            }

                            if(val == '' || val == null || (val == 'all' && indyvidual == false))
                            {
                                let oldVal = $('.price-all-type[data-subgroup="'+ price_sub +'"]').first().val();

                                if(oldVal == '' || oldVal == null){
                                    $(this).prop('checked', false);

                                    $(this).parents().eq(1).find('.type-row-input')
                                    .find('textarea, input').not('.individual-price-single')
                                    .attr('disabled', true)
                                }
                                else {
                                    $('.form-control.price').each(function(){
                                        if($(this).data('subgroup') == price_sub) {
                                            $(this).val(oldVal);
                                        }
                                    });

                                    $(this).parents().eq(1).find('.type-row-input').find('.individual-price-single')
                                    .attr('checked', false).prop('checked', false);
                                }

                                if(indyvidual == false){
                                    $('.form-control.price').each(function(){
                                        if($(this).data('subgroup') == price_sub) $(this).attr('disabled', false)
                                    })
                                }
                            }

                            if(indyvidual == false) {
                                $(this).parents().eq(1).find('.type-row-input')
                                .find('.individual-price-single')
                                .attr('checked', false).prop('checked', false)
                            }
                        }

                        $(this).on('change', function(){
                            $(this).parents().eq(1).find('.type-row-input')
                            .find('.individual-price-single')
                            .attr('checked', false).prop('checked', false)
                        })
                    })
                })
                $('.max-all-type').unbind();
                $('.max-all-type').on('input', function(){
                    let max_sub = $(this).data('subgroup');
                    let val = $(this).val();
                    $('.form-control.max').each(function(){
                        if($(this).data('subgroup') == max_sub) $(this).val(val)
                    })
                })

                $('.comment-all-type').unbind();
                $('.comment-all-type').on('input', function(){
                    let comment_sub = $(this).data('subgroup');
                    let val = $(this).val();
                    $('.form-control.comment').each(function(){
                        if($(this).data('subgroup') == comment_sub) $(this).val(val)
                    })
                })

                $('.process-all-type').unbind();
                $('.process-all-type').on('input', function(){
                    let process_sub = $(this).data('subgroup');
                    let val = $(this).val();

                    $('.custom-control-input.process').each(function(){
                        if($(this).data('subgroup') == process_sub)
                        {
                            if($(this).val() == val) $(this).prop('checked', true)
                        }
                    })
                });

            $(".custom-control-input.type").each(function(){
                $(this).unbind('change');
                $(this).change(function(){
                    let val = $(this).val();
                    let valtype = $(this).data("subgroup");

                    if($(this).prop("checked") == false)
                    {
                        $('.type-tree-li').each(function(){
                            if($(this).data("typecode") == val)
                            {
                                $(".last-input-edit").children().each(function(){
                                    if($(this).data("code") == val) $(this).attr("disabled", true);
                                })
                            }
                        })
                    }
                    else
                    {
                        $('.type-tree-li').each(function(){
                            if($(this).data("typecode") == val)
                            {
                                $(".last-input-edit").children().each(function(){
                                    if($(this).data("code") == val) $(this).removeAttr("disabled");
                                })
                            }
                        })

                        $(".custom-control-input.subgroup").each(function(){
                            if($(this).val() == valtype)
                            {
                                $(this).prop('checked', true);
                            }
                        });
                    }
                });

            });

                let last_subgroup_open = null;
                $('.subgroup-place-tree').each(function(){
                $(this).unbind();
                $(this).click(function(e){

                    if($(this).hasClass('show'))
                    {
                        $(this).removeClass('show');
                        $(this).parent().find('.subgroup-type-ul').hide(500);
                        $(this).children().each(function(){
                            if($(this).hasClass("arrow")) $(this).removeClass("rotate");
                        })
                    }
                    else
                    {
                        if(last_subgroup_open && last_subgroup_open != 0 || last_subgroup_open != null)
                        {

                            last_subgroup_open.removeClass('show');
                            last_subgroup_open.parent().find('.subgroup-type-ul').hide(500);
                            $(this).children().each(function(){
                                if($(this).hasClass("arrow")) last_subgroup_open.removeClass("rotate");
                            })
                        }

                        $(this).addClass('show');
                        $(this).parent().find('.subgroup-type-ul').show(500);
                        $(this).children().each(function(){
                            if($(this).hasClass("arrow")) $(this).addClass("rotate");
                        })
                    }

                    last_subgroup_open = $(this);
                })
            })
        }
    },
}
</script>

<style>

</style>
