var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FlashMessage'),_vm._v(" "),_c('div',{staticClass:"row form-row"},[_c('div',{staticClass:"form-group col-md-7"},[_c('Select',{staticClass:"trash-type-section mb-3",attrs:{"label":"Wybierz kod/nazwę odpadu dla którego chcesz otrzymywać powiadomienia","name":"code","id":"code","live-search":false,"multiple":true,"data-selected-text-format":"count > 2","data-count-selected-text":"Wybrane kody/nazwy odpadów: {0}","size":10,"clearOption":false,"data":_vm.trashes},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(trash){return _c('optgroup',{key:trash.id,attrs:{"label":trash.code + (trash.is_dangerous ? '*' : '') + ' - ' + trash.description}},[_vm._l((trash.children),function(child){return _vm._l((child.children),function(type){return _c('option',{key:type.id,attrs:{"data-tokens":type.code.replaceAll(' ', ''),"data-content":("<b>" + (type.code + (type.is_dangerous ? '*' : '')) + "</b> - " + (type.description))},domProps:{"value":type.id}})})})],2)})}}]),model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_vm._v(" "),_c('Geocoder',{staticClass:"mb-3",attrs:{"label":"Twoja lokalizacja","id":"location","placeholder":"Lokalizacja","value":_vm.form.location},on:{"update":function (ev) { return _vm.updateLocation(ev, _vm.form); }}}),_vm._v(" "),_c('InputLabel',{staticClass:"mb-3",attrs:{"label":"Obszar ogłoszeń (promień [km])","id":"area","type":"number","step":"0.01"},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}}),_vm._v(" "),_c('button',{staticClass:"btn text-white waste-container btn-search d-block px-5",class:[
                    _vm.processing ? 'opacity-50' : ''
                ],on:{"click":_vm.save}},[_vm._v("Dodaj")])],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-5"},[_c('GoogleMap',{staticStyle:{"min-height":"300px"},attrs:{"list":false,"coords":{
                    lat: _vm.form.lat,
                    lng: _vm.form.lng
                },"area":_vm.form.area,"area-opacity":0.1}})],1),_vm._v(" "),_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Lista wybranych kodów:")]),_vm._v(" "),(_vm.$props.user.newsletter && _vm.$props.user.newsletter.length == 0)?_c('span',[_vm._v("Nie wybrano")]):_vm._e(),_vm._v(" "),_vm._l((_vm.$props.user.newsletter),function(newsletter){return _c('div',{key:newsletter.id,staticClass:"col-12 mb-3"},[_c('div',{staticClass:"bg-light p-3 rounded border"},[_c('h5',{staticClass:"border-bottom pb-1 text-waste font-weight-bold"},[_vm._v("Kod odpadu:")]),_vm._v(" "),(newsletter.trashes && newsletter.trashes.length)?_c('ul',{staticClass:"pl-3"},_vm._l((newsletter.trashes),function(type){return _c('li',{key:type.id,staticClass:"py-1"},[_c('b',[_vm._v(_vm._s(type.code + (type.is_dangerous ? '*' : '')))]),_vm._v(" - "+_vm._s(type.description)+"\n                        ")])}),0):_c('p',[_vm._v("Nie wybrano rodzaju odpadu")]),_vm._v(" "),_c('h5',{staticClass:"border-bottom pb-1 text-waste font-weight-bold"},[_vm._v("Zasięg:")]),_vm._v(" "),_c('div',{staticClass:"mb-2"},[_c('h5',{staticClass:"mb-1"},[_c('svg',{staticClass:"bi bi-geo-alt",attrs:{"width":"1.5em","height":"1.5em","viewBox":"0 0 16 16","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"}})]),_vm._v(" "),(newsletter.address)?_c('span',[_vm._v(_vm._s(newsletter.address))]):_vm._e(),_vm._v("\n                        + "),_c('b',[_vm._v(_vm._s(newsletter.area)+" km")])])]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[(newsletter)?_c('button',{staticClass:"btn btn-danger btn-search d-block px-5",class:[
                            _vm.processing ? 'opacity-50' : ''
                        ],on:{"click":function($event){return _vm.remove(newsletter.id)}}},[_vm._v("Usuń")]):_vm._e()])])])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }