<template>
<div>
    <label :for="id ? id : randId" class="small mb-0 font-weight-bold">{{ label }}</label><template v-if="required">*</template>
    <slot name="label-info" />
    <select
        ref="select"
        :id="id ? id : randId"
        :name="name"
        class="form-control selectpicker show-tick"
        :class="[
            error ? 'is-invalid' : ''
        ]"
        :title="label ? label : title"
        :data-size="size"
        data-style="btn-white"
        :data-live-search="liveSearch"
        :data-token="token"
        :data-selected-text-format="dataSelectedTextFormat"
        :data-count-selected-text="dataCountSelectedText"
        :multiple="multiple"
        :live-search-normalize="true"
        :virtualScroll="false"
        @change="inputEvent"
    >
        <option v-if="clearOption" value="clear">Wszystkie</option>
        <slot :data="data" :selected="value" />
    </select>
    <span
        v-if="error"
        class="invalid-feedback"
        role="alert"
    >
        <template v-if="Array.isArray(error)">
            <div
                v-for="(message, key) in error"
                :key="key"
            ><strong>{{ message }}</strong></div>
        </template>
        <strong v-else>{{ error }}</strong>
    </span>
    <slot name="desc" />
</div>
</template>

<script>
export default {
    props: {
        label: String,
        name: String,
        id: String,
        type: {
            type: String,
        },
        value: {},
        token: String,
        liveSearch: Boolean,
        size: Number,
        title: String,
        placeholder: String,
        required: Boolean,
        autocomplete: String,
        readonly: Boolean,
        multiple: Boolean,
        accept: String,
        data: {},
        selected: [String, Number],
        errors: [Array, Object, String],
        clearOption: {
            type: Boolean,
            default: true,
        },
        dataSelectedTextFormat: String,
        dataCountSelectedText: String,

    },
    data() {
        return {
            error: this.errors,
            randId: this.id ? null : 'id-' + (this.name ? this.name : '') + Math.floor(Math.random() * 10001),
            search: '',
        }
    },
    mounted(){
        $(this.$refs.select).selectpicker('refresh');

        if(this.value){
            $(this.$refs.select).val(this.value);
            $(this.$refs.select).selectpicker('refresh');
        }
        else {
            if(this.$route.query.code || this.selected) {
                let checked = this.selected ? this.selected : this.$route.query.code;
                $(this.$refs.select).find('option').each((key, el) => {
                    let val = el.value;
                    if(val != 'clear' && val){

                        if(Number(JSON.parse(val).id) == Number(checked)){
                            $(el).prop('selected', true);
                            $(this.$refs.select).selectpicker('refresh');

                            this.$emit('input', JSON.parse(val));
                        }
                    }
                })
            }
        }

        if(this.$props.liveSearch){
            const _this = this;
            $(this.$refs.select).on('shown.bs.select', function (e) {
                let ariaSearch = $(this).next().attr('aria-owns');
                let input = $(this).parent().find('input');
                let thisSearch = $(this);
                if(input.attr('aria-controls') == ariaSearch)
                {
                    input.on('input', function(e){
                        if(isNaN($(this).val()))
                        {
                            thisSearch.selectpicker({
                                liveSearchStyle: 'contains',
                            });
                        }
                        else
                        {
                            thisSearch.selectpicker({
                                liveSearchStyle: 'startsWith',
                            });
                        }

                        _this.search = e.target.value;
                    });
                }
            });

            // $(this.$refs.select).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            //     let ariaSearch = $(this).next().attr('aria-owns');
            //     e.preventDefault();

            //     let input = $(this).parent().find('input');
            //     if(input.attr('aria-controls') == ariaSearch)
            //     {
            //         const scrollTop = $(this).parent().find('div.inner').scrollTop();

            //         setTimeout(() => {
            //             input.trigger("input");
            //             $(this).parent().find('div.inner').scrollTop(scrollTop);
            //         }, 10)
            //     }
            // })

        }
    },
    methods: {
        inputEvent(ev){
            if(ev.target.value != 'clear'){
                try {
                    if(this.$props.multiple){
                        this.$emit('input', $(ev.target).val());
                    }
                    else this.$emit('input', JSON.parse(ev.target.value));
                } catch (e) {
                    this.$emit('input', ev.target.value);
                }
            }
            else {
                this.$emit('input', null);

                $(this.$refs.select).val('default');
                $(this.$refs.select).selectpicker("refresh");
            }

            if(this.error){
                let errorKey = null;
                if(this.$root.errors.values[this.id]) errorKey = this.id;
                else errorKey = this.name;

                this.error = null;
                this.$root.errors.values[errorKey] = null;

                this.$nextTick(() => {
                    $(this.$refs.select).selectpicker('refresh');

                    if($(this.$refs.select).parent().hasClass('is-invalid'))
                        $(this.$refs.select).parent().removeClass('is-invalid');
                });
            }
        },
    },
    watch: {
        '$root.errors.values': {
            deep: true,
            handler(val){
                if(val){
                    let value = null;

                    if(val[this.id]) value = val[this.id];
                    else value = val[this.name];

                    this.error = value;

                    this.$nextTick(() => {
                        $(this.$refs.select).selectpicker('refresh');
                    });
                }
            }
        },
        value(val){
            if(!val) {
                this.$nextTick(() => {
                    $(this.$refs.select).selectpicker('refresh');
                });
            }
            else {
                $(this.$refs.select).val(this.value);
                $(this.$refs.select).selectpicker('refresh');
            }
        },
        data(val){
            if(val) {
                this.$nextTick(() => {
                    $(this.$refs.select).selectpicker('refresh');
                });
            }
        }
    }
}
</script>

<style>
ul.dropdown-menu {
    width: 100% !important;
    min-width: auto !important;
}
.bootstrap-select > .dropdown-toggle {
    border: 1px solid rgb(206, 212, 218);
}
</style>
