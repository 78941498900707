<template>
<div>
    <label :for="id ? id : randId" class="small font-weight-bold mb-0">{{label}}</label><template v-if="required">*</template>
    <slot name="label-info" />
    <div
    class="position-relative"
        :class="[
            error ? 'is-invalid' : ''
        ]"
    >
        <input
            :id="id ? id : randId"
            ref="input"
            name="market-location"
            type="text"
            :placeholder="placeholder"
            class="form-control"
            :class="[
                error ? 'is-invalid' : ''
            ]"
            autocomplete="off"
            v-model="address"
            @change="geocoder"
            @keypress.enter.prevent
            @keypress="useGeocoder = true"
        >
        <svg
            @click.prevent="geoSearch"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 16 16"
            class="bi bi-geo-alt geo-icon-custom position-absolute btn p-0"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
        </svg>
    </div>
    <span
        v-if="error"
        class="invalid-feedback"
        role="alert"
    >
        <template v-if="Array.isArray(error)">
            <div
                v-for="(message, key) in error"
                :key="key"
            ><strong>{{ message }}</strong></div>
        </template>
        <strong v-else>{{ error }}</strong>
    </span>
</div>
</template>

<script>
export default {
    props: ['required', 'label', 'id', 'name', 'value', 'placeholder', 'validAttr'],
    data() {
        return {
            input: null,
            autocomplete: null,
            coder: null,
            location: null,
            useGeocoder: true,
            address: this.value ? this.value : '',
            randId: this.id ? null : 'id-' + (this.name ? this.name : '') + Math.floor(Math.random() * 10001),
            option: {
                enableHighAccuracy: true,
                timeout: 5 * 1000,
                maximumAge: 0
            },
            error: null,
            componentForm: {
                street_number: 'short_name',
                route: 'long_name',
                locality: 'long_name',
                postal_code: 'short_name',
                premise: 'short_name'
            },
            interval: null,
            preperedData: null,
        }
    },
    mounted() {
        this.interval = setInterval(() => {

            if(window.google) {
                clearInterval(this.interval);

                this.autocomplete = new google.maps.places.Autocomplete(this.$refs.input, {
                    componentRestrictions: {
                        country: 'PL'
                    },
                    fields: ['geometry', 'formatted_address', 'address_components']
                });
                this.coder = new google.maps.Geocoder();

                this.autocompleteEvent();
            }
        }, 100)
    },
    methods: {
        autocompleteEvent() {
            this.autocomplete.addListener('place_changed', () => {
                this.location = this.autocomplete.getPlace();
                if (!this.location.geometry || !this.location.geometry.location) {
                    this.useGeocoder = true
                    this.geocoder({ address: this.$refs.input.value });
                }
                else {
                    this.address = this.$refs.input.value;
                    this.$emit('update', {
                        lat: this.location.geometry.location.lat(),
                        lng: this.location.geometry.location.lng(),
                        location: this.address,
                    })

                    this.useGeocoder = false
                    this.autocompleteValue(this.location);
                }
            });
        },
        geoSearch() {
            navigator.geolocation.getCurrentPosition((position) => {
                let latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                this.geocoder({ location: latlng });
            },
            (msg) => { alert('Udostępnij lokalizację w urządzeniu.') },
            this.option);

        },
        geocoder(val){
            setTimeout(() => {
                this.$nextTick(() => {
                    if(!this.useGeocoder) return;

                    if(val.target){
                        val = { address: val.target.value };
                    }

                    this.coder.geocode(val, (results, status) => {
                        if (status === "OK")
                        {
                            this.$refs.input.value = results[0].formatted_address;
                            this.address = results[0].formatted_address;

                            this.$emit('update', {
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng(),
                                location: this.address,
                            })

                            this.autocompleteValue(results[0]);
                        }
                        else {
                            if(results == null || this.$refs.input.value == '') {
                                this.$emit('update', {
                                    lat: null, lng: null, location: null,
                                })
                            }
                        }
                    });

                    this.useGeocoder = false
                })
            }, 200)
        },
        autocompleteValue(value){
            let arr = {};
            let named = {
                street_number: 'number',
                route: 'street',
                locality: 'city',
                postal_code: 'postcode',
                premise: 'additional'
            };
            for (let i = 0; i < value.address_components.length; i++)
            {
                let addressType = value.address_components[i].types[0];

                if (this.componentForm[addressType])
                {
                    let val = value.address_components[i][this.componentForm[addressType]];

                    arr[ named[addressType] ] = val;

                    arr['lat'] = value.geometry.location.lat();
                    arr['lng'] = value.geometry.location.lng();
                }
            }

            if(!arr['number'] && !arr['street'] && arr['additional']){
                arr['number'] = arr['additional'];
                arr['street'] = arr['city'];

                arr['additional'] = null;
            }
            if(!arr['number'] && !arr['street'] && !arr['additional'] && arr['city']){
                arr['street'] = arr['city'];
            }
            this.preperedData = arr;

            this.$emit('autocomplete', arr);

            this.error = null;
        }
    },
    watch: {
        // address (val, old) {
        //     if(val != old) this.useGeocoder = true
        // },
        value(val){
            if(!val){
                this.address = null;
            }
        },
        '$root.errors.values': {
            deep: true,
            handler(val){
                if(val){
                    let value = null;

                    if(this.$props.validAttr){
                        let arr = [];
                        let a = this.$props.validAttr;
                        for (const [key, value] of Object.entries(val)) {
                            if(key.includes(a)){
                                arr.push(value[0]);
                            }
                        }

                        let unique = [];
                        arr.forEach((c) => {
                            if (!unique.includes(c)) {
                                unique.push(c);
                            }
                        });

                        if(unique.length) value = unique;
                    }
                    else {
                        if(val[this.id]) value = val[this.id];
                        else value = val[this.name];
                    }
                    this.error = value;
                }
                else this.error = null;
            }
        },
    }
}
</script>

<style scoped>
.geo-icon-custom
{
    fill: #999;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}
.geo-icon-custom:hover
{
    fill: #333;
}
</style>
