<template>
<transition
    mode="out-in"
    name="fade"
>
    <div v-if="$root.errors.message || $root.flash" class="alert custom">
        <div
            :class="[
                $root.errors.message || $root.flash.error ? 'alert-danger' : '',
                $root.flash.success ? 'alert-success' : ''
            ]"
        >
            {{ $root.flash.success ? $root.flash.success : $root.flash.error }}
            <button
                type="button"
                class="close table"
                @click="ev => {
                    $root.errors.message = null;
                    $root.flash = null;
                }"

                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</transition>
</template>

<script>
export default {

}
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: all .3s ease;
}
.fade-enter, .fade-leave-to{
    opacity: 0;
    transform: scale(0.9);
}
</style>
