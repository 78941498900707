<template>
<section class="py-5">
    <div
        v-if="type == 'active'"
        class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered position-relative">
            <div class="modal-content position-relative">
            <div class="modal-header">
                <h5 class="modal-title font-weight-bold" id="staticBackdropLabel">{{ modal.title }}</h5>
                <button type="button" class="close modal-close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex"
                v-if="modal.action == 'confirm'"
            >
                <button type="button" class="btn btn-lg mx-2 btn-success w-100" @click="removeStep(modal.action)">Tak</button>
                <button type="button" class="btn btn-lg mx-2 btn-danger w-100" data-dismiss="modal">Nie</button>
            </div>
            <form
                :action="'/recipient/offer/remove/' + modal.id"
                method="post"
                class="modal-body d-flex"
                v-else-if="modal.action == 'remove'"
            >
                <input type="hidden" name="_token" :value="csrf_token">
                <button type="submit" name="found_offer" value="1" class="btn btn-lg mx-2 btn-success w-100">Tak</button>
                <button type="submit" name="found_offer" value="0" class="btn btn-lg mx-2 btn-danger w-100">Nie</button>
            </form>
            <div
                v-if="modal.loading"
                class="d-flex justify-content-center align-items-center position-absolute spinner modal"
            >
                <div class="spinner-border" style="width: 2.5rem; height: 2.5rem" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            </div>
        </div>
    </div>
    <transition
        mode="out-in"
        name="fade"
    >
        <div v-if="$root.errors.message || $root.flash" class="alert custom">
            <div
                :class="[
                    $root.errors.message || $root.flash.error ? 'alert-danger' : '',
                    $root.flash.success ? 'alert-success' : ''
                ]"
            >
                {{ $root.flash.success ? $root.flash.success : $root.flash.error }}
                <button
                    type="button"
                    class="close table"
                    @click="ev => {
                        $root.errors.message = null;
                        $root.flash = null;
                    }"

                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </transition>
    <div class="container-lg position-relative" style="min-height: 200px;">
        <div class="d-flex align-items-center justify-content-start mb-3 flex-wrap " style="gap: 10px;">
            <a :href="!$props.admin ? '/home' : '/admin/users'" class="btn waste-container text-white">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                </svg> Panel administracyjny
            </a>
            <template v-if="!$props.admin">
            <a
                href="/recipient/offer/create" class="btn waste-container text-white"
            >
                Dodaj ogłoszenie
            </a>
            </template>
            <a
                v-else
                :href="`/admin/recipient/offer/${$props.type == 'active' ? 'inactive' : 'active'}/${$props.user.id}`" class="btn waste-container text-white"
            >
                Ogłoszenia {{ $props.type == 'active' ? 'nieaktywne' : 'aktywne' }}
            </a>
            <h4
                class="font-weight-bold mb-0 text-right ml-auto"
                v-if="$props.admin"
            >
                <span>{{ $props.user.full_name }}</span><br>
                <a class="small font-weight-normal" :href="`mailto:${$props.user.email}`">{{ $props.user.email }}</a>
            </h4>
        </div>
        <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center position-absolute spinner"
        >
            <div class="sticky-top">
                <div class="spinner-border spinner-lg" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div v-if="type == 'inactive'">
            <div class="alert-custom alert-warning alert-dismissible font-weight-bold shadow-sm" role="alert">
                Ogłoszenia nieaktywne przechowywane są z ostatnich <strong><u>60 dni</u></strong>. Po tym czasie zostaną całkowicie usunięte.
            </div>
        </div>
        <div>
            <transition
                mode="out-in"
                name="fade"
            >
                <div v-if="!loading && offers.length == 0" class="bg-waste-offer rounded py-2 px-4 text-white d-flex flex-column justify-content-center align-items-center font-weight-bold position-relative shadow-sm text-center">
                    <h3 class="text-center mb-0">Nie masz żadnych {{type == 'active' ? 'aktywnych' : 'nieaktywnych'}} ogłoszeń!</h3>
                    <template v-if="!$props.admin">
                        <a
                            href="/recipient/offer/create" class="btn btn-lg btn-light mt-2"
                        >Dodaj ogłoszenie</a>
                    </template>
                </div>
            </transition>
            <div v-for="(item, key) in offers" :key="key"
                class="rounded single-offer"
                :class="[
                    key == offers.length - 1 ? '' : 'mb-3',
                    loading ? 'opacity-50' : ''
                ]"
            >
                <div class="bg-waste-offer rounded py-2 px-4 text-white d-sm-flex justify-content-between font-weight-bold position-relative shadow-sm">
                    <h4 class="font-weight-bold my-auto">{{ item.trash.code + ' - ' + item.trash.description }}</h4>
                    <h5 class="font-weight-bold my-0 ml-4 text-right">
                        Dodano:<br>
                        <small style="font-size: 15px; white-space: nowrap;">{{ getDate(item.created_at) }}</small>
                    </h5>
                </div>
                <div class="rounded px-md-4 px-2 py-4 bg-white position-relative content shadow-sm">
                    <div class="row">
                        <div
                            class="col-12 d-flex flex-wrap align-items-center justify-content-between"
                        >
                            <div class="d-flex flex-wrap">
                            <a :href="($props.admin ? '/admin' : '') + '/recipient/offer/edit/' + item.id" class="btn waste-container text-white d-block px-5 mb-1">
                            {{type == 'active' ? 'Edytuj' : 'Edytuj i dodaj ponownie'}}
                            </a>
                            <button
                                v-if="type == 'active'"
                                @click="modalAction('confirm', 'offer', item, 'Czy chcesz usunąc swoje ogłoszenie?')"
                                class="btn btn-danger text-white d-block px-5 ml-2 mb-1"
                                data-toggle="modal"
                                data-target="#staticBackdrop"
                            >Usuń</button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <ul class="list-group list-group-flush details-offer overflow-hidden" ref="details"
                            >
                                <li class="list-group-item">
                                    <b>Ilość odpadów:</b> <span>Min: {{ item.min }} [Mg], Max: {{ item.max }} [Mg]</span>
                                </li>
                                <li class="list-group-item">
                                    <b>Rodzaj płatności:</b>
                                    <span class="ml-1">{{ payments[item.recipient_offer.payments ? item.recipient_offer.payments : 3] }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>Cena:</b>
                                    <span class="ml-1">
                                        {{ item.value ? (item.value + ' [zł/Mg]') : 'Brak informacji' }}
                                    </span>
                                </li>
                                <li class="list-group-item" v-if="item.recipient_offer.additional">
                                    <b>Informacje dodatkowe:</b><br>
                                    <span v-html="item.recipient_offer.additional.replace(/(?:\r\n|\r|\n)/g, '<br>')">
                                    </span>
                                </li>
                                <li class="list-group-item process">
                                    <b>Sposób przetwarzania odpadów:</b>
                                    <InfoIcon
                                        :title="processes[item.process]"
                                        :viewBox="item.process === 1 ? '0 0 24 24' : '0 0 16 16'"
                                        size="26"
                                        class="btn p-0"
                                        :class="[
                                            item.process === 1 ? 'text-success process-r' : ''
                                        ]"
                                    >

                                        <path v-if="item.process === 1" d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"/>
                                        <path v-else-if="item.process === 2" fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
                                        <path v-if="item.process === 3" d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                                        <path v-if="item.process === 3" d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                    </InfoIcon>
                                </li>
                                <li class="list-group-item">
                                    <b>Transport:</b>
                                    <span class="ml-1">{{ transport[item.recipient_offer.transport] }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>Terminarz odbioru odpadów:</b>
                                    <span>Od {{ getDate(item.recipient_offer.from, 'DD/MM/YYYY') + " do " + getDate(item.recipient_offer.to, 'DD/MM/YYYY')}}</span>
                                </li>
                                <li class="list-group-item" v-if="item.user.is_bdo">
                                    <b>Ogłoszenie dodane jako:</b><br> {{__('messages.user.is_bdo')}}
                                    <!-- {{ recipientType[item.recipient_offer.as_who]}} -->
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-between">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div
                                        style="width: fit-content;"
                                    >
                                    <span class="font-weight-bold">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 16 16"
                                            class="bi bi-geo-alt"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                        Lokalizacja:
                                    </span> {{item.recipient_offer.location.full_address}}
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    </svg>
                                    <b>Nazwa firmy/Os.fizyczna:</b>
                                        {{
                                            item.recipient_offer.who ? item.recipient_offer.who :
                                                (item.user.company_name ? item.user.company_name : item.user.firstname + ' ' + item.user.lastname)
                                        }}
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                                    </svg>
                                    <b>E-mail:</b> {{ item.recipient_offer.email ? item.recipient_offer.email : item.user.email }}
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                    </svg>
                                    <b>Telefon:</b> {{ item.recipient_offer.phone ? item.recipient_offer.phone : '-'}}
                                </li>
                                <li class="list-group-item mt-4 d-flex align-items-center" v-if="item.recipient_offer.decisions && item.recipient_offer.decisions.length">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-lock-fill" viewBox="0 0 16 16">
                                    <path d="M7 7a1 1 0 0 1 2 0v1H7V7zM6 9.3c0-.042.02-.107.105-.175A.637.637 0 0 1 6.5 9h3a.64.64 0 0 1 .395.125c.085.068.105.133.105.175v2.4c0 .042-.02.107-.105.175A.637.637 0 0 1 9.5 12h-3a.637.637 0 0 1-.395-.125C6.02 11.807 6 11.742 6 11.7V9.3z"/>
                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM10 7v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V9.3c0-.627.46-1.058 1-1.224V7a2 2 0 1 1 4 0z"/>
                                    </svg>
                                    <b>Decyzja:</b>
                                    <a
                                        class="ml-1"
                                        :href="`/decision/download/${item.recipient_offer.decisions[0].files[0].id}/${item.recipient_offer.decisions[0].id}`"
                                    >
                                        <span>{{item.recipient_offer.decisions[0].concerns ? item.recipient_offer.decisions[0].concerns : '--'}}</span>/<span>{{item.recipient_offer.decisions[0].signature ? item.recipient_offer.decisions[0].signature : '--'}}</span>/<span>{{item.recipient_offer.decisions[0].indefinitely ? 'bezterminowo' : (item.recipient_offer.decisions[0].date ? item.recipient_offer.decisions[0].date : '--')}}</span>

                                        <svg xmlns="http://www.w3.org/2000/svg" class=" mx-auto" style="height: 20px; width: 20px; margin-top: 0px" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                    </svg>
                                    </a>
                                </li>
                            </ul>
                            <div class="font-weight-bold d-flex align-items-center justify-content-end mt-auto">
                                <span class="rounded-circle d-flex align-items-center justify-content-center mr-1 text-white bg-waste border px-2 py-1" style="min-width: 40px; min-height:40px">
                                    {{ item.history > 9999 ? '9999+' : item.history }}
                                </span> Liczba odsłon ogłoszenia
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import InfoIcon from 'UI/InfoIcon.vue';

import {
    getDate,
    getDiffDate,
    addToDate,
} from 'helper/moment';

export default {
    props: ['payments', 'processes', 'type', 'user', 'transport', 'recipientType', 'admin'],
    components: {
        InfoIcon,
    },
    data() {
        return {
            show: false,
            offers: [],
            loading: true,
            modal: {
                title: 'Czy chcesz usunąc swoje ogłoszenie?',
                action: 'remove',
                id: null,
                loading: false,
                type: 'market'
            },
            csrf_token: $('meta[name=csrf-token]').attr('content'),
        }
    },
    mounted() {
        this.fetchData();
        this.show = true;

        if(this.$route.query.action) {
            this.$nextTick(() => {
                this.$root.flash = { success: this.$route.query.action == 'added' ? 'Dodano ogłoszenie!' : 'Edycja ogłoszenia przebiegła pomyślnie!' };
                this.$router.replace({ 'query': null });
            })
        }
    },

    methods: {
        async fetchData(){
            try {
                // let url = '/api' + this.$route.path + (this.admin ? '' : ('/' + this.user.id));
                let url = '/api/recipient/offer/' + this.$props.type + '/' + this.user.id;
                await axios.get(url)
                .then((response) => {
                    setTimeout(() => this.loading = false, 500);

                    this.offers = response.data;
                })
            } catch(err) {
                console.log(err)
            }
        },
        modalAction(action, type, item, title){

            if(action == 'confirm'){
                this.modal.id = item.id;
                this.modal.type = type;
                this.modal.action = action;
                this.modal.title = title;
                this.modal.item = null;
            }
        },
        removeStep(action, ev, val) {
            if(action == 'confirm'){
                this.modal.loading = true;
                setTimeout(() => {
                    this.modal.loading = false;
                    this.modal.title = 'Czy udało Ci się znaleźć satysfakcjonującą ofertę?';
                    this.modal.action = 'remove';
                    this.modal.item = null;
                }, 1000)
            }
        },

        getDate,
        getDiffDate
    },
}
</script>

<style scoped>

.spinner {
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;
}
.spinner.modal {
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5)
}
.spinner .spinner-lg{
    width: 4rem;
    height: 4rem;
    border-width: 0.5em;
}

.list-group .list-group-item .h5{
    margin-bottom: 0 !important;
}
.list-group .list-group-item {
    padding: 0.5rem 1.25rem;
}
.btn.scale:hover {
    transform: scale(0.95);
}
.btn:hover {
    filter: contrast(0.8);
}
</style>
