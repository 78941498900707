<template>
<section class="py-5 waste_points_create" v-if="show">
    <transition
        mode="out-in"
        name="fade"
    >
        <div v-if="$root.errors.message || $root.flash" class="alert custom">
            <div
                :class="[
                    $root.errors.message || $root.flash.error ? 'alert-danger' : '',
                    $root.flash.success ? 'alert-success' : ''
                ]"
            >
                {{ $root.flash.success ? $root.flash.success : $root.flash.error }}
                <button
                    type="button"
                    class="close table"
                    @click="ev => {
                        $root.errors.message = null;
                        $root.flash = null;
                    }"

                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </transition>
    <div class="container-lg">
        <a :href="market ? (market.removed ? `${$props.admin ? '/admin' : ''}/market/inactive${$props.admin ? ('/' + $props.user.id) : ''}` : `${$props.admin ? '/admin' : ''}/market/active${$props.admin ? ('/' + $props.user.id) : ''}`) : '/home'" class="btn waste-container text-white mb-3">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg> {{ market ? (market.removed ? 'Nieaktywne ogłoszenia' : 'Aktywne ogłoszenia') : 'Panel administracyjny' }}
        </a>

        <form @submit.prevent="sendForm($event)" class="p-2 p-sm-5 m-auto shadow bg-white relative">
            <div
                v-if="loading"
                class="d-flex justify-content-center align-items-center spinner modal"
            >
                <div class="spinner-border" style="width: 3.5rem; height: 3.5rem; border-width: 0.25rem;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="accordion" id="accordionOffer">
                <div class="card border-0 mb-2">
                    <div id="headingOne">
                    <button @click="openSection($event)" class="btn btn-lg p-3 waste-container w-100 text-white d-flex justify-content-between align-items-center collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        <h3 class="mb-0">
                            Informacje o odpadach
                        </h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                    </button>
                    </div>
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionOffer">
                        <div class="card-body p-0 py-3">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group trash-type-section">
                                        <Select
                                            label="Kod/nazwa odpadu"
                                            name="code"
                                            :live-search="true"
                                            :size="10"
                                            :data="trashes"
                                            :required="true"
                                            :selected="form.code"
                                            :clearOption="false"
                                            @input="getTrashData"
                                        >
                                        <template #default="{ data }">
                                            <optgroup
                                                    v-for="(trash) in data"
                                                    :key="trash.id"
                                                    :label="trash.code + (trash.is_dangerous ? '*' : '') + ' - ' + trash.description"
                                                >
                                                <template v-for="child in trash.children">
                                                    <option
                                                        v-for="type in child.children"
                                                        :key="type.id"
                                                        :value="JSON.stringify(type)"
                                                        :data-tokens="type.code.replaceAll(' ', '')"
                                                        :data-content="'<b>' + type.code + (type.is_dangerous ? '*' : '') + '</b> - ' + type.description"
                                                    ></option>
                                                </template>
                                            </optgroup>
                                        </template>
                                        </Select>
                                        <small class="font-weight-bold" v-if="adminInfo.trash_file_url">Nie wiesz jaki kod odpadu wybrać? <a :href="adminInfo.trash_file_url" download class="font-weight-bold text-waste">Kliknij Tutaj!</a></small>
                                    </div>
                                    <div>
                                        <InputLabel
                                            id="description_trash"
                                            label="Opis odpadów"
                                            type="textarea"
                                            :placeholder="trash.details"
                                            v-model="form.details"
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <InputLabel
                                            id="size"
                                            label="Przewidywana ilość odpadów"
                                            type="number"
                                            placeholder="Przewidywana ilość odpadów"
                                            :required="true"
                                            step="0.01"
                                            v-model="form.size"
                                        />
                                    </div>
                                    <Select
                                        class="form-group col-md-6"
                                        label="Wybierz jednostkę"
                                        name="unit"
                                        :size="10"
                                        :data="units"
                                        :required="true"
                                        :clearOption="false"
                                        v-model="form.unit"
                                    >
                                        <!-- <template #label-info>
                                            <InfoIcon
                                                type="tooltip"
                                                :html="true"
                                                title="Przewidywana ilość odpadów powinna być podawana w tonach. Mg jest podstawową jednostką, w przeliczeniu na którą odbiorca ustala cenę za odbiór"
                                                data-trigger="hover"
                                            >
                                                <path fill-rule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                            </InfoIcon>
                                        </template> -->
                                        <template #default="{ data, selected }">
                                            <option
                                                v-for="unit in data"
                                                :key="unit.id"
                                                :value="unit.id"
                                                :selected="unit.id == selected"
                                            >
                                                {{ unit.symbol + ' (' + unit.name + ')'}}
                                            </option>
                                        </template>
                                    </Select>
                                    </div>
                                    <ul class="pl-0 mt-2">
                                        <li>
                                            <Checkbox
                                                class="w-fit"
                                                type="checkbox"
                                                label="Interesuje mnie odbiór części odpadów"
                                                id="some_part"
                                                :bool="true"
                                                v-model="form.some_part"
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-6 mt-4">
                                    <p class="font-weight-bold border-bottom">Preferowany sposób przetwarzania odpadów*</p>
                                    <ul class="pl-0 pl-md-4"
                                        :class="[
                                            $root.errors.values && $root.errors.values['process'] ? 'is-invalid' : ''
                                        ]"
                                    >
                                        <li
                                            v-for="(process, key) in processes"
                                            :key="key"
                                            class="mb-1"
                                        >
                                            <Checkbox
                                                class="w-fit"
                                                type="checkbox"
                                                :label="process"
                                                name="process"
                                                :id="'process_' + key"
                                                :data="key"
                                                :errorMessage="false"
                                                v-model="form.process"
                                            />
                                        </li>
                                    </ul>
                                    <strong v-if="$root.errors.values && $root.errors.values['process']" class="invalid-feedback">
                                        {{$root.errors.values['process'][0]}}
                                    </strong>
                                </div>
                            </div>
                            <transition
                                name="fade"
                                mode="out-in"
                            >
                            <div class="row mt-4">
                                <div class="col-lg-6" v-if="Object.keys(trash).length > 0">
                                    <p class="font-weight-bold border-bottom">Parametry odpadów:</p>
                                    <ul class="p-3 bg-light rounded border">
                                        <li class="mb-2" v-for="(param, key) in form.params" :key="key">
                                            <InputLabel
                                                :id="'params.'+ key + '.value'"
                                                type="textarea"
                                                v-model="param.value"
                                                :disabled="!param.required"
                                                :placeholder="param.required ? 'Opisz parametr' : ''"
                                            >
                                                <template #label>
                                                    <div class="d-flex align-items-center">
                                                        <Checkbox
                                                            class="w-fit"
                                                            type="checkbox"
                                                            :id="'params.'+ key + '.required'"
                                                            :bool="true"
                                                            :required="param.required"
                                                            v-model="param.required"
                                                        >
                                                            <span style="margin-top: 2px;">{{ param.is_custom ? 'Dodaj parametr' : param.name }}</span>
                                                        </Checkbox>
                                                        <InputLabel
                                                            v-if="param.is_custom"
                                                            class="ml-2 mb-1"
                                                            :id="'params.' + key + '.name'"
                                                            v-model="param.name"
                                                            :disabled="!param.required"
                                                            :placeholder="param.required ? ('Dodaj parametr*') : ''"
                                                        />
                                                    </div>
                                                </template>
                                            </InputLabel>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-6" v-if="Object.keys(trash).length > 0">
                                    <p class="font-weight-bold border-bottom">Rodzaj i udział zanieczyszczeń:</p>
                                    <ul class="p-3 bg-light rounded border">
                                        <li class="mb-1" v-for="(pollution, key) in form.pollution" :key="key">
                                            <InputLabel
                                                :id="'pollution.' + key + '.value'"
                                                type="textarea"
                                                v-model="pollution.value"
                                                :disabled="!pollution.required"
                                                :placeholder="pollution.required ? 'Podaj udział zanieczyszczeń, dodaj opis' : ''"
                                            >
                                                <template #label>
                                                    <div class="d-flex align-items-center">
                                                        <Checkbox
                                                            class="w-fit"
                                                            type="checkbox"
                                                            :label="pollution.name"
                                                            :id="'pollution.' + key + '.required'"
                                                            :bool="true"
                                                            v-model="pollution.required"
                                                        >
                                                            <span style="margin-top: 2px;">{{ pollution.is_custom ? 'Dodaj parametr' : pollution.name }}</span>
                                                        </Checkbox>
                                                        <InputLabel
                                                            v-if="pollution.is_custom"
                                                            :id="'pollution.' + key + '.name'"
                                                            class="ml-2 mb-1"
                                                            v-model="pollution.name"
                                                            :disabled="!pollution.required"
                                                            :placeholder="pollution.required ? 'Dodaj parametr*' : ''"
                                                        />
                                                    </div>
                                                </template>
                                            </InputLabel>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-12">
                                    <p class="font-weight-bold border-bottom">Dodaj zdjęcie:</p>
                                    <InputLabel
                                        type="file"
                                        id="file-upload"
                                        accept="image/*"
                                        :multiple="true"
                                        @input="ev => images = ev"
                                        :value="market ? market.images : null"
                                    >
                                    </InputLabel>
                                </div>
                            </div>
                            </transition>
                        </div>
                    </div>
                </div>
                <div class="card border-0 mb-2">
                    <div id="headingTwo">
                    <button @click="openSection($event)" class="btn btn-lg p-3 waste-container w-100 text-white d-flex justify-content-between align-items-center collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                        <h3 class="mb-0">
                            Lokalizacja
                        </h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                    </button>
                    </div>

                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionOffer">
                    <div class="card-body p-0 py-3">
                        <div class="row">
                            <div class="col-lg-6 d-flex flex-column justify-content-center">
                                <div class="form-group row">
                                    <div class="col-12">
                                        <Geocoder
                                            @autocomplete="changeAddress"
                                            :value="market ? market.location.full_address : ''"
                                            label="Lokalizacja odpadów"
                                            validAttr="address"
                                            :required="true"
                                        >
                                        <template #label-info>
                                            <InfoIcon
                                                type="popover"
                                                :html="true"
                                                title="Wyszukiwarka Google"
                                                data-trigger="hover"
                                                size="1em"
                                                data-content="Wykorzystaj wyszukiwarkę Google do wybrania adresu przyjęcia odpadów.<br>Jeżeli adres nie jest widoczny na liście nie przejmuj się. Podaj prawidłowy adres ( aby sprecyzować wynik do adresu proszę dopisać kod pocztowy )."
                                            >
                                                <path fill-rule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                            </InfoIcon>
                                        </template>
                                        </Geocoder>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group row">
                                    <InputLabel
                                        class="col-sm-8 col-6 pr-2 pr-sm-0"
                                        id="address.city"
                                        label="Miasto"
                                        placeholder="Miasto"
                                        :readonly="readonly.includes('city')"
                                        :errorMessage="false"
                                        :errorBorder="true"
                                        v-model="form.address.city"
                                    />
                                    <InputLabel
                                        class="col-sm-4 col-6 pl-2 pl-sm-3"
                                        id="address.postcode"
                                        label="Kod pocztowy"
                                        placeholder="Kod pocztowy"
                                        :readonly="readonly.includes('postcode')"
                                        :errorMessage="false"
                                        :errorBorder="true"
                                        v-model="form.address.postcode"
                                    />
                                </div>

                                <div class="form-group row">
                                    <InputLabel
                                        class="col-sm-5 col-12 pr-3 pr-sm-0 mb-3 mb-sm-0"
                                        id="address.street"
                                        label="Ulica"
                                        placeholder="Ulica"
                                        :readonly="readonly.includes('street')"
                                        :errorMessage="false"
                                        :errorBorder="true"
                                        v-model="form.address.street"
                                    />
                                    <InputLabel
                                        class="col-sm-3 col-6 pr-2 pr-sm-0"
                                        id="address.number"
                                        label="Numer budynku"
                                        placeholder="Numer budynku"
                                        :errorMessage="false"
                                        :errorBorder="true"
                                        :readonly="readonly.includes('number')"
                                        v-model="form.address.number"
                                    />
                                    <InputLabel
                                        class="col-sm-4 col-6 pl-2 pl-sm-3"
                                        id="address.additional"
                                        label="Numer lokalu"
                                        placeholder="Numer lokalu"
                                        :errorBorder="true"
                                        v-model="form.address.additional"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="card border-0 mb-2">
                    <div id="headingThree">
                        <button @click="openSection($event)" class="btn btn-lg p-3 waste-container w-100 text-white d-flex justify-content-between align-items-center collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <h3 class="mb-0">
                                Warunki odbioru
                            </h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                        </button>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionOffer">
                    <div class="card-body p-0 py-3">
                        <div class="row mt-4">
                            <div class="col-lg-6">
                                <p class="font-weight-bold border-bottom">
                                    <span>Transport*</span>
                                    <InfoIcon
                                        :html="true"
                                        placement="right"
                                        title="Interesują mnie oferty"
                                    />
                                </p>
                                <ul class="pl-0 pl-md-4"
                                    :class="[
                                        $root.errors.values && $root.errors.values['transport'] ? 'is-invalid' : ''
                                    ]"
                                >
                                    <li class="mb-1" v-for="(item) in transport" :key="item.slug">
                                        <Checkbox
                                            class="w-fit"
                                            type="checkbox"
                                            :label="item.value"
                                            name="transport"
                                            :data="item.slug"
                                            :errorMessage="false"
                                            v-model="form.transport"
                                        />
                                    </li>
                                </ul>
                                <strong v-if="$root.errors.values && $root.errors.values['transport']" class="invalid-feedback">
                                    {{$root.errors.values['transport'][0]}}
                                </strong>
                            </div>
                            <div class="col-12 mt-4">
                                <ul class="pl-0">
                                    <li
                                        class="mb-2 p-1 bg-light rounded border"
                                        v-for="(info, key) in additional"
                                        :key="key"
                                    >
                                        <p class="font-weight-bold">{{info.text}}<template v-if="info.slug != 'other'">*</template></p>
                                        <ul
                                            class="d-md-flex pl-0"
                                            v-if="info.values"
                                            :class="[
                                                $root.errors.values && $root.errors.values['additional.loading'] ? 'is-invalid' : ''
                                            ]"
                                        >
                                            <li class="mr-3" v-for="(value, index) in info.values" :key="index">
                                                <Checkbox
                                                    class="w-fit"
                                                    :type="info.slug == 'loading' ? 'checkbox' : 'radio'"
                                                    :name="'additional.' + info.slug"
                                                    :label="value.text"
                                                    :id="info.slug + '_' + value.slug"
                                                    :errorMessage="false"
                                                    :data="value.slug"
                                                    v-model="form.additional[info.slug]"
                                                />
                                            </li>
                                        </ul>
                                        <strong v-if="$root.errors.values && $root.errors.values['additional.loading']" class="invalid-feedback">
                                            {{$root.errors.values['additional.loading'][0]}}
                                        </strong>
                                        <InputLabel
                                            v-if="info.slug == 'other'"
                                            class="mt-3"
                                            id="additional.other.value"
                                            placeholder="Opisz trudności logistyczne jakie może napotkać kierowca przy odbiorze odpadów, np. ograniczenia tonażowe dróg dojazdowych"
                                            type="textarea"
                                            v-model="form.additional[info.slug]"
                                        />
                                        <InputLabel
                                            v-else-if="form.additional[info.slug] && form.additional[info.slug] == 'other'"
                                            class="mt-3"
                                            id="additional.other.value"
                                            type="textarea"
                                            v-model="form.additional['storage_other']"
                                        />

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <p class="font-weight-bold border-bottom">Terminarz odbioru odpadów*</p>
                                <div class="form-row">
                                    <InputLabel
                                        class="col-md-6"
                                        id="date.from"
                                        type="date"
                                        label="Od"
                                        v-model="form.date.from"
                                    />
                                    <InputLabel
                                        class="col-md-6"
                                        id="date.to"
                                        type="date"
                                        label="Do"
                                        v-model="form.date.to"
                                    >
                                        <template #label-info>
                                            <InfoIcon
                                                :html="true"
                                                title='Po tej dacie ogłoszenie zmieni status na "nieaktywne"'
                                                data-trigger="hover"
                                                size="1em"
                                            >
                                                <path fill-rule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                            </InfoIcon>
                                        </template>
                                    </InputLabel>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <p class="font-weight-bold border-bottom">Mogą składać ofertę*</p>
                                <ul class="pl-0 pl-md-4"
                                    :class="[
                                        $root.errors.values && $root.errors.values['can_order'] ? 'is-invalid' : ''
                                    ]"
                                >
                                    <li class="mb-1">
                                        <Checkbox
                                            class="w-fit"
                                            type="checkbox"
                                            label="Podmioty zarejestrowane w BDO"
                                            name="can_order"
                                            data="bdo"
                                            :errorMessage="false"
                                            v-model="form.can_order"
                                        />
                                        <transition
                                            name="fade"
                                            mode="out-in"
                                        >
                                        <ul class="mb-0 pl-4 mt-1" v-if="form.can_order.includes('bdo')">
                                            <li class="mb-1">
                                                <Checkbox
                                                    class="w-fit"
                                                    type="checkbox"
                                                    label="Wymagam dostarczenia decyzji potwierdzającej uprawnienia do zagospodarowania odpadów"
                                                    id="environmental_decision"
                                                    v-model="form.environmental_decision"
                                                />
                                            </li>
                                        </ul>
                                        </transition>
                                    </li>
                                    <li class="mb-1">
                                        <Checkbox
                                            class="w-fit"
                                            type="checkbox"
                                            label="Inne podmioty/os.fizyczne/jednostki organizacyjne niebędące przedsiębiorcami"
                                            data="other"
                                            name="can_order"
                                            :errorMessage="false"
                                            v-model="form.can_order"
                                        />
                                    </li>
                                </ul>
                                <strong v-if="$root.errors.values && $root.errors.values['can_order']" class="invalid-feedback">
                                    {{$root.errors.values['can_order'][0]}}
                                </strong>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="card border-0 mb-2">
                    <div id="headingFour">
                        <button @click="openSection($event)" class="btn btn-lg p-3 waste-container w-100 text-white d-flex justify-content-between align-items-center collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <h3 class="mb-0">
                                Pozostałe informacje
                            </h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                        </button>
                    </div>
                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionOffer">
                    <div class="card-body p-0 py-3">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <p class="font-weight-bold pb-0 mb-0">Dane kontaktowe: </p>
                            </div>
                            <div class="form-group col-md-6">
                                <InputLabel
                                    id="user.who"
                                    label="Nazwa firmy/ os. fizyczna"
                                    v-model="form.user.who"
                                    :required="true"
                                />
                            </div>

                            <div class="form-group col-md-6">
                                <InputLabel
                                    id="user.phone"
                                    label="Telefon"
                                    v-model="form.user.phone"
                                />
                            </div>

                            <div class="form-group col-md-6">
                                <InputLabel
                                    id="user.email"
                                    label="E-mail"
                                    v-model="form.user.email"
                                    :required="true"
                                />
                            </div>
                            <!-- <div class="col-md-6">
                                <p class="font-weight-bold pb-0 mb-0">Dodaj ogłoszenie jako*</p>
                                <ul class="font-weight-bold pl-0 mt-3"
                                    :class="[
                                        $root.errors.values && $root.errors.values['user.as_who'] ? 'is-invalid' : ''
                                    ]"
                                >
                                    <li>
                                        <Checkbox
                                            class="w-fit"
                                            type="radio"
                                            name="user.as_who"
                                            data="bdo"
                                            label="Podmiot zarejestrowany w BDO"
                                            :errorMessage="false"
                                            v-model="form.user.as_who"
                                        />
                                    </li>
                                    <li>
                                        <Checkbox
                                            class="w-fit"
                                            type="radio"
                                            name="user.as_who"
                                            data="other"
                                            :errorMessage="false"
                                            label="Os.fizyczna/ jednostka organizacyjna niebedąca przedsiębiorcą /podmiot niezarejestrowany w BDO"
                                            v-model="form.user.as_who"
                                        />
                                    </li>
                                </ul>
                                <strong v-if="$root.errors.values && $root.errors.values['user.as_who']" class="invalid-feedback">
                                    {{$root.errors.values['user.as_who'][0]}}
                                </strong>
                            </div> -->
                            <div class="col-12">
                                <div class="form-group mt-4">
                                    <p class="font-weight-bold pb-0 mb-0">Dodatkowe informacje</p>
                                    <InputLabel
                                        id="description"
                                        type="quill"
                                        placeholder="Dodatkowe informacje"
                                        v-model="form.description"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 d-sm-flex justify-content-between align-items-start">
                    <button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-danger text-white btn-scale d-block px-5 mr-2">Anuluj</button>
                    <button class="btn waste-container text-white btn-scale d-block px-5 mr-2 mb-2">{{market && !market.removed ? 'Zapisz zmiany' : 'Dodaj ogłoszenie'}}</button>
                </div>
            </div>
        </form>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title font-weight-bold">Czy chcesz anulować dodawanie oferty ?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Wszystkie niezapisane zmiany zostaną utracone</p>
            </div>
            <div class="modal-footer">
                <a href="/market/active" class="btn btn-success">Tak</a>
                <button type="button" class="btn btn-danger" data-dismiss="modal">Nie</button>
            </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import InputLabel from 'UI/InputLabel.vue';
import Geocoder from 'UI/GeocoderInput.vue';
import Checkbox from 'UI/Checkbox.vue';
import Select from 'UI/Select.vue';
import InfoIcon from 'UI/InfoIcon';

import {
    getDate,
    addToDate,
} from 'helper/moment';

import moment from 'moment-timezone';

export default {
    components: {
        InputLabel,
        Geocoder,
        Checkbox,
        Select,
        InfoIcon,
    },
    props: ['trashes', 'processes', 'user', 'units' , 'payments', 'market', 'transport', 'adminInfo', 'admin'],
    data() {
        return {
            title: (this.market ? 'Edytuj' : 'Dodaj') + ' ogłoszenie (oddam odpady)',
            show: false,
            images: [],
            form: {
                address: {
                    city: this.market ? this.market.location.city : null,
                    postcode: this.market ? this.market.location.postcode : null,
                    street: this.market ? this.market.location.street : null,
                    number: this.market ? this.market.location.number : null,
                    additional: this.market ? this.market.location.aditional : null,
                    lat: this.market ? this.market.location.lat : null,
                    lng: this.market ? this.market.location.lng : null,
                },
                user: {
                    phone: this.market ? this.market.phone : this.user.phone,
                    email: this.market ? this.market.email : this.user.email,
                    who: this.market ? this.market.who : (this.user.company_name ? this.user.company_name : this.user.firstname + ' ' + this.user.lastname),
                    as_who: this.market ? this.market.as_who : null
                },
                code: this.market ? this.market.trash_id : null,
                additional: {
                    storage: this.market ? this.market.additional.storage : null,
                    storage_other: this.market ? this.market.additional.storage_other : null,
                    loading: this.market ? this.market.additional.loading : [],
                    other: this.market ? this.market.additional.other : null,
                },
                params: this.market ? this.market.params : [],
                pollution: this.market ? this.market.pollution : [],
                size: this.market ? this.market.size : null,
                description: this.market ? this.market.description : null,
                process: this.market ? this.market.process : [],
                transport: this.market ? this.market.transport : [],
                can_order: this.market ? this.market.can_order :  [],
                date: {
                    from: this.market && !this.market.removed ? getDate(this.market.date_from, 'YYYY-MM-DD') : getDate(null, 'YYYY-MM-DD'),
                    to: this.market && !this.market.removed ? getDate(this.market.date_to, 'YYYY-MM-DD') : moment(new Date()).add('months', 1).format('YYYY-MM-DD'),
                },
                environmental_decision: this.market ? (this.market.environmental_decision == '0' || !this.market.environmental_decision ? false : true) : false,
                some_part: this.market ? this.market.some_part : false,
                unit: this.market && this.market.unit_id ? this.market.unit_id : this.$props.units.filter(el => el.name == 'tona')[0].id,
                details: this.market && this.market.details ? this.market.details : '',
            },

            additional: [
                {
                    text: 'Sposób magazynowania',
                    slug: 'storage',
                    values: [
                        {text: 'Kontener', slug: 'container'},
                        {text: 'Big-bag', slug: 'big-bag'},
                        {text: 'Luzem', slug: 'in-bulk'},
                        {text: 'Paleta', slug: 'palette'},
                        {text: 'Inne', slug: 'other', value: ''}
                    ]
                },
                {
                    text: 'Załadunek',
                    slug: 'loading',
                    values: [
                        {text: 'Własny', slug: 'own'},
                        {text: 'Po stronie odbiorcy', slug: 'recipient'}
                    ]
                },
                {
                    text: 'Inne warunki odbioru',
                    slug: 'other',
                }
            ],

            errors: {
                values: null,
                message: null
            },
            axios_header: {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            },
            readonly: [],
            trash: {},
            loading: false,
            MarketFillData: this.market ? true : false,
        }
    },
    mounted() {
        moment.locale('pl');
        this.show = true;
        $('.title-section').html(this.title);


        this.changeAddress(this.form.address)
    },
    methods: {
        changeAddress(value){
            this.readonly = []
            const address = JSON.parse(JSON.stringify(this.form.address));

            for (const [key, val] of Object.entries(value)) {
                this.form.address[key] = val;

                if(val) {
                    delete address[key]
                    this.readonly.push(key)
                }
            }

            for (const [key, val] of Object.entries(address)) {
                this.form.address[key] = null;
            }
        },
        getTrashData(val) {
            this.trash = val;
            if(this.MarketFillData == false){
                this.form.params = [];
                if(val.params && val.params.params){
                    val.params.params.forEach((el, key) => {
                        this.form.params.push({name: el, value: '', required: false});
                    })
                }
                this.form.params.push({name: '', value: '', required: false, is_custom: true});

                this.form.pollution = [];
                if(val.params && val.params.pollutions){
                    val.params.pollutions.forEach((el, key) => {
                        this.form.pollution.push({name: el, value: '', required: false});
                    })
                }
                this.form.pollution.push({name: '', value: '', required: false, is_custom: true});
            }

            this.form.code = val.id;
            this.MarketFillData = false;
        },
        sendForm(ev){
            this.loading = true;
            let formData = new FormData();
            formData.append("data", JSON.stringify(this.form) );

            this.images.forEach(el => {
                formData.append("images[]", el.file );
            });
            axios.post(this.market ? ('/market/update/' + this.market.id) : '/market/store', formData, this.axios_header)
            .then((response) => {

                if(response.data.status == 'error') {
                    this.showErrors(response)
                }
                else {
                    if(response.data.success){
                        const link = this.$props.admin ? `/admin/market/active/${this.user.id}` : '/market/active';
                        window.location.href = link + '?action=' + (this.market && !this.market.removed ? 'edited' : 'added');
                    }
                }

            })
            .catch( (error) => this.showErrors(error) )
            .finally(() => {

                this.loading = false;

                if($('.is-invalid').length > 0){
                    $('.is-invalid').first().parents().each((key, el) => {
                        if($(el).hasClass('collapse')){

                            if($(el).hasClass('show'))
                                $('.is-invalid')[0].scrollIntoView({ behavior: "smooth", block: "center" })
                            else{
                                $(el).collapse('show');
                                $(el).on('shown.bs.collapse', (ev) => {
                                    $('.is-invalid')[0].scrollIntoView({ behavior: "smooth", block: "center" })
                                })
                            }
                        }
                    })
                }
            } );
        },
        fetchDataForm(value, defaultValue = null) {
            if(this.isBoolean(defaultValue)){
                return this.market ? (value == true ? true : false) : defaultValue;
            }
            else return this.market ? (value ? value : defaultValue) : defaultValue;
        },
        isBoolean(val) {
            return val === false || val === true;
        },

        resetForm(){
            this.images = [],
            this.form = {
                address: {
                    city: null,
                    postcode: null,
                    street: null,
                    number: null,
                    additional: null,
                    lat: null,
                    lng: null,
                },
                user: {
                    phone: this.user.phone,
                    email: this.user.email,
                    who:this.user.company_name ? this.user.company_name : this.user.firstname + ' ' + this.user.lastname,
                    as_who: null,
                },
                code: null,
                payment: null,
                additional: { storage: null, storage_other: null, loading: null, other: null, },
                params: [],
                pollution: [],
                size: null,
                description: null,
                process: [],
                transport: [],
                can_order: [],
                date: {
                    from: getDate(null, 'YYYY-MM-DD'),
                    to: null,
                },
                environmental_decision: true,
                some_part: false,
            }
        },
        openSection(ev) {
            let id = $(ev.target).data('target');
            let button = $(ev.target);

            $(id).on('shown.bs.collapse', (ev) => {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(button).offset().top - $(button).outerHeight()
                }, 'slow');
            })
        }
    },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all .3s ease;
}
.fade-enter, .fade-leave-to{
    opacity: 0;
    transform: translateX(100px);
}
.spinner.modal{
    background: rgb(255,255,255, 0.25);
}
</style>
