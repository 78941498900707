<template>
<!-- <transition
    mode="out-in"
    name="fade"
> -->
<section class="py-5" v-if="show">
    <div
        v-if="type == 'active'"
        class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered position-relative">
            <div class="modal-content position-relative">
            <div class="modal-header">
                <h5 class="modal-title font-weight-bold" id="staticBackdropLabel">{{ modal.title }}</h5>
                <button type="button" class="close modal-close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex"
                v-if="modal.action == 'confirm' || (modal.type == 'answer' && modal.action == 'remove')"
            >
                <button type="button" class="btn btn-lg mx-2 btn-success w-100" @click="removeStep(modal.action)">Tak</button>
                <button type="button" class="btn btn-lg mx-2 btn-danger w-100" data-dismiss="modal">Nie</button>
            </div>
            <form
                :action="'/market/remove/' + modal.id"
                method="post"
                class="modal-body d-flex"
                v-else-if="modal.type == 'market' && modal.action == 'remove'"
            >
                <input type="hidden" name="_token" :value="csrf_token">
                <button type="submit" name="found_offer" value="1" class="btn btn-lg mx-2 btn-success w-100">Tak</button>
                <button type="submit" name="found_offer" value="0" class="btn btn-lg mx-2 btn-danger w-100">Nie</button>
            </form>
            <div
                v-else-if="modal.type == 'other-offers' && modal.action == 'recycling-offer'"
                class="modal-body"
            >
                <ul class="list-disc" v-if="modal.action == 'recycling-offer'">
                    <li v-if="modal.item.unit.name != 'tona'">Ilość odpadów w przeliczeniu na Mg [tona]</li>
                    <li v-if="calcRequired">Cena transportu po stronie przekazującego</li>
                </ul>
                <div
                    class="d-flex justify-content-between align-items-center flex-sm-row flex-column"
                    v-if="modal.item.unit.name != 'tona'"
                    :class="[
                        calcRequired ? 'mb-4' : ''
                    ]"
                >
                    <InputGroup
                        type="number"
                        step="0.01"
                        class="w-50"
                        :disabled="true"
                        label="Aktualna ilość odpadów"
                        :value="modal.item.size"
                    >
                        <template #append>{{ modal.item.unit.symbol }}</template>
                    </InputGroup>
                    <div class="mx-4 d-sm-block d-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-right mt-4" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                    </div>
                    <InputGroup
                        type="number"
                        step="0.01"
                        class="w-50"
                        label="Ilość odpadów"
                        v-model="modal.size"
                    >
                        <template #append>Mg</template>
                    </InputGroup>
                </div>

                <div
                    class="bg-light rounded border"
                    v-if="calcRequired"
                >
                    <div class="bg-light px-3 pt-3">
                    <div class="form-row">
                        <div class="form-group col-sm-6">
                            <Select
                                class="mb-0"
                                label="Wybierz rodzaj ceny transportu"
                                name="calc-transport"
                                id="unit-price"
                                :size="10"
                                :clearOption="false"
                                :data="$props.unitprice"
                                v-model="modal.unitprice"
                            >
                                <template #default="{ data, selected }">
                                    <option
                                        v-for="(unit, key) in data"
                                        :key="key"
                                        :value="key"
                                        :selected="selected == unit"
                                    >
                                    {{ unit }}
                                    </option>
                                    <option :value="3">Nie uwzględniaj kosztów transportu</option>
                                </template>
                            </Select>
                        </div>

                        <div class="form-group col-sm-6">
                            <svg width="100%" height="8em" viewBox="0 0 16 16" class="bi bi-calculator-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                <linearGradient id="gradient">
                                    <stop offset="5%"  stop-color="#E8BC1D" />
                                    <stop offset="95%" stop-color="#F07E12" />
                                </linearGradient>
                                </defs>
                                <path fill="#474747" fill-rule="evenodd" d="M12 1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
                                <path fill="#474747" d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"/>
                            </svg>
                        </div>
                        <div class="form-group col-12" v-if="modal.unitprice != 3">
                            <div class="form-row">
                                <InputLabel
                                    class="form-group col-md-6"
                                    label="Podaj ładowność środka transportu [tony]"
                                    name="calc-transport-type"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    :max="calcTransportPriceMax"
                                    placeholder="Ładowność"
                                    :errors="calcTransportSize > calcTransportPriceMax ? ('Maksymalna ładowność to ' + calcTransportPriceMax + 't') : null"
                                    :disabled="!modal.unitprice"
                                    :value="calcTransportSize"
                                    @input="ev => { if(modal.unitprice == 2) getCalcPrice(ev) }"
                                />
                                <InputLabel
                                    :readonly="modal.unitprice == 2"
                                    :disabled="!modal.unitprice"
                                    class="form-group col-md-6"
                                    label="Cena [zł]/[km]"
                                    :required="true"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    v-model="modal.transport_price"
                                    placeholder="Cena"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bg-white p-3  border-top">
                    <p class="mb-0">
                        *Ceny na podstawie cennika
                        <a
                            v-if="admininfo.transport_file_url"
                            :href="admininfo.transport_file_url" download
                        ><span>{{ admininfo.transport_file_name }}</span></a>
                        <span v-else>{{ admininfo.transport_file_name }}</span>
                        <br><span class="font-weight-bold small text-waste">Kalkulator nie uwzględnia kosztów powrotu!</span>
                    </p>
                </div>
                </div>
                <div class="d-flex justify-content-end">
                    <template
                        v-if="type == 'active' && (answer == false || answer == 0 || !answer)"
                    >

                    <a
                        v-if="(modal.size && (modal.transport_price || modal.unitprice == 3)) || (modal.size && !calcRequired)"
                        :href="'/search/recycling?recycling-location=' + modal.item.location.full_address +'&lat-recycling='+modal.item.location.lat+'&lng-recycling='+modal.item.location.lng+'&recycling-trash='+modal.item.trash_id+'&recycling-size='+ modal.size +'&recycling-filter=1&recycling-transport='+ ( modal.unitprice == 3 ? '1' : '0' ) + ( modal.item.process.includes('1') ? '&recycling-process[]=1' : '' ) + (modal.item.process.includes('2') ? '&recycling-process[]=2' : '') + '&calc-price='+modal.transport_price+'&calc-transport='+ modal.unitprice +'&calc-transport-type=' + calcTransportSize"
                        class="btn btn-secondary mt-2 ml-auto"
                    >Sprawdź stałą ofertę odbiorców odpadów</a>
                    <!-- + ( modal.unitprice == 3 ? '2' : (modal.item.transport.includes('with_transport') ? '0' : '1') ) -->
                    <button
                        v-else
                        type="button"
                        :style="(modal.size && (modal.transport_price || modal.unitprice == 3)) ? '' : 'opacity: 0.5'"
                        class="btn btn-secondary mt-2 ml-auto"
                    >Sprawdź stałą ofertę odbiorców odpadów</button>

                    </template>
                </div>
            </div>
            <div
                v-if="modal.loading"
                class="d-flex justify-content-center align-items-center position-absolute spinner modal"
            >
                <div class="spinner-border" style="width: 2.5rem; height: 2.5rem" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            </div>
        </div>
    </div>
    <transition
        mode="out-in"
        name="fade"
    >
        <div v-if="$root.errors.message || $root.flash" class="alert custom">
            <div
                :class="[
                    $root.errors.message || $root.flash.error ? 'alert-danger' : '',
                    $root.flash.success ? 'alert-success' : ''
                ]"
            >
                {{ $root.flash.success ? $root.flash.success : $root.flash.error }}
                <button
                    type="button"
                    class="close table"
                    @click="ev => {
                        $root.errors.message = null;
                        $root.flash = null;
                    }"

                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </transition>
    <div class="container-lg position-relative" style="min-height: 200px;">
        <div class="d-flex align-items-center justify-content-start mb-3 flex-wrap " style="gap: 10px;">
        <a :href="!$props.admin ? '/home' : '/admin/users'" class="btn waste-container text-white mb-3">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
            </svg> Panel administracyjny
        </a>
        <template v-if="!$props.admin">
            <a
                v-if="answer == false || answer == 0 || !answer"
                href="/market/create" class="btn waste-container text-white mb-3"
            >
                Dodaj ogłoszenie
            </a>
        </template>
        <a
            v-else
            :href="`/admin/market/${$props.type == 'active' ? 'inactive' : 'active'}/${$props.user.id}`" class="btn waste-container text-white mb-3"
        >
            Ogłoszenia {{ $props.type == 'active' ? 'nieaktywne' : 'aktywne' }}
        </a>
        <h4
            class="font-weight-bold mb-0 text-right ml-auto"
            v-if="$props.admin"
        >
            <span>{{ $props.user.full_name }}</span><br>
            <a class="small font-weight-normal" :href="`mailto:${$props.user.email}`">{{ $props.user.email }}</a>
        </h4>
        </div>
        <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center position-absolute spinner"
        >
            <div class="sticky-top">
                <div class="spinner-border spinner-lg" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div v-if="type == 'inactive'">
            <div class="alert-custom alert-warning alert-dismissible font-weight-bold shadow-sm" role="alert">
                Ogłoszenia nieaktywne przechowywane są z ostatnich <strong><u>60 dni</u></strong>. Po tym czasie zostaną całkowicie usunięte.
            </div>
        </div>
        <div>
            <transition
                mode="out-in"
                name="fade"
            >
                <div v-if="!loading && offers.length == 0" class="bg-waste-offer rounded py-2 px-4 text-white d-flex flex-column justify-content-center align-items-center font-weight-bold position-relative shadow-sm text-center">
                    <h3 class="text-center mb-0">Nie masz żadnych {{type == 'active' ? 'aktywnych' : 'nieaktywnych'}} {{answer ? 'ofert' : 'ogłoszeń'}}!</h3>
                    <template v-if="!$props.admin">
                    <a
                        v-if="answer == false || answer == 0 || !answer"
                        href="/market/create" class="btn btn-lg btn-light mt-2"
                    >Dodaj ogłoszenie</a>
                    </template>
                </div>
            </transition>
            <div v-for="(item, key) in offers" :key="key"
                class="rounded single-offer"
                :class="[
                    key == offers.length - 1 ? '' : 'mb-3',
                    loading ? 'opacity-50' : ''
                ]"
            >
                <div class="bg-waste-offer rounded py-2 px-4 text-white d-sm-flex justify-content-between font-weight-bold position-relative shadow-sm">
                    <h5 class="font-weight-bold my-auto">
                        <span>{{ item.trash.code + ' - ' + item.trash.description }}</span>
                    </h5>
                    <h5 class="font-weight-bold my-0 ml-4 text-right">
                        Dodano:<br>
                        <small style="font-size: 15px; white-space: nowrap;">{{ getDate(item.created_at) }}</small>
                    </h5>
                </div>
                <div class="rounded px-md-4 px-2 py-4 bg-white position-relative content shadow-sm">
                    <!-- <p>Ogłoszenie zostanie usunięte za <b>{{ getDiffDate(new Date(item.date_to).addDays(60))}}</b> dni</p> -->
                    <div class="row">
                        <div
                            class="col-12 d-flex flex-wrap align-items-center mb-3"
                            :class="[
                                !$props.answer ? 'justify-content-end': 'justify-content-start'
                            ]"
                        >
                        <span class="mr-auto font-weight-bold">Nr. ogłoszenia: <span class="text-waste">{{ item.number_id }}</span></span>
                            <a
                                :href="($props.admin ? '/admin' : '') + '/market/edit/' + item.id"
                                class="btn waste-container text-white scale d-block px-5 mb-1"
                                v-if="(answer == false || answer == 0 || !answer) && (type == 'active' && item.answers.length == 0)"
                            >
                            {{type == 'active' ? 'Edytuj' : 'Edytuj i dodaj ponownie'}}
                            </a>
                            <button
                                v-if="type == 'active' && (answer == false || answer == 0 || !answer)"
                                @click="modalAction('confirm', 'market', item, 'Czy chcesz usunąc swoje ogłoszenie?')"
                                class="btn btn-danger text-white scale d-block px-5 ml-2 mb-1"
                                data-toggle="modal"
                                data-target="#staticBackdrop"
                            >Usuń</button>
                            <a
                                class="mb-2 btn waste-container text-white"
                                v-if="$props.answer & $props.type == 'active'"
                                :href="'/message/create/' + item.user_id + '/8'"
                            >Wyślij wiadomość</a>
                        </div>
                        <div class="col-md-6">
                            <ul class="list-group list-group-flush details-offer overflow-hidden" style="height: 0" ref="details"
                                :class="[
                                    (showMore.value == item.id && showMore.type == 'search') ? 'active' : ''
                                ]"
                            >
                                <li class="list-group-item" v-if="item.details">
                                    <b>Opis odpadów:</b>
                                    <ul class="mt-1 pl-0 pl-md-4 list-disc">
                                        <li v-html="item.details.replace(/(?:\r\n|\r|\n)/g, '<br>')"></li>
                                    </ul>
                                </li>
                                <li class="list-group-item">
                                    <b>Przewidywana ilość odpadów:</b> <span>{{ item.size }} [{{ item.unit ? item.unit.symbol : 'Mg' }}]</span>
                                </li>
                                <li class="list-group-item">
                                    <b>Możliwy odbiór części odpadów:</b>
                                    <span class="ml-1">{{ item.some_part ? 'Tak' : 'Nie' }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>Terminarz odbioru odpadów:</b>
                                    <span>Od {{ getDate(item.date_from, 'DD/MM/YYYY') + " do " + getDate(item.date_to, 'DD/MM/YYYY')}}</span>
                                </li>
                                <li
                                    class="list-group-item"
                                    v-if="objectCount(item.params.filter(el => el.required == true)) > 0 ||
                                        objectCount(item.pollution.filter(el => el.required == true)) > 0"
                                >
                                    <div class="row mx-n2">
                                        <div class="col-md px-2" v-if="objectCount(item.params.filter(el => el.required == true)) > 0">
                                            <b>Parametry odpadów:</b><br>
                                            <ul class="mt-1 pl-0 pl-md-4">
                                                <template
                                                    v-for="(p, key) in item.params"
                                                >
                                                    <li v-if="p.required" :key="key" class="mb-2 list-disc">
                                                        {{ p.name }}:<br>
                                                        <div class="ml-2" v-html="p.value.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                        <div class="col-md px-2"
                                            v-if="objectCount(item.pollution.filter(el => el.required == true)) > 0"
                                        >
                                            <b>Rodzaj i udział zanieczyszczeń:</b>
                                            <ul class="mt-1 pl-0 pl-md-4">
                                                <template
                                                    v-for="(p, key) in item.pollution"
                                                >
                                                    <li
                                                        v-if="p && p.required" :key="key" :class="p ? 'mb-2' : ''" class="list-disc"
                                                    >
                                                        {{ p.name }}<br>
                                                        <div class="ml-2" v-if="p.value" v-html="p.value.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item process">
                                    <b>Sposób przetwarzania odpadów:</b>
                                    <span
                                        v-for="(process, key) in item.process" :key="process.id"
                                        :class="[
                                            item.process.length - 1 != key ? 'mr-2' : ''
                                        ]"
                                    >
                                        <InfoIcon
                                            v-if="Number(process) === 1"
                                            :title="processes[process]"
                                            viewBox="0 0 24 24"
                                            size="26"
                                            class="btn text-success process-r p-0"
                                        >
                                            <path d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"/>
                                        </InfoIcon>
                                        <InfoIcon
                                            v-else-if="Number(process) === 2"
                                            :title="processes[process]"
                                            viewBox="0 0 16 16"
                                            size="26"
                                            class="btn p-0"
                                        >
                                            <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
                                        </InfoIcon>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <div class="row mx-n2">
                                        <div class="col-md px-2">
                                            <b>Transport:</b>
                                            <ul class="mt-1 pl-0 pl-md-4 list-disc">
                                                <li v-for="(tr) in item.transport" :key="tr">{{ __('messages.' + tr) }}</li>
                                            </ul>
                                        </div>
                                        <div class="col-md px-2">
                                            <b>Załadunek:</b>
                                            <ul class="mt-1 pl-0 pl-md-4 list-disc">
                                                <li v-for="(lo) in item.additional.loading" :key="lo">{{ __('messages.' + lo) }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <b>Sposób magazynowania:</b>
                                    <ul class="mt-1 pl-0 pl-md-4 list-disc">
                                        <li>
                                            <span
                                                :class="[
                                                    item.additional.storage == 'other' ? 'font-weight-bold' : ''
                                                ]"
                                                v-if="item.additional.storage != 'other'"
                                            >
                                                {{ __('messages.storage.' + item.additional.storage) + (item.additional.storage == 'other' ? ':' : '')}}
                                            </span>
                                            <div v-if="item.additional.storage == 'other'"
                                                v-html="item.additional.storage_other.replace(/(?:\r\n|\r|\n)/g, '<br>')"
                                            ></div>
                                        </li>
                                    </ul>
                                </li>
                                <li class="list-group-item" v-if="item.additional.other && item.additional.other">
                                    <b>Inne:</b>
                                    <ul class="mt-1 pl-0 pl-md-4 list-disc" >
                                        <li>
                                            <div v-html="item.additional.other.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                                        </li>
                                    </ul>
                                </li>
                                <li class="list-group-item">
                                    <b>Mogą składać ofertę:</b>
                                    <ul class="mt-1 pl-0 pl-md-4 list-disc">
                                        <li v-for="(can) in item.can_order" :key="can">{{ __('messages.can_order.' + can) }}</li>
                                    </ul>
                                </li>
                                <li class="list-group-item">
                                    <b>Wymagam dostarczenia decyzji potwierdzającej uprawnienia do zagospodarowania odpadów:</b>
                                    <span class="ml-1">{{ item.environmental_decision == true ? 'Tak' : 'Nie' }}</span>
                                </li>
                            </ul>
                            <button @click="showMoreDetails(item, 'search', key)" class="btn mt-2 mb-md-0 mb-2 waste-container text-white w-100" ref="btn">
                                <span>Szczegóły ogłoszenia</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    :style="showMore.value == item.id && showMore.type == 'search' ? '' : 'transform:rotate(180deg)'"
                                    style="transition: all .5s ease;"
                                    width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                </svg>
                            </button>
                        </div>
                        <div class="col-md-6">
                            <div class="image-sticky">
                                <agile
                                    ref="carousel"
                                    v-if="item.images.length > 1"
                                    :key="item.id"
                                >
                                    <div
                                        class="slide"
                                        v-for="(image) in item.images" :key="image.id"
                                    >
                                        <img :src="image.url" style="max-height: 280px;" class="mw-100 m-auto d-block border p-2" />
                                    </div>

                                    <template slot="prevButton">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                                        <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                                        </svg>
                                    </template>
                                    <template slot="nextButton">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                                        </svg>
                                    </template>
                                </agile>
                                <div v-else class="slide">
                                    <img
                                        :src="item.images.length == 1 ? item.images[0].url : '/img/site/rynek-no-image.png'"
                                        style="max-height: 280px;"
                                        class="mw-100 m-auto d-block border p-2"
                                    />
                                </div>

                                <ul class="list-group list-group-flush text-center mt-4">
                                    <li class="list-group-item py-2 border-0">
                                        <div
                                            style="width: fit-content;"
                                            class="mx-auto"
                                        >
                                        <span class="font-weight-bold">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 16 16"
                                                class="bi bi-geo-alt"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                            </svg>
                                            Lokalizacja:
                                        </span> {{item.location.full_address}}
                                        </div>
                                    </li>
                                    <li class="list-group-item py-2 border-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                        <b>Nazwa firmy/Os.fizyczna:</b>
                                            {{
                                                item.who ? item.who :
                                                    (item.user.company_name ? item.user.company_name : item.user.firstname + ' ' + item.user.lastname)
                                            }}
                                    </li>
                                    <li class="list-group-item py-2 border-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                                        </svg>
                                        <b>E-mail:</b> {{ item.email ? item.email : item.user.email }}
                                    </li>
                                    <li class="list-group-item py-2 border-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                        </svg>
                                        <b>Telefon:</b> {{ item.phone ? item.phone : '-'}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <transition
                            @before-enter="beforeEnter"
                            @enter="slideEnter"
                            @after-enter="afterEnter"
                            @leave="slideLeave"
                        >
                            <div class="col-12 more" v-if="showMore.value == item.id && showMore.type == 'search'">
                                <div class="mt-3 list-group-item border-0" v-if="item.description">
                                    <div class="border-bottom pb-1 mb-1"><b>Dodatkowe informacje:</b></div>
                                    <div class="ql-container ql-snow border-0">
                                        <div class="ql-editor px-0" v-html="item.description.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <div class="col-12 mt-3 border-0 d-flex flex-column">
                        <button @click="showAnswears(item)" class="btn btn-lg waste-container text-white w-100">
                            <span>
                                {{ (answer == false || answer == 0 || !answer) ? 'Oferty' : 'Moje oferty'}} ( <b>{{ item.answers_count }}</b> )
                            </span>
                            <template v-if="!$props.answer">
                            {{ getNewOffers(item) }}
                            </template>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                :style="showOffers == item.id ? '' : 'transform:rotate(180deg)'"
                                style="transition: all .5s ease;"
                                width="20" height="20" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                        </button>
                        <template
                            v-if="item.unit.name == 'tona' && (
                                item.transport.includes('with_transport')
                            )"
                        >
                            <a
                                v-if="type == 'active' && (answer == false || answer == 0 || !answer)"
                                :href="'/search/recycling?recycling-location=' + item.location.full_address +'&lat-recycling='+item.location.lat+'&lng-recycling='+item.location.lng+'&recycling-trash='+item.trash_id+'&recycling-size='+item.size+'&recycling-filter=1&recycling-transport=1' + (item.process.includes('1') ? '&recycling-process[]=1' : '') + (item.process.includes('2') ? '&recycling-process[]=2' : '')"
                                class="btn btn-secondary mt-2 ml-auto"
                            >Sprawdź stałą ofertę odbiorców odpadów</a>
                            <!-- (item.transport.includes('with_transport') ? '0' : '1') -->
                        </template>
                        <button
                            v-else-if="type == 'active' && (answer == false || answer == 0 || !answer)"
                            @click="modalAction('recycling-offer', 'other-offers', item, 'W twoim ogłoszeniu brakuje drobnych szczegółów')"
                            class="btn btn-secondary mt-2 ml-auto"
                            data-toggle="modal"
                            data-target="#staticBackdrop"
                        >
                            Sprawdź stałą ofertę odbiorców odpadów
                        </button>
                        <transition
                            @before-enter="beforeEnter"
                            @enter="slideEnter"
                            @after-enter="afterEnter"
                            @leave="slideLeave"
                        >
                            <div class="result-wrapper" v-if="showOffers == item.id && item.answers_count">
                                <ul class="pl-0 mb-0 font-weight-bold small d-md-flex"
                                    :class="[
                                        $props.answer ? 'mt-2' : ''
                                    ]"
                                >
                                    <li class="d-flex align-items-center mb-md-0 mb-2 mr-md-2 mr-0 text-success">
                                        <span class="bg-success rounded-circle border mr-1" style="width: 15px; height: 15px"></span> - {{ !$props.answer ? 'Zysk ze sprzedaży odpadów' : 'Twój zysk za przyjęcie odpadów'}}
                                    </li>
                                    <li class="d-flex align-items-center text-waste">
                                        <span class="bg-waste-progress rounded-circle border mr-1" style="width: 15px; height: 15px"></span> - {{ !$props.answer ? 'Opłata za przyjęcie odpadów' : 'Twój koszt zakupu odpadów'}}
                                    </li>
                                </ul>
                                <div class="my-2 shadow">
                                    <!-- sticky-top top-nav -->
                                    <div class="row row-head py-2 px-0 mx-0 text-center bg-light font-weight-bold">
                                        <div class="col-lg-9 col-md-8 p-0">
                                            <div class="row mx-0" style="font-size: 9pt;">
                                                <div class="col-sm-1 order-0 col-6 border-right px-1 d-flex align-items-center justify-content-center">Lp.</div>
                                                <div class="col-lg-2 order-1 col-sm-3 col-6 border-right px-1 d-flex align-items-center justify-content-center">
                                                    <a href="#" class="stretched-link" @click.prevent="sortTable(item, 'date')"
                                                        :class="[
                                                            $route.query.sort == 'date' ? 'active' : ''
                                                        ]"
                                                    >
                                                        Data dodania
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-down-fill cursor-pointer" viewBox="0 0 16 16"
                                                            :style="$route.query.order_by == 'desc' && $route.query.sort == 'date' ? ' transform: rotate(-180deg)' : 'transform: rotate(0deg)'"
                                                            style="transition: .5s ease-in-out;"
                                                        >
                                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div class="col-lg-1 order-2 col-sm-3 col-6 border-right px-1 d-flex align-items-center justify-content-center">Proces przetwarzania</div>
                                                <div class="col-lg-2 order-3 col-sm-3 col-6 border-right px-1 d-flex align-items-center justify-content-center">
                                                    <a href="#" class="stretched-link" @click.prevent="sortTable(item, 'size')"
                                                        :class="[
                                                            $route.query.sort == 'size' ? 'active' : ''
                                                        ]"
                                                    >
                                                        Ilość odpadów
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-down-fill cursor-pointer" viewBox="0 0 16 16"
                                                            :style="$route.query.order_by == 'desc' && $route.query.sort == 'size' ? ' transform: rotate(-180deg)' : 'transform: rotate(0deg)'"
                                                            style="transition: .5s ease-in-out;"
                                                        >
                                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div class="col-lg-4 order-lg-4 order-sm-5 order-4 border-md-top col-12 border-right px-1">
                                                    <div class="mb-1 d-flex align-items-center justify-content-center">
                                                        Cena
                                                        <InfoIcon
                                                            :html="true"
                                                            class="ml-1"
                                                            title="<ul class='p-2 text-left'>
                                                                <li class='mb-2'>(+) płatność za przekazanie odpadów ponosi przejmujący odpady (odbiorca)</li>
                                                                <li>(-) płatność za przekazanie odpadów ponosi przekazujący odpady</li>
                                                            </ul>"
                                                        />
                                                    </div>
                                                    <div class="row mx-0">
                                                        <div class="col border-right px-2 d-flex align-items-center justify-content-center">
                                                            <a href="#" class="stretched-link" @click.prevent="sortTable(item, 'with-transport')"
                                                                :class="[
                                                                    $route.query.sort == 'with-transport' ? 'active' : ''
                                                                ]"
                                                            >
                                                                Z transportem
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-down-fill cursor-pointer" viewBox="0 0 16 16"
                                                                    :style="$route.query.order_by == 'desc' && $route.query.sort == 'with-transport' ? ' transform: rotate(-180deg)' : 'transform: rotate(0deg)'"
                                                                    style="transition: .5s ease-in-out;"
                                                                >
                                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <div class="col px-2 d-flex align-items-center justify-content-center">
                                                            <a href="#" class="stretched-link" @click.prevent="sortTable(item, 'without-transport')"
                                                                :class="[
                                                                    $route.query.sort == 'without-transport' ? 'active' : ''
                                                                ]"
                                                            >
                                                                Bez transportu
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-down-fill cursor-pointer" viewBox="0 0 16 16"
                                                                    :style="$route.query.order_by == 'desc' && $route.query.sort == 'without-transport' ? ' transform: rotate(-180deg)' : 'transform: rotate(0deg)'"
                                                                    style="transition: .5s ease-in-out;"
                                                                >
                                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2 order-lg-5 order-sm-4 order-5 col-12 px-1 d-flex align-items-center justify-content-center">
                                                    <a href="#" class="stretched-link" @click.prevent="sortTable(item, 'distance')"
                                                        :class="[
                                                            $route.query.sort == 'distance' ? 'active' : ''
                                                        ]"
                                                    >
                                                        Odległość transportowa
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-down-fill cursor-pointer" viewBox="0 0 16 16"
                                                            :style="$route.query.order_by == 'desc' && $route.query.sort == 'distance' ? ' transform: rotate(-180deg)' : 'transform: rotate(0deg)'"
                                                            style="transition: .5s ease-in-out;"
                                                        >
                                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 border-left px-1 d-flex align-items-center justify-content-center" style="font-size: 9pt;">{{ $props.answer ? 'Twoje  dane kontaktowe' : 'Kontakt'}}</div>
                                    </div>
                                    <transition-group
                                        name="list"
                                    >
                                    <div
                                        v-for="(answer, i) in item.answers"
                                        :key="answer.id"
                                        class="row mx-0 text-center border position-relative"
                                        :class="[
                                            (answer.new == 1 || answer.new == true) && !$props.answer ? 'new-answer' : '',
                                            showAnswerByHash == answer.id ? 'border-success border-3 active-answer' : ''
                                        ]"
                                    >
                                        <!-- <div class="col-12 text-left">
                                            <span>Nr. oferty: {{ answer.number_id }}</span>
                                        </div> -->
                                        <div v-if="(answer.new == 1 || answer.new == true) && !$props.answer" class="new-label">NOWA OFERTA</div>
                                        <div class="col-lg-9 col-md-8 p-0 d-flex flex-column justify-content-center">
                                            <div class="row mx-0">
                                                <div class="col-sm-1 order-0 col-6 py-2 px-1 d-flex align-items-center justify-content-center">
                                                    <span>{{ Number(i) + 1 }}</span>
                                                </div>
                                                <div class="col-lg-2 order-1 col-sm-3 col-6 py-2 px-1 d-flex align-items-center justify-content-center" style="white-space:nowrap;"
                                                    :class="[
                                                        $route.query.sort == 'date' ? 'sorted-col' : ''
                                                    ]"
                                                >
                                                    {{ getDate(answer.created_at) }}
                                                </div>
                                                <div class="col-lg-1 order-2 col-sm-3 border-md-top col-6 py-2 px-1 d-flex align-items-center justify-content-center">
                                                    <span
                                                    >
                                                        <InfoIcon
                                                            v-if="Number(answer.process) == 1"
                                                            :title="processes[answer.process]"
                                                            viewBox="0 0 24 24"
                                                            size="2rem"
                                                            class="btn text-success process-r p-0"
                                                        >
                                                            <path d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"/>
                                                        </InfoIcon>
                                                        <InfoIcon
                                                            v-else-if="Number(answer.process) == 2"
                                                            :title="processes[answer.process]"
                                                            viewBox="0 0 16 16"
                                                            size="2rem"
                                                            class="p-0"
                                                        >
                                                            <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
                                                        </InfoIcon>
                                                    </span>
                                                </div>
                                                <div class="col-lg-2 order-3 col-sm-3 border-md-top col-6 py-2 px-1 d-flex flex-column align-items-center justify-content-center"
                                                    :class="[
                                                        $route.query.sort == 'size' ? 'sorted-col' : ''
                                                    ]"
                                                >
                                                    <span>{{ answer.amount }}</span>
                                                    <span class="text-muted">{{ item.unit ? item.unit.symbol : 'Mg' }}</span>
                                                </div>
                                                <div class="col-lg-4 order-lg-4 order-sm-5 order-4 col-12 border-lg-top py-2 px-1 d-flex align-items-center justify-content-center">
                                                    <div class="row mx-0 w-100">
                                                        <div
                                                            v-for="type in ['with', 'without']" :key="type"
                                                            class="col-6 px-2"
                                                            :class="[
                                                                $route.query.sort == (type + '-transport') ? 'sorted-col' : ''
                                                            ]"
                                                        >
                                                            <div>
                                                                <template
                                                                    v-for="client in transport"
                                                                >
                                                                    <span
                                                                        :key="client"
                                                                        v-if="answer.transport_details && answer.transport_details[type + '.' + client]"
                                                                        :class="[
                                                                            (client == 'owner' && !$props.answer) ||
                                                                                (client != 'owner' && $props.answer) ? 'text-waste' : 'text-success'
                                                                        ]"
                                                                    >
                                                                    <!-- <template v-if="(client != 'owner' && !$props.answer) || (client == 'owner' && $props.answer)">
                                                                        +
                                                                    </template> -->
                                                                    {{ Math.abs(answer.transport_details[type + '.' + client]) }} zł
                                                                    <!-- <template v-if="(client != 'owner' && !$props.answer) || (client == 'owner' && $props.answer)">
                                                                        <br>Twój zysk!
                                                                    </template> -->
                                                                    </span>
                                                                </template>
                                                                <span
                                                                    v-if="checkEmpty(answer.transport_details, type)"
                                                                >-</span>
                                                            </div>
                                                            <div class="text-muted mt-1">
                                                                <template
                                                                    v-for="client in transport"
                                                                >
                                                                    <span
                                                                        :key="client"
                                                                        v-if="answer.transport_details && answer.transport_details[type + '.' + client]"
                                                                    >
                                                                    {{ getPriceForUnit(answer.transport_details[type + '.' + client], answer) }}
                                                                    </span>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2 col-12 order-lg-5 order-sm-4 order-5 py-2 px-1 border-md-top"
                                                    :class="[
                                                        $route.query.sort == 'distance' ? 'sorted-col' : ''
                                                    ]"
                                                >
                                                    <GoogleDistance
                                                        v-if="answer.location && answer.lat && answer.lng"
                                                        :origin="{
                                                            lat: item.location.lat,
                                                            lng: item.location.lng
                                                        }"
                                                        :destination="{
                                                            lat: answer.lat,
                                                            lng: answer.lng
                                                        }"
                                                        @get-distance="ev => answer.distance = ev"
                                                    />
                                                    <span v-else>-</span>
                                                </div>
                                            </div>
                                            <div class="row mx-0">
                                                <div
                                                    class="col-12 py-2 px-1 text-left"
                                                    v-if="answer.additional"
                                                >
                                                <span class="font-weight-bold">Informacje dodatkowe:</span>
                                                <div class="font-weight-normal"
                                                    v-html="answer.additional ? answer.additional.replace(/(?:\r\n|\r|\n)/g, '<br>') : '-'"
                                                ></div>
                                                </div>
                                                <div class="col-12 py-2 px-1 text-left">
                                                    <span class="font-weight-bold">Nr. oferty:</span>
                                                    <div class="text-waste font-weight-bold">{{ answer.number_id }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 border-md-top py-2 px-1 d-flex align-items-center justify-content-center">
                                            <div>
                                                <ul class="list-group list-group-flush text-center font-weight-normal">
                                                    <li class="list-group-item py-2 border-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                        </svg>
                                                        <b>Nazwa firmy/Os.fizyczna:</b> {{ answer.recipient }}
                                                    </li>
                                                    <li class="list-group-item py-2 border-0">
                                                        <div
                                                            style="width: fit-content;"
                                                            class="mx-auto"
                                                        >
                                                        <span class="font-weight-bold">
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 16 16"
                                                                class="bi bi-geo-alt"
                                                                fill="currentColor"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                            </svg>
                                                            Lokalizacja:
                                                        </span> {{ answer.location ? answer.location : '-' }}
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item py-2 border-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                                                        </svg>
                                                        <b>E-mail:</b> {{ answer.email }}
                                                    </li>
                                                    <li class="list-group-item py-2 border-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                                        </svg>
                                                        <b>Telefon:</b> {{ answer.phone ? answer.phone : '-'}}
                                                    </li>
                                                </ul>
                                                <div
                                                    class="d-flex flex-column"
                                                    v-if="$props.type == 'active'"
                                                >
                                                    <a
                                                        class="mb-2 btn waste-container text-white"
                                                        v-if="!$props.answer"
                                                        :href="'/message/create/' + ($props.answer ? item.user_id : answer.user_id) + '/8'"
                                                    >Wyślij wiadomość</a>
                                                    <button
                                                        class="btn btn-danger"
                                                        v-if="user.id == answer.user_id && $props.answer"
                                                        @click="removeAnswer(answer)"

                                                        data-toggle="modal"
                                                        data-target="#staticBackdrop"
                                                    >Usuń ofertę</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </transition-group>
                                </div>
                            </div>
                            </transition>
                        </div>
                    </div>
                    <span class="mr-auto small font-weight-bold" v-if="item.updated_at != item.created_at">
                        Zaktualizowano dnia: {{ getDateWithTime(item.updated_at) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- </transition> -->
</template>

<script>
import InputGroup from 'UI/InputGroup.vue';
import InputLabel from 'UI/InputLabel';
import Geocoder from 'UI/GeocoderInput.vue';
import Checkbox from 'UI/Checkbox.vue';
import Select from 'UI/Select.vue';
import GoogleDistance from 'UI/GoogleDistance';
import InfoIcon from 'UI/InfoIcon.vue';
import {
    getDate,
    getDiffDate,
    getDateEndOf,
    getDateWithTime,
} from 'helper/moment';

export default {
    components: {
        InputGroup,
        InputLabel,
        Geocoder,
        Checkbox,
        Select,
        InfoIcon,
        GoogleDistance,
    },
    props: ['processes', 'user', 'payments', 'type', 'answer', 'unitprice', 'admininfo', 'admin'],
    data() {
        return {
            showAnswerByHash: null,
            showOfferByHash: null,
            show: false,
            showOffers: false,
            description: false,
            offers: [],
            loading: true,
            modal: {
                title: 'Czy chcesz usunąc swoje ogłoszenie?',
                action: 'confirm',
                id: null,
                size: null,
                transport_price: null,
                item: null,
                unitprice: 2,
                loading: false,
                type: 'market'
            },
            transport: ['owner', 'recipient'],
            csrf_token: $('meta[name=csrf-token]').attr('content'),
            showMore: {
                value: false,
                type: null,
                last: null,
                active: null,
            },
            array_sort_reverse: false,
            calcTransportPrice: [],
            calcTransportPriceMax: null,
            calcTransportSize: 10,
            calcRequired: false,
        }
    },
    mounted() {
        this.fetchData();
        this.show = true;

        if(this.$route.query.action) {
            this.$nextTick(() => {
                this.$root.flash = { success: this.$route.query.action == 'added' ? 'Dodano ogłoszenie!' : 'Edycja ogłoszenia przebiegła pomyślnie!' };
                this.$router.replace({'query': null});
            })
        }

        axios.get("/search/transports")
        .then((result) => {
            this.calcTransportPrice = result.data;
            this.calcTransportPriceMax = result.data.reduce((prev, current) => (prev.to > current.to) ? prev.to : current.to);
        });
    },
    methods: {
        async fetchData(){
            try {
                // let url = '/api' + this.$route.path + (this.admin ? '' : ('/' + this.user.id));
                let url = `/api/market${this.$props.answer ? '/answers' : ''}/${this.$props.type}/${this.user.id}`;
                await axios.get(url)
                .then((response) => {

                    if(typeof response.data === 'object'){
                        for (const [key, value] of Object.entries(response.data)) {
                            let obj = value.answers;
                            value.answers = [];

                            for (const [k, v] of Object.entries(obj)) {
                                value.answers.push(v);
                            }
                        }
                    }

                    this.offers = response.data;

                    this.$nextTick(() => {
                        $(this.$refs.details).each((key, el) => {
                            this.getHeightOfElement(el, key);
                        })

                        if(this.$route.hash){
                            let hashArray = this.$route.hash.replace('#', '').split('/');
                            this.showOfferByHash = Number(hashArray[0]);
                            this.showOffers = this.showOfferByHash;

                            this.showAnswerByHash = Number(hashArray[1]);

                            let offer = this.offers.filter(el => el.id == this.showOfferByHash);

                            if(offer.length) this.sortTable(offer[0], 'date');
                        }

                        setTimeout(() => {
                            this.loading = false;
                        }, 500);
                    })
                })
            } catch(err) {
                console.log(err)
            }
        },
        getHeightOfElement(el, key)
        {
            let height = 0;
            let a = this.$nextTick(() => {
                height = $(el).parent().height();
                height -= $(this.$refs.btn[key]).height();

                $(el).css('height', window.screen.width < 768 ? 280 : height);
                $(el).data('height', window.screen.width < 768 ? 280 : height);

            })
        },
        checkEmpty(el, type, client){
            let isTrue = true;
            for (const [key, value] of Object.entries(el)) {
                let arr = key.split('.');
                if(arr[0] == type){
                    isTrue = false;
                }
            }
            return isTrue;
        },
        showAnswears(el) {
            this.showOffers = el.id == this.showOffers ? false : el.id;
            this.sortTable(el, this.$route.query.sort);

            if(!this.$props.answer)
                axios.post('/market/show-answers/' + el.id);
        },
        showDescription(el) {
            this.description = el.id == this.description ? false : el.id;
        },
        objectCount(item){
            return Array.isArray(item) ? item.length : Object.size(item);
        },
        sortTable(item, value){
            let order_by = this.$route.query.order_by;

            if(!order_by) this.$router.replace({ query: { sort: value, order_by: 'desc' } });

            if(!value)
                value = 'date';

            if(!Array.isArray(item)){
                let obj = item.answers;
                item.answers = [];
                for (const [key, value] of Object.entries(obj)) {
                    item.answers.push(value);
                }
            }

            // if(this.$route.query.sort == value){
                if(order_by == 'desc') {
                    this.$router.replace({ query: { sort: value, order_by: 'asc' } });
                }
                else if(order_by == 'asc') {
                    this.$router.replace({ query: { sort: value, order_by: 'desc' } });
                }
            // }

            if(value == 'size') {
                item.answers.sort((a, b) => {
                    if(order_by == 'desc' || !order_by) return Number(b.amount) - Number(a.amount);
                    else if(order_by == 'asc') return Number(a.amount) - Number(b.amount);
                });
            }
            else if(value == 'distance'){
                item.answers.sort((a, b) => {
                    if(order_by == 'desc' || !order_by){
                        if(a.distance && b.distance)
                            return Number(a.distance.distance.value) - Number(b.distance.distance.value);
                        else if(a.distance && !b.distance){
                            return -1;
                        }
                        else if(!a.distance && !b.distance){
                            return 0;
                        }
                        else return 1;
                    }
                    else if(order_by == 'asc'){
                        if(a.distance && b.distance)
                            return Number(b.distance.distance.value) - Number(a.distance.distance.value);
                        else if(a.distance && !b.distance){
                            return -1;
                        }
                        else if(!a.distance && !b.distance){
                            return 0;
                        }
                        else return 1;
                    }
                });
            }
            else if(value == 'date') {
                item.answers.sort((a, b) => {
                    if(order_by == 'desc' || !order_by) return new Date(b.created_at) - new Date(a.created_at);
                    else if(order_by == 'asc') return new Date(a.created_at) - new Date(b.created_at);
                });
            }
            else if(value == 'with-transport' || value == 'without-transport'){
                arr = value.split('-');
                item.answers.sort((a, b) => {
                    let valA = a.transport_details[arr[0] + '.owner'] ?
                    (a.transport_details[arr[0] + '.owner']) : a.transport_details[arr[0] + '.recipient']

                    let valB = b.transport_details[arr[0] + '.owner'] ?
                    (b.transport_details[arr[0] + '.owner']) : b.transport_details[arr[0] + '.recipient']

                    if(
                        (!a.transport_details[arr[0] + '.owner'] && !this.$props.answer) ||
                        (a.transport_details[arr[0] + '.recipient'] && this.$props.answer)
                    ){
                        valA *= (-1);
                    }
                    else if(
                        (!b.transport_details[arr[0] + '.owner'] && !this.$props.answer) ||
                        (b.transport_details[arr[0] + '.recipient'] && this.$props.answer)
                    ){
                        valB *= (-1);
                    }

                    valA = Number(valA);
                    valB = Number(valB);

                    if(order_by == 'desc' || !order_by) {
                        if(valB && valA)
                            return valB - valA;
                        else if(valA && !valB)
                            return -1;
                        else if(!valA && !valB){
                            return 0;
                        }
                        else return 1;
                    }
                    else if(order_by == 'asc'){
                        if(valB && valA)
                            return valA - valB;
                        else if(valB && !valA)
                            return 1;
                        else if(!valB && !valA){
                            return 0;
                        }
                        else
                            return -1;
                    }

                });
            }
            if(this.$route.query.sort != value){
                this.$router.replace({ query: { sort: value, order_by: order_by } });
                this.array_sort_reverse = false;
            }
        },
        showMoreDetails(el, type, key) {
            this.showMore.value = el.id == this.showMore.value ? false : el.id;
            this.showMore.type = type;

            if(type == 'search'){
                this.showMore.active = this.$refs.details[key];

                let hiddenHeight = $(this.showMore.active).data('height');
                let scrollHeight = $(this.showMore.active)[0].scrollHeight;
                let isTheSame = this.showMore.active == this.showMore.last;

                $(this.showMore.active).animate({
                    height: ( isTheSame && $(this.showMore.active).hasClass('active') ) ? hiddenHeight : scrollHeight,
                }, 500)

                if(!isTheSame && this.showMore.last) {

                    let lastHeight = $(this.showMore.last).data('height');

                    $(this.showMore.last).animate({
                        height: lastHeight,
                    }, 500)
                }

                this.showMore.last = this.$refs.details[key];
            }
        },
        beforeEnter(el){
            $(el).css('display', 'none');
        },
        afterEnter(el){
            // f(this.showMore.type == 'search'){
            //     $('ul.more').first()[0].scrollIntoView({
            //         behavior: "smooth",
            //         block: "start",
            //     });
            // }
            // else {
            //     el.scrollIntoView({
            //         behavior: "smooth",
            //         block: "start",
            //     });
            // }i

        },
        slideEnter(el, done){
            $(el).slideDown(500, () => {
                done();

                if(this.showAnswerByHash){
                    // Scroll to answer
                    this.$nextTick(() => {
                        document.getElementsByClassName('active-answer')[0].scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    });
                }
            });
        },
        slideLeave(el, done){
            $(el).slideUp(500, () => done());
        },
        reloadAgile(){
            this.$nextTick(() => {

                setTimeout(() => {
                    this.$refs.carousel.forEach(element => {
                        element.reload();
                    });
                }, 1000)
            })
        },
        modalAction(action, type, item, title){

            if(action == 'confirm'){
                this.modal.id = item.id;
                this.modal.type = type;
                this.modal.action = action;
                this.modal.title = title;
                this.modal.item = null;
            }
            else if(action == 'recycling-offer'){
                this.modal.id = null;
                this.modal.type = type;
                this.modal.action = action;
                this.modal.item = item;
                this.modal.title = title;

                this.getCalcPrice(this.calcTransportSize);

                this.modal.size = item.unit.name != 'tona' ? null : item.size;

                this.calcRequired = (item.transport.includes('without_transport') && !item.transport.includes('with_transport'));
            }
        },
        getNewOffers(item){
            let news = item.answers.filter(el => el.new == true || el.new == 1).length;

            return 'w tym nowych ( ' + news + ' )';
        },
        removeStep(action, ev, val) {
            if(action == 'confirm'){
                this.modal.loading = true;
                setTimeout(() => {
                    this.modal.loading = false;
                    this.modal.title = 'Czy udało Ci się znaleźć satysfakcjonującą ofertę?';
                    this.modal.action = 'remove';
                    this.modal.item = null;
                }, 1000)
            }
            else if(action == 'remove'){
                this.modal.loading = true;

                axios.post('/market/answer/remove/' + this.modal.id)
                .then((response) => {
                    $('.modal').modal('hide');

                    this.fetchData();

                    this.$root.flash = response.data;
                    this.modal.loading = false;
                })
            }
        },
        removeAnswer(answer){
            this.modal.id = answer.id;
            this.modal.type = 'answer';
            this.modal.title = 'Czy chcesz usunąc swoją ofertę?';
            this.modal.action = 'remove'
        },
        getPriceForUnit(price, answer){
            if(answer.transport_details){
                if(price / answer.amount)
                    return '(' + ( Math.abs(price / answer.amount) ).toFixed(2) + ' zł/' + (answer.unit ? answer.unit.symbol : 'Mg') + ')'
            }
        },
        getCalcPrice(ev){
            this.calcTransportSize = Number(ev);

            if(this.calcTransportSize <= this.calcTransportPriceMax && this.calcTransportSize > 0){
                $(this.calcTransportPrice).each((key, val) => {
                    if(
                        val.from <= Number(ev) && val.to > Number(ev) ||
                        this.calcTransportPrice[this.calcTransportPrice.length - 1].to == val.to && val.to == Number(ev)
                    )
                    {
                        this.modal.transport_price = Number( (val.price / 50).toFixed(2) );
                    }
                });
            }
            else this.modal.transport_price = null;
        },
        getDate,
        getDiffDate,
        getDateEndOf,
        getDateWithTime,
    },
    watch: {
        offers(val){
            if(val.length > 0) setTimeout(() => this.loading = false, 500)
        }
    }
}
</script>

<style scoped>
@media(max-width: 576px){
    .flex-sm-row .w-50 {
        width: 100% !important;
    }
}
.row-head {
    background-color: #e4e4e4 !important;
}
.row-head * {
    border-color: #6c757d !important;
}
.fade-enter-active, .fade-leave-active {
    transition: all .3s ease;
}
.fade-enter, .fade-leave-to{
    opacity: 0;
    transform: scale(0.9);
}
/* .content{
    left: -10px;
    top: -10px;
} */
.bg-waste-offer {
    z-index: 1;
}
.list-group .list-group-item .h5{
    margin-bottom: 0 !important;
}
.list-group .list-group-item {
    padding: 0.5rem 1.25rem;
}
.opacity-50 {
    opacity: 0.5;
}
.image-sticky {
    top: 100px;
}
.spinner {
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;
}
.spinner.modal {
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5)
}
.spinner .spinner-lg{
    width: 4rem;
    height: 4rem;
    border-width: 0.5em;
}
.btn.scale:hover {
    transform: scale(0.95);
}
.btn:hover {
    filter: contrast(0.8);
}
.t-head {
    background-color: #e4e4e4;
    color: #343a40;
    font-weight: bold;
}
.td-head {
    border-right: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
}
.td-head:last-child {
    border-right: 0;
}
.list-disc {
    list-style-type: disc !important;
}
.top-nav {
    top: 60px !important;
}
.stretched-link:hover, .stretched-link.active {
    color: #F07E12;
}
.sorted-col {
    font-weight: bold;
}
.new-answer .new-label {
    background-color: #E7BB1D;
    position: absolute;
    width: 120px;
    height: 25px;
    left: -10px;
    top: 5px;

    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-top-left-radius: .25rem;

    font-size: .85rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.new-answer .new-label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 10px;
    height: 20px;
    border-bottom-left-radius: .25rem;
    background-color: #E7BB1D;
}
.new-answer .list-group-item{
    background: transparent !important;
}
@media only screen and (max-width: 991.98px) {
    .border-lg-top {
        border-top: 1px solid #dee2e6 !important;
    }
}
@media only screen and (max-width: 767.98px) {
    .border-md-top {
        border-top: 1px solid #dee2e6 !important;
    }
}
</style>
