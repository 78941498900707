<template>
    <form method="POST" action="/login">
        <input type="hidden" name="_token" :value="token">
        <div class="form-group">
            <label for="email_form" class="mb-0 font-weight-bold">Email</label>
            <input id="email_form" type="email" class="form-control" name="email" value="" required autocomplete="email">
        </div>
        <div class="form-group">
            <label for="password_form" class="mb-0 font-weight-bold">Hasło</label>
            <input id="password_form" type="password" class="form-control" name="password" required autocomplete="current-password">
        </div>
        <input class="custom-control-input" type="hidden" name="remember" id="remember-me" value="true">
        <button type="submit" class="btn btn-scale text-white waste-container mt-2">Zaloguj</button>
    </form>
</template>

<script>
export default {
    data() {
        return {
            token: $('meta[name=csrf-token]').attr('content')
        }
    },
}
</script>

<style>

</style>
