<template>
<div class="g-distance">
    <template v-if="result">
        <span>{{ result.distance.text }}</span>
        <br>
        <span class="text-muted">({{ result.duration.text }})</span>
    </template>
    <template v-else>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </template>
</div>
</template>

<script>
export default {
    props: {
        origin: Object,
        destination: Object
    },
    data() {
        return {
            originCoords: null,
            destinationCoords: null,
            service: null,
            result: null,
        }
    },
    mounted() {
        this.interval = setInterval(() => {

            if(window.google) {
                clearInterval(this.interval);

                this.calcDistance();
            }
        }, 100)
    },
    methods: {
        calcDistance() {
            this.originCoords = new google.maps.LatLng(this.$props.origin.lat, this.$props.origin.lng);
            this.destinationCoords = new google.maps.LatLng(this.$props.destination.lat, this.$props.destination.lng);

            this.service = new google.maps.DistanceMatrixService();
            this.service.getDistanceMatrix(
            {
                origins: [this.originCoords],
                destinations: [this.destinationCoords],
                travelMode: 'DRIVING',
            }, (response, status) => {
                this.result = response.rows[0].elements[0];

                this.$emit('get-distance', response.rows[0].elements[0]);
            });
        }
    },
}
</script>

<style>

</style>
