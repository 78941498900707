<template>
<section class="py-5 waste_points_create" v-if="show">
    <transition
        mode="out-in"
        name="fade"
    >
        <div v-if="$root.errors.message || $root.flash" class="alert custom">
            <div
                :class="[
                    $root.errors.message || $root.flash.error ? 'alert-danger' : '',
                    $root.flash.success ? 'alert-success' : ''
                ]"
            >
                {{ $root.flash.success ? $root.flash.success : $root.flash.error }}
                <button
                    type="button"
                    class="close table"
                    @click="ev => {
                        $root.errors.message = null;
                        $root.flash = null;
                    }"

                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </transition>
    <!-- {{ priceInfo.recipient_offer }} -->
    <div class="container-lg">
        <a :href="priceInfo ? (priceInfo.recipient_offer.removed ? `${$props.admin ? '/admin' : ''}/recipient/offer/inactive${$props.admin ? ('/' + $props.user.id) : ''}` : `${$props.admin ? '/admin' : ''}/recipient/offer/active${$props.admin ? ('/' + $props.user.id) : ''}`) : '/home'" class="btn waste-container text-white mb-3">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg> {{ priceInfo ? (priceInfo.recipient_offer.removed ? 'Nieaktywne ogłoszenia' : 'Aktywne ogłoszenia') : 'Panel administracyjny' }}
        </a>

        <form @submit.prevent="sendForm($event)" class="p-2 p-sm-5 m-auto shadow bg-white">
            <div
                v-if="loading"
                class="d-flex justify-content-center align-items-center spinner modal"
            >
                <div class="spinner-border" style="width: 3.5rem; height: 3.5rem; border-width: 0.25rem;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="accordion" id="accordionOffer">
                <div class="card border-0 mb-2">
                    <div id="headingOne">
                    <button @click="openSection($event)" class="btn btn-lg p-3 waste-container w-100 text-white d-flex justify-content-between align-items-center collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        <h3 class="mb-0">
                            Informacje o odpadach
                        </h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                    </button>
                    </div>
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionOffer">
                        <div class="card-body p-0 py-3">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group trash-type-section">
                                        <Select
                                            label="Kod/nazwa odpadu"
                                            name="code"
                                            :live-search="true"
                                            :size="10"
                                            :data="trashes"
                                            :required="true"
                                            :selected="form.code"
                                            :clearOption="false"
                                            v-model="form.code"
                                        >
                                        <template #default="{ data }">
                                            <optgroup
                                                    v-for="(trash) in data"
                                                    :key="trash.id"
                                                    :label="trash.code + (trash.is_dangerous ? '*' : '') + ' - ' + trash.description"
                                                >
                                                <template v-for="child in trash.children">
                                                    <option
                                                        v-for="type in child.children"
                                                        :key="type.id"
                                                        :value="type.id"
                                                        :data-tokens="type.code.replaceAll(' ', '')"
                                                        :data-content="'<b>' + type.code + (type.is_dangerous ? '*' : '') + '</b> - ' + type.description"
                                                    ></option>
                                                </template>
                                            </optgroup>
                                        </template>
                                        </Select>
                                        <small class="font-weight-bold" v-if="adminInfo.trash_file_url">Nie wiesz jaki kod odpadu wybrać? <a :href="adminInfo.trash_file_url" download class="font-weight-bold text-waste">Kliknij Tutaj!</a></small>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="d-flex"><p class="font-weight-bold small mb-0">Ilość odpadów</p>*</div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <InputGroup
                                                id="min"
                                                placeholder="Min"
                                                type="number"
                                                step="0.01"
                                                v-model.number="form.min"
                                            >
                                                <template #append>
                                                    [Mg] (tona)
                                                </template>
                                            </InputGroup>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <InputGroup
                                                id="max"
                                                placeholder="Max"
                                                type="number"
                                                step="0.01"
                                                v-model.number="form.max"
                                            >
                                                <template #append>
                                                    [Mg] (tona)
                                                </template>
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <p class="font-weight-bold border-bottom">
                                        <span>Rodzaj płatności</span>
                                    </p>
                                    <ul class="pl-0 pl-md-4"
                                        :class="[
                                            $root.errors.values && $root.errors.values['payments.type'] ? 'is-invalid' : ''
                                        ]"
                                    >
                                        <li class="mb-1 d-flex align-items-center" v-for="(item, key) in payments" :key="key">
                                            <Checkbox
                                                class="w-fit"
                                                type="radio"
                                                :label="item"
                                                name="payments.type"
                                                :data="key"
                                                :errorMessage="false"
                                                v-model.number="form.payments.type"
                                            />
                                            <InputLabel
                                                v-if="key != 3 && key == form.payments.type"
                                                class="ml-2"
                                                id="payments.value"
                                                type="number"
                                                step="0.01"
                                                :disabled="form.payments.type != key"
                                                placeholder="Cena"
                                                v-model.number="form.payments.value"
                                            />
                                        </li>
                                    </ul>
                                    <strong v-if="$root.errors.values && $root.errors.values['payments.type']" class="invalid-feedback">
                                        {{$root.errors.values['payments.type'][0]}}
                                    </strong>
                                </div>
                                <div class="col-lg-6">
                                    <p class="font-weight-bold border-bottom">Sposób przetwarzania odpadów*</p>
                                    <ul class="pl-0 pl-md-4"
                                        :class="[
                                            $root.errors.values && $root.errors.values['process'] ? 'is-invalid' : ''
                                        ]"
                                    >
                                        <li
                                            v-for="(process, key) in processes"
                                            :key="key"
                                            class="mb-1"
                                        >
                                            <Checkbox
                                                class="w-fit"
                                                type="radio"
                                                :label="process"
                                                name="process"
                                                :id="'process_' + key"
                                                :data="key"
                                                :errorMessage="false"
                                                v-model="form.process"
                                            />
                                        </li>
                                    </ul>
                                    <strong v-if="$root.errors.values && $root.errors.values['process']" class="invalid-feedback">
                                        {{$root.errors.values['process'][0]}}
                                    </strong>
                                </div>
                                <div class="col-lg-6">
                                    <InputLabel
                                        id="additional"
                                        type="textarea"
                                        label="Informacje dodatkowe"
                                        placeholder="Informacje dodatkowe"
                                        v-model="form.additional"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 mb-2">
                    <div id="headingTwo">
                    <button @click="openSection($event)" class="btn btn-lg p-3 waste-container w-100 text-white d-flex justify-content-between align-items-center collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                        <h3 class="mb-0">
                            Lokalizacja
                        </h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                    </button>
                    </div>

                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionOffer">
                    <div class="card-body p-0 py-3">
                        <div class="row">
                            <div class="col-lg-6 d-flex flex-column justify-content-center">
                                <div class="form-group row">
                                    <div class="col-12">
                                        <Geocoder
                                            @autocomplete="changeAddress"
                                            :value="priceInfo ? priceInfo.recipient_offer.location.full_address : ''"
                                            label="Lokalizacja odpadów"
                                            validAttr="address"
                                            :required="true"
                                        >
                                        <template #label-info>
                                            <InfoIcon
                                                type="popover"
                                                :html="true"
                                                title="Wyszukiwarka Google"
                                                data-trigger="hover"
                                                size="1em"
                                                data-content="Wykorzystaj wyszukiwarkę Google do wybrania adresu przyjęcia odpadów. Jeżeli adres nie jest widoczny na liście nie przejmuj się. Podaj prawidłowy adres ( aby sprecyzować wynik do adresu proszę dopisać kod pocztowy )."
                                            >
                                                <path fill-rule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                            </InfoIcon>
                                        </template>
                                        </Geocoder>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group row">
                                    <InputLabel
                                        class="col-sm-8 col-6 pr-2 pr-sm-0"
                                        id="address.city"
                                        label="Miasto"
                                        placeholder="Miasto"
                                        :readonly="true"
                                        :errorMessage="false"
                                        v-model="form.address.city"
                                    />
                                    <InputLabel
                                        class="col-sm-4 col-6 pl-2 pl-sm-3"
                                        id="address.postcode"
                                        label="Kod pocztowy"
                                        placeholder="Kod pocztowy"
                                        :readonly="true"
                                        :errorMessage="false"
                                        v-model="form.address.postcode"
                                    />
                                </div>

                                <div class="form-group row">
                                    <InputLabel
                                        class="col-sm-5 col-12 pr-3 pr-sm-0 mb-3 mb-sm-0"
                                        id="address.street"
                                        label="Ulica"
                                        placeholder="Ulica"
                                        :readonly="true"
                                        :errorMessage="false"
                                        v-model="form.address.street"
                                    />
                                    <InputLabel
                                        class="col-sm-3 col-6 pr-2 pr-sm-0"
                                        id="address.number"
                                        label="Numer budynku"
                                        placeholder="Numer budynku"
                                        :readonly="true"
                                        :errorMessage="false"
                                        v-model="form.address.number"
                                    />
                                    <InputLabel
                                        class="col-sm-4 col-6 pl-2 pl-sm-3"
                                        id="address.additional"
                                        label="Numer lokalu"
                                        placeholder="Numer lokalu"
                                        v-model="form.address.additional"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="card border-0 mb-2">
                    <div id="headingThree">
                        <button @click="openSection($event)" class="btn btn-lg p-3 waste-container w-100 text-white d-flex justify-content-between align-items-center collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <h3 class="mb-0">
                                Warunki odbioru
                            </h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                        </button>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionOffer">
                    <div class="card-body p-0 py-3">
                        <div class="row mt-4">
                            <div class="col-lg-6">
                                <p class="font-weight-bold border-bottom">
                                    <span>Transport*</span>
                                    <InfoIcon
                                        :html="true"
                                        placement="right"
                                        title="Interesują mnie oferty"
                                    />
                                </p>
                                <ul class="pl-0 pl-md-4"
                                    :class="[
                                        $root.errors.values && $root.errors.values['transport'] ? 'is-invalid' : ''
                                    ]"
                                >
                                    <li class="mb-1" v-for="(item, key) in transport" :key="key">
                                        <Checkbox
                                            class="w-fit"
                                            type="radio"
                                            :label="item"
                                            name="transport"
                                            :data="key"
                                            :errorMessage="false"
                                            v-model.number="form.transport"
                                        />
                                    </li>
                                </ul>
                                <strong v-if="$root.errors.values && $root.errors.values['transport']" class="invalid-feedback">
                                    {{$root.errors.values['transport'][0]}}
                                </strong>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <p class="font-weight-bold border-bottom">Terminarz odbioru odpadów*</p>
                                <div class="form-row">
                                    <InputLabel
                                        class="col-md-6"
                                        id="date.from"
                                        type="date"
                                        label="Od"
                                        v-model="form.date.from"
                                    />
                                    <InputLabel
                                        class="col-md-6"
                                        id="date.to"
                                        type="date"
                                        label="Do"
                                        v-model="form.date.to"
                                    >
                                        <template #label-info>
                                            <InfoIcon
                                                :html="true"
                                                title='Po tej dacie ogłoszenie zmieni status na "nieaktywne"'
                                                data-trigger="hover"
                                                size="1em"
                                            >
                                                <path fill-rule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                            </InfoIcon>
                                        </template>
                                    </InputLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="card border-0 mb-2">
                    <div id="headingFour">
                        <button @click="openSection($event)" class="btn btn-lg p-3 waste-container w-100 text-white d-flex justify-content-between align-items-center collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <h3 class="mb-0">
                                Pozostałe informacje
                            </h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                        </button>
                    </div>
                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionOffer">
                    <div class="card-body p-0 py-3">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <p class="font-weight-bold pb-0 mb-0">Dane kontaktowe: </p>
                            </div>
                            <div class="form-group col-md-6">
                                <InputLabel
                                    id="user.who"
                                    label="Nazwa firmy/ os. fizyczna"
                                    v-model="form.user.who"
                                    :required="true"
                                />
                            </div>
                            <div class="form-group col-md-6">
                                <InputLabel
                                    id="user.phone"
                                    label="Telefon"
                                    v-model="form.user.phone"
                                />
                            </div>
                            <div class="form-group col-md-6">
                                <InputLabel
                                    id="user.email"
                                    label="E-mail"
                                    v-model="form.user.email"
                                    :required="true"
                                />
                            </div>

                            <div class="form-group col-12">
                                <Checkbox
                                    class="w-fit mt-2"
                                    type="checkbox"
                                    label="Posiadam zezwolenie na zbieranie/przetwarzanie odpadów"
                                    name="waste_collection"
                                    :bool="true"
                                    v-model.number="form.waste_collection"
                                    @input="ev => openSection(ev, true)"
                                />
                            </div>
                        </div>
                        <!-- <pre>{{ form.decision }}</pre> -->
                        <div class="collapse border-top position-relative" id="decisions">
                                <h6 class="my-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <span>{{form.decision.concerns ? form.decision.concerns : '--'}}</span>/<span>{{form.decision.signature ? form.decision.signature : '--'}}</span>/<span>{{form.decision.indefinitely ? 'bezterminowo' : (form.decision.date ? form.decision.date : '--')}}</span>
                                    </div>
                                    <div v-if="priceInfo && priceInfo.recipient_offer.decisions[0] && priceInfo.recipient_offer.decisions[0].files[0]" class="align-items-center" id="saved-decision-options-0">
                                        <div style="height: 28px; width: 28px;" class="btn btn-light mx-1 d-flex justify-content-center align-items-center">
                                            <a
                                                :href="`/decision/download/${priceInfo.recipient_offer.decisions[0].files[0].id}/${priceInfo.recipient_offer.decisions[0].id}`"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" class=" mx-auto" style="height: 20px; width: 20px; margin-top: 0px" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </h6>
                                <div id="decision-values-0">
                                    <div class="form-file rounded mx-auto position-relative mt-3">
                                        <div class="custom-file file-title rounded-top flex justify-between py-2">

                                            <span class="mr-2 pr-1 pl-2">Dodaj decyzję w formacie PDF</span>

                                            <div class="ml-auto mr-5">
                                                <label for="pdf-0">
                                                    <div class="icon d-flex align-items-center ">
                                                        <svg class="bi bi-upload mx-auto" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8zM5 4.854a.5.5 0 0 0 .707 0L8 2.56l2.293 2.293A.5.5 0 1 0 11 4.146L8.354 1.5a.5.5 0 0 0-.708 0L5 4.146a.5.5 0 0 0 0 .708z"></path>
                                                            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 2z"></path>
                                                        </svg>
                                                    </div>
                                                </label>
                                                <button
                                                    type="button"
                                                    @click="ev => {
                                                        form.decision = { concerns: '', signature: '', indefinitely: '', date: '', file_name: '', file: '' }
                                                    }"
                                                    class="btn reset-elements btn-light mr-2"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="2em" height="2em" viewBox="0 0 122.88 120.01" style="enable-background:new 0 0 122.88 120.01" xml:space="preserve"><g><path class="st0" d="M110.97,1.27L70.02,42.73l10.67,10.36l41.25-41.56C125.58,3.7,117.92-2.85,110.97,1.27L110.97,1.27z M54.04,46.81c0.4-0.31,0.81-0.58,1.22-0.81l0.15-0.08c2.35-1.28,4.81-1.24,7.39,0.53l7.17,6.98l0.11,0.11l6.6,6.42 c2.73,2.78,3.34,5.88,1.83,9.31l-19.35,50.75C24.02,112.99-0.34,87.94,0,49.73C19.23,55.35,37.75,57.19,54.04,46.81L54.04,46.81z"/></g></svg>
                                                </button>
                                            </div>

                                        </div>
                                        <label for="pdf-0"
                                            class="rounded-bottom drop-file-wrapper border-right border-bottom border-left m-auto d-flex flex-column align-items-center justify-content-center"
                                            :class="[
                                                $root.errors.values && $root.errors.values['decision.file'] ? 'is-invalid' : ''
                                            ]"
                                        >
                                            <input type="file" id="pdf-0" name="decision.file" accept=".pdf,.xls,.xlsx,.doc,.docx" class="inputFile2" @change="uploadFile($event)">
                                            <svg class="bi bi-file-plus mb-2" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V8h-1v5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5V1z"></path>
                                                <path fill-rule="evenodd" d="M13.5 1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1H13V1.5a.5.5 0 0 1 .5-.5z"></path>
                                                <path fill-rule="evenodd" d="M13 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0v-2z"></path>
                                            </svg>
                                            <h5 class="file-name text-center font-weight-bold">

                                                <template v-if="form.decision.file_name && !form.decision.file">
                                                <span>{{form.decision.concerns ? form.decision.concerns : '--'}}</span>/<span>{{form.decision.signature ? form.decision.signature : '--'}}</span>/<span>{{form.decision.indefinitely ? 'bezterminowo' : (form.decision.date ? form.decision.date : '--')}}</span>
                                                </template>
                                                <span v-else>
                                                    {{ form.decision.file_name ? form.decision.file_name : 'Przeciągnij lub wybierz plik aby go dodać' }}
                                                </span>
                                            </h5>
                                            <small class="text-sm file-name-small" v-if="form.decision.file_name && !form.decision.file">
                                                {{ 'Przeciągnij lub wybierz plik aby go dodać' }}
                                            </small>
                                        </label>
                                        <small
                                            class="invalid-feedback"
                                            role="alert"
                                            v-if="$root.errors.values && $root.errors.values['decision.file']"
                                        >{{ $root.errors.values['decision.file'][0] }}</small>
                                    </div>

                                    <small class="font-weight-bold mt-3">Dotyczy zezwolenia na</small>
                                    <div class="row">
                                        <div class="col-12 d-flex flex-wrap">
                                            <div class="mx-3 form-check custom-control custom-radio">
                                                <input name="decisions.concerns" value="Zbieranie" type="radio" id="collecting_waste-0" class="custom-control-input"
                                                    :class="[
                                                        $root.errors.values && $root.errors.values['decision.concerns'] ? 'is-invalid' : ''
                                                    ]"
                                                    v-model="form.decision.concerns"
                                                >
                                                <label class="custom-control-label" for="collecting_waste-0" >Zbieranie odpadów</label>
                                            </div>

                                            <div class="mx-3 form-check custom-control custom-radio">
                                                <input name="decisions.concerns" value="Przetwarzanie" type="radio" id="wasteprocessing-0" class="custom-control-input"
                                                    :class="[
                                                        $root.errors.values && $root.errors.values['decision.concerns'] ? 'is-invalid' : ''
                                                    ]"
                                                    v-model="form.decision.concerns"
                                                >
                                                <label class="custom-control-label" for="wasteprocessing-0" > Przetwarzanie odpadów</label>
                                            </div>

                                            <div class="mx-3 form-check custom-control custom-radio">
                                                <input name="decisions.concerns" value="Inne" type="radio" id="others-0" class="custom-control-input"
                                                    v-model="form.decision.concerns"
                                                    :class="[
                                                        $root.errors.values && $root.errors.values['decision.concerns'] ? 'is-invalid' : ''
                                                    ]"
                                                >
                                                <label class="custom-control-label" for="others-0" >Inne</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="row my-3">
                                        <div class="col-md-6">
                                            <small class="font-weight-bold">Znak decyzji</small>
                                            <input name="decisions.signature" id="decisions.signature" type="text"  class="form-control fill_color " checked v-model="form.decision.signature"
                                                :class="[
                                                        $root.errors.values && $root.errors.values['decision.signature'] ? 'is-invalid' : ''
                                                    ]"
                                            >
                                        </div>
                                        <div class="col-md-6">
                                            <small class="font-weight-bold">Termin obowiązywania</small>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <input name="decisions.date" v-model="form.decision.date" type="date"  class="form-control fill_color"
                                                        :class="[
                                                        $root.errors.values && $root.errors.values['decision.date'] ? 'is-invalid' : ''
                                                    ]"
                                                    >
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="custom-control custom-checkbox my-md-0 my-3 ">
                                                        <input name="decisions.indefinitely" value="bezterminowo" type="checkbox" id="indefinitely-0" class="custom-control-input ml-4" v-model="form.decision.indefinitely" >
                                                        <label class="custom-control-label" for="indefinitely-0">bezterminowo</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 d-sm-flex justify-content-between align-items-start">
                    <button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-danger text-white btn-scale d-block px-5 mr-2">Anuluj</button>
                    <button class="btn waste-container text-white btn-scale d-block px-5 mr-2 mb-2">{{priceInfo && !priceInfo.recipient_offer.removed ? 'Zapisz zmiany' : 'Dodaj ogłoszenie'}}</button>
                </div>
            </div>
        </form>

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title font-weight-bold">Czy chcesz anulować dodawanie oferty ?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Wszystkie niezapisane zmiany zostaną utracone</p>
            </div>
            <div class="modal-footer">
                <a href="/recipient/offer/active" class="btn btn-success">Tak</a>
                <button type="button" class="btn btn-danger" data-dismiss="modal">Nie</button>
            </div>
            </div>
        </div>
    </div>
    </div>
</section>
</template>

<script>
import InputLabel from 'UI/InputLabel.vue';
import Geocoder from 'UI/GeocoderInput.vue';
import Checkbox from 'UI/Checkbox.vue';
import Select from 'UI/Select.vue';
import InfoIcon from 'UI/InfoIcon';
import InputGroup from 'UI/InputGroup';

import {
    getDate,
} from 'helper/moment';
import moment from 'moment-timezone';

export default {
    components: {
        InputLabel,
        Geocoder,
        Checkbox,
        Select,
        InfoIcon,
        InputGroup,
    },
    props: ['trashes', 'processes', 'user' , 'payments', 'priceInfo', 'transport', 'adminInfo', 'admin'],
    data() {
        return {
            title: (this.priceInfo ? 'Edytuj' : 'Dodaj') + ' ogłoszenie (przyjmę odpady)',
            show: false,
            images: [],
            form: {
                address: {
                    city: this.priceInfo ? this.priceInfo.recipient_offer.location.city : null,
                    postcode: this.priceInfo ? this.priceInfo.recipient_offer.location.postcode : null,
                    street: this.priceInfo ? this.priceInfo.recipient_offer.location.street : null,
                    number: this.priceInfo ? this.priceInfo.recipient_offer.location.number : null,
                    additional: this.priceInfo ? this.priceInfo.recipient_offer.location.additional : null,
                    lat: this.priceInfo ? this.priceInfo.recipient_offer.location.lat : null,
                    lng: this.priceInfo ? this.priceInfo.recipient_offer.location.lng : null,
                },
                user: {
                    phone: this.priceInfo ? this.priceInfo.recipient_offer.phone : this.user.phone,
                    email: this.priceInfo ? this.priceInfo.recipient_offer.email : this.user.email,
                    who: this.priceInfo ? this.priceInfo.recipient_offer.who : (this.user.company_name ? this.user.company_name : this.user.firstname + ' ' + this.user.lastname),
                    as_who: this.priceInfo ? this.priceInfo.recipient_offer.as_who : null
                },
                additional: this.priceInfo ? this.priceInfo.recipient_offer.additional : null,
                code: this.priceInfo ? this.priceInfo.trash_id : 1,
                min: this.priceInfo ? this.priceInfo.min : null,
                max: this.priceInfo ? this.priceInfo.max : null,
                process: this.priceInfo ? this.priceInfo.process : null,
                transport: this.priceInfo ? this.priceInfo.recipient_offer.transport : null,
                waste_collection: this.priceInfo ? (this.priceInfo.recipient_offer.waste_collection == 1 ? true : false) : false,
                decision: {
                    id: this.getDecisionInfo(this.priceInfo, 'id'),
                    concerns: this.getDecisionInfo(this.priceInfo, 'concerns'),
                    signature: this.getDecisionInfo(this.priceInfo, 'signature'),
                    indefinitely: this.getDecisionInfo(this.priceInfo, 'indefinitely'),
                    date: this.getDecisionInfo(this.priceInfo, 'date'),
                    file_name: this.getDecisionInfo(this.priceInfo, 'files'),
                    file: '',
                },
                payments: {
                    type: this.priceInfo ? this.priceInfo.recipient_offer.payments ?? 3 : null,
                    value: this.priceInfo ? this.priceInfo.value : null,
                },
                date: {
                    from: this.priceInfo ? getDate(this.priceInfo.recipient_offer.from, 'YYYY-MM-DD') : getDate(null, 'YYYY-MM-DD'),
                    to: this.priceInfo ? getDate(this.priceInfo.recipient_offer.to, 'YYYY-MM-DD') : moment(new Date()).add('months', 1).format('YYYY-MM-DD'),
                },
            },
            errors: {
                values: null,
                message: null
            },
            trash: {},
            axios_header: {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            },
            loading: false,

        }
    },
    mounted() {
        moment.locale('pl');
        this.show = true;
        $('.title-section').html(this.title);

        this.$nextTick(() => {
            if(this.form.waste_collection){
                $('#decisions').addClass('show');
            }
        })
    },
    methods: {
        getDecisionInfo(price, slug){
            if(price){
                if(price.recipient_offer.decisions[0]){
                    if(slug == 'files'){
                        let files = price.recipient_offer.decisions[0][slug];

                        return files[0].name;
                    }
                    else return price.recipient_offer.decisions[0][slug];
                }
                else return '';
            }
            else return '';
        },
        uploadFile(ev){
            this.form.decision.file = ev.target.files[0];
            this.form.decision.file_name = ev.target.files[0].name;
        },
        changeAddress(value){
            for (const [key, val] of Object.entries(value)) {
                this.form.address[key] = val;
            }
        },
        sendForm(ev){
            let data = this.preperDataToSend();
            this.loading = true;

            axios.post(this.priceInfo ? ('/recipient/offer/update/' + this.priceInfo.id) : '/recipient/offer/store', data, this.axios_header)
            .then((response) => {

                if(response.data.status == 'error') {
                    this.showErrors(response)
                }
                else {
                    if(response.data.success){
                        const link = this.$props.admin ? `/admin/recipient/offer/active/${this.user.id}` : '/recipient/offer/active';
                        window.location.href = link + '?action=' + (this.priceInfo && !this.priceInfo.recipient_offer.removed ? 'edited' : 'added');
                    }
                }

            })
            .catch( (error) => this.showErrors(error) )
            .finally(() => {
                this.loading = false;

                if($('.is-invalid').length > 0){
                    $('.is-invalid').first().parents().each((key, el) => {
                        if($(el).hasClass('collapse')){

                            if($(el).hasClass('show'))
                                $('.is-invalid')[0].scrollIntoView({ behavior: "smooth", block: "center" })
                            else{
                                $(el).collapse('show');
                                $(el).on('shown.bs.collapse', (ev) => {
                                    $('.is-invalid')[0].scrollIntoView({ behavior: "smooth", block: "center" })
                                })
                            }
                        }
                    })
                }
            });
        },
        preperDataToSend(){
            let formData = new FormData();

            for (const [key, value] of Object.entries(this.form)) {
                if(key == 'address' || key == 'user' || key == 'payments' || key == 'date' || key == 'decision'){
                    for (const [k, v] of Object.entries(value)) {
                        formData.append(key + '['+k+']', v ? v : '');
                    }
                }
                else {
                    formData.append(key, value ? value : '');
                }
            }

            return formData;
        },
        isBoolean(val) {
            return val === false || val === true;
        },
        openSection(ev, isDecision = false) {
            let id = $(ev.target).data('target');
            let button = $(ev.target);

            if(isDecision){
                if(ev) $('#decisions').collapse('show');
                else $('#decisions').collapse('hide');
            }
            else {
                $(id).on('shown.bs.collapse', (ev) => {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(button).offset().top - $(button).outerHeight()
                    }, 'slow');
                })
            }
        }
    },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all .3s ease;
}
.fade-enter, .fade-leave-to{
    opacity: 0;
    transform: scale(0.9);
}

.spinner.modal{
    background: rgb(255,255,255, 0.25);
}
</style>
