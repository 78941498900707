import _ from 'lodash';

export default function __(key, replace, locale = 'pl') {
    let
        translation,
        translationNotFound = true;

    try {
        translation = window.i18n[key];
        if (translation) {
            translationNotFound = false
        }
    } catch (e) {
        translation = key;

    }

    if (translationNotFound){
        translation = key
    }

    _.forEach(replace, (value, key) => {
        translation = translation.replace(':' + key, value)
    })

    return translation;
}
