<template>
<div>
    <FlashMessage />
    <div class="row form-row">
        <div class="form-group col-md-7">
            <Select
                class="trash-type-section mb-3"
                label="Wybierz kod/nazwę odpadu dla którego chcesz otrzymywać powiadomienia"
                name="code"
                id="code"
                :live-search="false"
                :multiple="true"
                data-selected-text-format="count > 2"
                :data-count-selected-text="`Wybrane kody/nazwy odpadów: {0}`"
                :size="10"
                :clearOption="false"
                :data="trashes"
                v-model="form.code"
            >
            <template #default="{ data }">
                <optgroup
                        v-for="(trash) in data"
                        :key="trash.id"
                        :label="trash.code + (trash.is_dangerous ? '*' : '') + ' - ' + trash.description"
                    >
                    <template v-for="child in trash.children">
                        <option
                            v-for="type in child.children"
                            :key="type.id"
                            :value="type.id"
                            :data-tokens="type.code.replaceAll(' ', '')"
                            :data-content="`<b>${ type.code + (type.is_dangerous ? '*' : '') }</b> - ${ type.description }`"
                        ></option>
                    </template>
                </optgroup>
            </template>
            </Select>
            <Geocoder
                label="Twoja lokalizacja"
                id="location"
                class="mb-3"
                placeholder="Lokalizacja"
                @update="ev => updateLocation(ev, form)"
                :value="form.location"
            />
            <InputLabel
                label="Obszar ogłoszeń (promień [km])"
                id="area"
                class="mb-3"
                type="number"
                step="0.01"
                v-model="form.area"
            />
            <button
                @click="save"
                class="btn text-white waste-container btn-search d-block px-5"
                :class="[
                    processing ? 'opacity-50' : ''
                ]"
            >Dodaj</button>
        </div>
        <div class="form-group col-md-5">
            <GoogleMap
                style="min-height: 300px;"
                :list="false"
                :coords="{
                    lat: form.lat,
                    lng: form.lng
                }"
                :area="form.area"
                :area-opacity="0.1"
            >
            </GoogleMap>
        </div>
        <h5 class="font-weight-bold">Lista wybranych kodów:</h5>
        <span v-if="$props.user.newsletter && $props.user.newsletter.length == 0">Nie wybrano</span>
        <div
            v-for="newsletter in $props.user.newsletter"
            :key="newsletter.id"
            class="col-12 mb-3"
        >
            <div class="bg-light p-3 rounded border">
                <h5 class="border-bottom pb-1 text-waste font-weight-bold">Kod odpadu:</h5>
                <ul
                    v-if="newsletter.trashes && newsletter.trashes.length"
                    class="pl-3"
                >
                        <li
                            v-for="type in newsletter.trashes"
                            :key="type.id"
                            class="py-1"
                        >
                            <b>{{ type.code + (type.is_dangerous ? '*' : '') }}</b> - {{ type.description }}
                        </li>
                </ul>
                <p v-else>Nie wybrano rodzaju odpadu</p>
                <h5 class="border-bottom pb-1 text-waste font-weight-bold">Zasięg:</h5>
                <div class="mb-2">
                    <h5 class="mb-1">
                        <svg
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 16 16"
                            class="bi bi-geo-alt"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                        </svg>
                        <span v-if="newsletter.address">{{ newsletter.address }}</span>
                        + <b>{{ newsletter.area }} km</b>
                    </h5>
                    <!-- <p class="mb-0">Wysyłaj informacje o ogłoszeniach znajdujących się w obrębie <b>{{ newsletter.area }}</b> kilometrów</p> -->
                </div>

                <div class="d-flex justify-content-end align-items-center">
                    <button
                        v-if="newsletter"
                        @click="remove(newsletter.id)"
                        class="btn btn-danger btn-search d-block px-5"
                        :class="[
                            processing ? 'opacity-50' : ''
                        ]"
                    >Usuń</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Select from 'UI/Select';
import InputLabel from 'UI/InputLabel.vue';
import Geocoder from 'UI/GeocoderInput.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import FlashMessage from 'UI/Flash';

export default {
    components: {
        Select,
        InputLabel,
        Geocoder,
        GoogleMap,
        FlashMessage
    },
    props: {
        user: [Array, Object],
        trashes: [Array, Object],
    },
    data() {
        return {
            form: {
                code: [],
                area: 30,
                location: null,
                lat: null,
                lng: null,
            },
            processing: false,
        }
    },

    mounted() {

    },
    methods: {
        getParseData(data){
            let newData = [];

            data.forEach((el) => {

                try {
                    let item = JSON.parse(el);
                    newData.push( item )
                } catch (e) {
                    newData.push( el )
                }
            })

            return newData;
        },
        getTrashCode(){
            let trashes = [];

            if(this.user.newsletter && this.user.newsletter.trashes.length){
                this.user.newsletter.trashes.forEach((el) => {
                    trashes.push( el.id );
                })
            }

            return trashes;
        },
        updateLocation(val, form){
            for (const [key, v] of Object.entries(val)) {
                form[key] = v;
            }
        },
        save(){
            if(!this.processing){
                this.processing = true;

                axios.post('/newsletter/'+ this.$props.user.id +'/store', this.form)
                .then((response) => {
                    if(response.data.success){
                        this.$root.flash = response.data;
                        this.user.newsletter = response.data.user.newsletter;


                        this.form = {
                            code: [],
                            area: 30,
                            location: null,
                            lat: null,
                            lng: null,
                        };
                    }
                })
                .catch((errors) => {
                    this.showErrors(errors);
                })
                .finally(() => {
                    this.processing = false;
                })
            }
        },
        remove(newsletter){
            if(!this.processing && this.$props.user.newsletter){
                this.processing = true;

                axios.post('/newsletter/'+ newsletter +'/remove')
                .then((response) => {
                    if(response.data.success || response.data.error){
                        this.$root.flash = response.data;
                        this.user.newsletter = response.data.user.newsletter;
                    }
                })
                .catch((errors) => {
                    this.showErrors(errors);
                })
                .finally(() => {
                    this.processing = false;
                })
            }
        }
    }
}
</script>

<style scoped>
.list-disc {
    list-style: disc;
}
.btn.scale:hover {
    transform: scale(0.95);
}
.btn:hover {
    filter: contrast(0.8);
}
.opacity-50 {
    opacity: .5;
}
</style>
